import { User as AuthUser } from "@auth0/auth0-react";
import { observable, action } from "mobx";
import ShortUniqueId from "short-unique-id";

import { ViewMode } from "../helpers/Enum";
import {
  createUser,
  getStripeProfile,
  getAuth0User,
  updateUser,
  createStripeProfile,
  getSubscriptions,
} from "../helpers/api";
import { User, UserSettings } from "../models/User";
import Analytics from "../helpers/Analytics";

import RootStore from "./RootStore";

export const defaultSettings: UserSettings = {
  non_translated_text_color: "#696969",
  non_translated_text_size: 14,
  original_text_color: "#ffffff",
  original_text_size: 14,
  translated_text_color: "#75E275",
  translated_text_size: 14,
  selected_paragraph_color: "#FFA500",
  view_mode: ViewMode.BottomView,
  dark_mode: true,
};

export default class UserStore {
  @observable currentUser!: User;
  @observable isCurrentUserReady: boolean = false;
  @observable stripeUser?: any;
  @observable isPurchaseModalOpened: boolean = false;
  @observable isLoginAlertModalOpened: boolean = false;
  @observable isOpenAIApiKeyAlertModalOpened: boolean = false;
  @observable isShareModalOpened: boolean = false;
  @observable isSettingsDrawerOpened: boolean = false;

  private isUserSetted: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(rootStore: RootStore) {}

  @action setUser = async (user: AuthUser | undefined) => {
    if (this.isUserSetted) return;

    this.isUserSetted = true;

    if (user && user.sub) {
      const existingUser = await getAuth0User(user.sub);

      if (existingUser) {
        this.currentUser = existingUser;

        this.currentUser.isAnonymous = false;
      } else {
        const newUser = await createUser(
          user.name,
          user.sub,
          user.email,
          user.email_verified !== undefined ? user.email_verified : false,
          user.gender,
          user.locale,
          user.phone_number,
          user.phone_number_verified !== undefined
            ? user.phone_number_verified
            : false,
          defaultSettings,
          undefined
        );

        newUser.isAnonymous = false;

        if (newUser) {
          this.currentUser = newUser;
        }
      }

      this.getStripeUser();
    } else {
      const anonymousUser = await createUser(
        undefined,
        new ShortUniqueId().randomUUID(20),
        undefined,
        false,
        undefined,
        undefined,
        undefined,
        false,
        defaultSettings,
        undefined
      );

      anonymousUser.isAnonymous = true;

      if (anonymousUser) {
        this.currentUser = anonymousUser;

        Analytics.identify(this.currentUser);
      }
    }

    setTimeout(() => {
      this.isCurrentUserReady = true;
    }, 1000);
  };

  @action updateUserData = async () => {
    await updateUser(this.currentUser);
  };

  @action getStripeUser = async () => {
    if (this.currentUser.email) {
      const stripeProfile = await getStripeProfile(this.currentUser.email);

      if (!stripeProfile) {
        const newStripeProfile = await createStripeProfile(
          this.currentUser.email
        );

        this.currentUser.stripeProfile = newStripeProfile;
      } else {
        this.currentUser.stripeProfile = stripeProfile;
      }

      // get user subscriptions
      if (this.currentUser.stripeProfile) {
        const subscriptions = await getSubscriptions(
          this.currentUser.stripeProfile.id
        );

        this.currentUser.subscriptions = subscriptions;
      }

      // set user analytics identify
      Analytics.identify(this.currentUser);
    }
  };
}
