/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import { TranslationItem } from "../../models/TranslationItem";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function getCurrentBlockTranslation(
  blockId: string
): Promise<TranslationItem | undefined> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query TranslationQuery($block_id: String!) {
        currentBlockTranslation(block_id: $block_id) {
          id
          block_id
          original_key_text
          language
          original_text
          translation_text
          updated_at
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          block_id: blockId,
        },
        fetchPolicy: "no-cache",
      })
      .then((translationQueryResult: any) => {
        const translationItem: TranslationItem = get(
          translationQueryResult,
          "data.currentBlockTranslation",
          null
        );

        if (translationItem) {
          translationItem.original_key_text = decodeURIComponent(
            translationItem.original_key_text
          );
          translationItem.original_text = decodeURIComponent(
            translationItem.original_text
          );
          translationItem.translation_text = decodeURIComponent(
            translationItem.translation_text
          );

          resolve(translationItem);
        } else {
          resolve(undefined);
        }
      })
      .catch((error: any) => {
        console.error(error);

        resolve(undefined);
      });
  });
}
