/* @flow */

import { computed } from "mobx";

import stores from "../../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        mb: 2,
      },
      loginContainer: {
        pb: 3,
        pl: 2,
        pt: 2,
        alignItems: "center",
        justifyContent: "center",
      },
      loginInfoText: {
        mb: 2,
        ml: 2,
      },
      buttonContainer: {
        display: "flex",
        ml: 2,
      },
      titleContainer: {
        display: "flex",
        pl: 2,
        pr: 1,
        pt: 2,
        alignItems: "center",
        justifyContent: "space-between",
      },
      menuButton: {
        ml: -1,
      },
      titleText: {
        fontWeight: "bold",
        cursor: "pointer",
      },
      divider: {
        flex: 1,
        height: 1.1,
        bgcolor: stores.ui.themeColors.divider,
        ml: 1,
      },
      syncButton: {},
      cancelSubscriptionButton: {
        textTransform: "none",
        mr: 2,
      },
    };

    return styles;
  }
}
