/* @flow */

import { computed } from "mobx";

import stores from "../../../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      promptItemContainer: {
        display: "flex",
        m: 1,
        justifyContent: "center",
        borderRadius: 1,
      },
      promptItem: {
        display: "flex",
        alignItems: "center",
        whiteSpace: "normal",
        borderRadius: 1,
        flex: 1,
        pr: 2,
      },
      promptLabel: {
        flex: 1,
        height: "100%",
        pl: 2,
        pr: 2,
        pt: 3,
        pb: 3,
        cursor: "pointer",
        justifyContent: "center",
      },
      iconButton: {
        bgcolor: stores.ui.themeColors.selectedBackground,
        ml: 1,
      },
      icon: {
        width: "20px",
        height: "20px",
      },
      popover: {
        maxWidth: "500px",
        maxHeight: "500px",
      },
    };

    return styles;
  }
}
