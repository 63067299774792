/* @flow */

import { computed } from "mobx";

import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        display: "flex",
        flex: 1,
        height: "100vh",
        flexDirection: "row",
        justifyContent: "flex-start",
        bgcolor: stores.ui.themeColors.background,
      },
      leftContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minWidth: 350,
        maxWidth: 350,
        bgcolor: stores.ui.themeColors.secondaryBackground,
      },
      editorContainer: {
        minWidth: "30vw",
        flex: 1,
        overflow: "auto",
        scrollbarWidth: 0,
        bgcolor: stores.ui.themeColors.secondaryBackground,
        ml: 5,
        mr: 5,
        borderRadius: 1,
      },
      centerContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        mt: 5,
        mb: 5,
      },
      mainContainer: {
        display: "flex",
        flex: 1,
      },
      bottomContainer: {
        display: "flex",
        ml: 5,
        mr: 5,
        pt: 1,
        pb: 1,
        alignItems: "center",
        pl: 3,
        bgcolor: stores.ui.themeColors.secondaryBackground,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
      },
      progressContainer: {
        flex: 1,
      },
      bottomButtonContainer: {
        alignItems: "center",
        justifyContent: "center",
        mr: 2,
      },
      bottomButton: {
        display: "block",
        textTransform: "none",
        alignItems: "center",
        justifyContent: "center",
      },
      bottomButtonIcon: {
        width: 25,
        height: 25,
      },
      bottomButtonText: {},
      exportMenu: {},
      exportMenuIcon: {
        marginLeft: 0,
        marginRight: "10px",
        width: 25,
        height: 25,
      },
    };

    return styles;
  }
}
