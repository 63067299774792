// @flow

import { gql } from "apollo-boost";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { TranslationItem } from "../../models/TranslationItem";

export default async function createTranslationItem(
  blockId: string,
  originalKeyText: string,
  language: string,
  originalText: string,
  translationText: string,
  updatedAt: number
): Promise<TranslationItem> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createTranslation(
        block_id: "${blockId}"
        original_key_text: "${encodeURIComponent(originalKeyText)}"
        language: "${language}"
        original_text: "${encodeURIComponent(originalText)}"
        translation_text: "${encodeURIComponent(translationText)}"
        updated_at: ${updatedAt}
      ) {
        id
        block_id
        original_key_text
        language
        original_text
        translation_text
        updated_at
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createTranslationMutationResult: any) => {
        const translationItem: TranslationItem = get(
          createTranslationMutationResult,
          "data.createTranslation",
          null
        );

        if (translationItem) {
          translationItem.original_key_text = decodeURIComponent(
            translationItem.original_key_text
          );
          translationItem.original_text = decodeURIComponent(
            translationItem.original_text
          );
          translationItem.translation_text = decodeURIComponent(
            translationItem.translation_text
          );

          resolve(translationItem);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
