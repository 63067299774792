import * as React from "react";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import Header from "@editorjs/header";
import { observer } from "mobx-react";
// @ts-ignore
import Strikethrough from "@sotaproject/strikethrough";
// @ts-ignore
import Underline from "@editorjs/underline";
// @ts-ignore
// import Quote from "@editorjs/quote";
// import Checklist from "@editorjs/checklist";
// @ts-ignore
// import List from "@editorjs/list";

// @ts-ignore
import DragDrop from "editorjs-drag-drop";
// @ts-ignore
import Undo from "editorjs-undo";

import { DocumentItem } from "../../models/DocumentItem";
import { DocumentHistoryItem } from "../../models/DocumentHistoryItem";
import stores from "../../stores";
import Emitter from "../../helpers/EventEmitter";
import i18n from "../../data/i18n";
import { BlockType } from "../../helpers/Enum";
import { ViewMode } from "../../helpers/Enum";
import TranslantionTune from "./Tunes/TranslationTune";
import FootnotesTune from "./Tunes/FootnoteTune";
import ActionTool from "./Tools/ActionTool";
import MarkerTool from "./Tools/MarkerTool";
import styles from "./styles";
import WhisperCallTool from "./Tools/WhisperCallTool";
// import Table from "./Tools/TableTool";

type Props = {
  documentItem: DocumentItem;
};

type State = {
  editorData?: OutputData;
  currentDocumentHistoryItem?: DocumentHistoryItem;
};
const isTranslationBlock = stores.actionStore.selectedBlockType === BlockType.TranslationBlockItem;

@observer
export default class Editor extends React.Component<Props, State> {
  private editor: EditorJS | undefined;

  private firstLoad: boolean = true;

  constructor(props: any) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    const { documentItem } = this.props;

    Emitter.on("UPDATE_EDITOR_DATA", (editorData: OutputData | undefined) => {
      if (this.editor && editorData) {
        this.editor.render(editorData);
      }
    });

    if (documentItem) this.loadDocumentHistoryOutputData(documentItem);
  }

  componentWillUnmount(): void {
    Emitter.off("UPDATE_EDITOR_DATA");

    if (this.editor && this.editor.destroy) this.editor.destroy();
  }

  private loadDocumentHistoryOutputData = async (
    documentItem: DocumentItem
  ) => {
    const currentDocumentHistoryItem =
      await stores.documentStore.getCurrentDocumentHistoryItem(documentItem);

    if (currentDocumentHistoryItem) {
      this.setState({ currentDocumentHistoryItem }, () => {
        this.initEditor(currentDocumentHistoryItem.output_data);
      });
    } else {
      this.initEditor(undefined);
    }
  };

  private onDataChange = async (content: OutputData) => {
    const { documentItem } = this.props;
    const { currentDocumentHistoryItem } = this.state;

    const newDocumentHistoryItem =
      await stores.documentStore.setDocumentHistoryItem(
        documentItem,
        currentDocumentHistoryItem,
        content
      );

    this.setState({
      editorData: content,
      currentDocumentHistoryItem: newDocumentHistoryItem,
    });
  };

  // private updateSelectedText = (range: any) => {
  //   // Add this code to change the background color of the selected text
  //   if (range) {
  //     const span = document.createElement("span");
  //     span.style.backgroundColor = stores.ui.themeColors.selectedBackground;
  //     span.id = "selected-text";

  //     const content = range.extractContents();
  //     span.appendChild(content);

  //     range.insertNode(span);
  //   }
  // };

  private onMouseDown = () => {
    // Remove the background color of the previous selection
    const prevSelectedElement = document.getElementById("selected-text");
    if (prevSelectedElement) {
      // Extract the text from the span
      // @ts-ignore
      const text = prevSelectedElement.childNodes;

      // Create a fragment to hold the nodes
      let fragment = document.createDocumentFragment();
      while (text.length) {
        fragment.appendChild(text[0]);
      }

      // Replace the span with the fragment
      // @ts-ignore
      prevSelectedElement.parentNode.replaceChild(
        fragment,
        prevSelectedElement
      );
    }
  };

  private onMouseUp = () => {
    const selection: any = window.getSelection();

    Emitter.emit("SELECTION_CHANGED", {});

    if (selection && selection.rangeCount > 0 && selection.baseNode) {
      let clonedContents = selection.baseNode.parentElement;
      const selectedAreaHtml = clonedContents.innerHTML;

      let parser = new DOMParser();
      let doc = parser.parseFromString(selectedAreaHtml, "text/html");

      doc.querySelectorAll("sup").forEach((sup) => {
        sup.remove();
      });

      let selectedAreaWholeText = doc.body.textContent || "";

      const range = selection.getRangeAt(0).cloneRange(); // Cloning range to prevent mutations on actual selection
      const clonedSelection = range.cloneContents();
      let supElements = clonedSelection.querySelectorAll("sup");

      supElements.forEach((sup: any) => {
        sup.textContent = ""; // Clearing sup text content
      });

      const selectedText = clonedSelection.textContent;

      if (selectedText.length === 0) {
        stores.actionStore.setSelectionProps(undefined, undefined, undefined);
      } else {
        const selectedBlockType =
          selection.baseNode.parentElement.classList.contains(
            "translation-block"
          )
            ? BlockType.TranslationBlockItem
            : BlockType.BlockItem;
        if (selectedText && selectedAreaWholeText) {
          stores.actionStore.setSelectionProps(
            selectedText,
            selectedAreaWholeText,
            selectedBlockType
          );
        }
      }

      //this.updateSelectedText(range);
    }
  };

  private initEditor = (editorData: OutputData | undefined) => {
    const { documentItem } = this.props;
    //@ts-ignore
    // const ImageTool = window.ImageTool;
    const { currentUser } = stores.userStore;

    // Check if the document language is "Editor"
    if (
      documentItem.document_language === "Editor" ||
      documentItem.document_language === "Voice"
    ) {
      // Update the view mode setting to "OnlyOriginal"
      currentUser.settings.view_mode = ViewMode.OnlyOriginal;
    } else {
      currentUser.settings.view_mode = ViewMode.BottomView;
    }

    this.editor = new EditorJS({
      holder: `editorjs-document-${documentItem.id}`,
      data: editorData,
      //@ts-ignore
      logLevel: "ERROR",
      onReady: () => {
        const editor = this.editor;
        // console.log(stores.documentStore.selectedDocumentItem?.id); // querySelector null warning is caused from the delay between switching the documents

        new Undo({ editor });
        new DragDrop(editor);
      },
      onChange: async (api, event) => {
        if (this.editor && !this.firstLoad) {
          let content = await this.editor.saver.save();

          this.onDataChange(content);
        } else {
          this.firstLoad = false;
        }
      },

      placeholder: i18n.editor.placeholder,
      toolbar: {},
      autofocus: false,
      tools: {
        translation: {
          //@ts-ignore
          class: TranslantionTune,
          shortcut: "CMD+SHIFT+T",
        },
        strikethrough: {
          class: Strikethrough,
          inlineToolbar: true,
        },
        underline: {
          class: Underline,
          inlineToolbar: true,
        },
        AI: {
          class: ActionTool,
          inlineToolbar: true,
        },
        voiceAction: {
          //@ts-ignore
          class: WhisperCallTool,
          inlineToolbar: true,
        },
        header: {
          //@ts-ignore
          class: Header,
          tunes: ["translation"],
          //@ts-ignore

          inlineToolbar: [
            "AI",
            "voiceAction",
            "bold",
            "italic",
            "marker",
            "strikethrough",
            "underline",
          ],
        },

        footnotes: {
          class: FootnotesTune,
          config: {
            shortcut: "CMD+SHIFT+S",
          },
        },
        //@ts-ignore
        marker: {
          class: MarkerTool,
          shortcut: "CMD+H",
        },

        paragraph: {
          tunes: ["translation", "footnotes"],
          //@ts-ignore

          inlineToolbar: [
            "AI",
            "voiceAction",
            "bold",
            "italic",
            "marker",
            "strikethrough",
            "underline",
          ],
        },
        //@ts-ignore
        // image: {
        //   class: ImageTool,
        //   config: {
        //     endpoints: {
        //       byFile: "http://localhost:3000/uploadFile", // Your backend file uploader endpoint
        //       byUrl: "http://localhost:3000/fetchUrl", // Your endpoint that provides uploading by Url
        //     },
        //   },
        // },

        // checklist: {
        //   class: Checklist,
        //   tunes: ["translation", "footnotes"],
        //   inlineToolbar: true,
        // },
        // table: {
        //   //@ts-ignore
        //   class: Table,
        //   tunes: ["translation", "footnotes"],
        //   inlineToolbar: true,
        // },

        // quote: {
        //   //@ts-ignore
        //   class: Quote,
        //   tunes: ["translation", "footnotes"],
        //   inlineToolbar: true,
        //   shortcut: "CMD+SHIFT+O",
        //   config: {
        //     quotePlaceholder: "Enter a quote",
        //     captionPlaceholder: "Quote's author",
        //   },
        // },

        // list: {
        //   class: List,
        //   tunes: ["translation"],
        //   inlineToolbar: true,
        //   config: {
        //     defaultStyle: "unordered",
        //   },
        // },
      },
    });

    if (this.editor) stores.documentStore.editor = this.editor;
  };

  render() {
    const { documentItem } = this.props;

    return (
      <div
        id={"editor"}
        className={`editor-${stores.userStore.currentUser.settings.view_mode}-view-mode`}
        style={{
          display: "flex",
        }}
      >
        <div
          onMouseUp={this.onMouseUp}
          onMouseDown={this.onMouseDown}
          className={`editor-${stores.ui.theme}-mode`}
          style={styles.styleSheet.editor}
          id={`editorjs-document-${documentItem.id}`}
        ></div>
      </div>
    );
  }
}
