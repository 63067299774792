import { Box, Button, Dialog, Typography } from "@mui/material";
import * as React from "react";
import { observer } from "mobx-react";

import i18n from "../../data/i18n";
import { Product } from "../../models/Product";

import { LoginButton } from "./LoginButton";
import styles from "./styles";

type Props = {
  open: boolean;
  onClose: () => void;
};

type State = {
  currentStep: number;
  selectedProduct?: Product;
};

@observer
export default class LoginAlertModal extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentStep: 0,
    };
  }

  render() {
    const { open, onClose } = this.props;

    return (
      <Dialog onClose={onClose} open={open}>
        <Box sx={styles.styleSheet.mainContainer}>
          <Typography variant="body1" sx={styles.styleSheet.title}>
            {i18n.common.login}
          </Typography>

          <Typography variant="body2" sx={styles.styleSheet.description}>
            {i18n.common.loginMessage}
          </Typography>

          <Box style={styles.styleSheet.bottomButtonsContainer}>
            <Button
              sx={styles.styleSheet.closeButton}
              color="secondary"
              onClick={onClose}
            >
              {i18n.common.cancel}
            </Button>

            <LoginButton />
          </Box>
        </Box>
      </Dialog>
    );
  }
}
