import React from "react";
import { FormControl, TextField } from "@mui/material";
import isEmail from "validator/lib/isEmail";

type Props = {
  onChange: (value: string, isValid: boolean) => any;
  fieldName: string;
  label: string;
  helperText: string;
  placeholder: string;
};

type State = {
  value: string;
  isValid: boolean;
  dirty: boolean;
};

export default class LanguageSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: "",
      isValid: false,
      dirty: false,
    };
  }

  private handleChange = (event: any) => {
    const { onChange } = this.props;

    const value = event.target.value;
    let isValid = false;

    if (isEmail(value)) {
      isValid = true;
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }

    this.setState({ value });

    onChange(value, isValid);
  };

  render() {
    const { fieldName, label, helperText, placeholder } = this.props;
    const { dirty, isValid, value } = this.state;

    return (
      <FormControl fullWidth={false}>
        <TextField
          error={dirty && isValid === false}
          onBlur={() => this.setState({ dirty: true })}
          id={fieldName}
          label={label}
          name={fieldName}
          variant="outlined"
          size={"medium"}
          helperText={helperText}
          value={value}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            style: {
              height: 15,
              width: 270,
            },
          }}
          placeholder={placeholder}
          onChange={(e) => this.handleChange(e)}
        />
      </FormControl>
    );
  }
}
