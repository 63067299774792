import * as React from "react";
import { observer } from "mobx-react";
import { Box, Button, List, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Cookies from "js-cookie";

import i18n from "../../../../data/i18n";
import stores from "../../../../stores";
import { PromptMode, RegenerateMode } from "../../../../models/RegenerateMode";
import SortButtonListComponent from "../../../../components/SortButtonListComponent";
import PromptModeSelector from "../../../../components/PromptModeSelector";
import SearchComponent from "../../../../components/SearchComponent";
import RegenerateMenuItem from "../RegenerateMenuItem";
import ResultView from "../ResultView";
import keys from "../../../../constants/keys";
import { SortType } from "../../../../helpers/Enum";
import Emitter from "../../../../helpers/EventEmitter";
import Functions from "../../../../helpers/Functions";
import Analytics from "../../../../helpers/Analytics";

import styles from "./styles";

type Props = {
  onNewActionPress: () => any;
  onEditRegenerateModeClick: (regenerateModeId: number) => any;
};

type State = {
  isResultViewVisible: boolean;
  lastUsedRegenerateModes: Array<RegenerateMode>;
};

@observer
export default class RegenerateModesView extends React.Component<Props, State> {
  private resultViewRef: any = React.createRef();

  constructor(props: any) {
    super(props);

    this.state = {
      isResultViewVisible: false,
      lastUsedRegenerateModes: [],
    };
  }

  componentWillMount() {
    Emitter.on("SELECTION_CHANGED", () => {
      this.setState({ isResultViewVisible: false });
    });

    const lastUsedCookie = Cookies.get("lastUsedRegenerateModes");

    if (lastUsedCookie) {
      const lastUsedRegenerateModes: Array<RegenerateMode> =
        JSON.parse(lastUsedCookie);
      // Filter out locked modes before setting the state
      const unlockedModes = lastUsedRegenerateModes.filter(
        (mode) =>
          !(
            mode.prompt_modes_list &&
            mode.prompt_modes_list.includes(
              //@ts-ignore
              Functions.getKeyByPromptModeValue(PromptMode.Editor)
            ) &&
            (!stores.userStore.currentUser.subscriptions ||
              stores.userStore.currentUser.subscriptions.length <= 0)
          )
      );
      this.setState({ lastUsedRegenerateModes: unlockedModes });
    }
  }

  componentWillUnmount() {
    Emitter.off("SELECTION_CHANGED");
  }

  private onRegenerateModeClick = async (regenerateMode: RegenerateMode) => {
    const { lastUsedRegenerateModes } = this.state;

    // Filter out locked regenerate modes and only add unlocked ones to the list
    const unlockedModes = lastUsedRegenerateModes.filter(
      (mode) =>
        !(
          mode.prompt_modes_list &&
          mode.prompt_modes_list.includes(
            //@ts-ignore
            Functions.getKeyByPromptModeValue(PromptMode.Editor)
          ) &&
          (!stores.userStore.currentUser.subscriptions ||
            stores.userStore.currentUser.subscriptions.length <= 0)
        )
    );

    // Ensure uniqueness in the list (using a Set for efficiency)
    const isModeAlreadyUsed = new Set(unlockedModes.map((mode) => mode.id)).has(
      regenerateMode.id
    );

    let newRegenerateModes: RegenerateMode[];

    if (!isModeAlreadyUsed) {
      // Add the new mode (only if it's unlocked)
      if (
        !(
          regenerateMode.prompt_modes_list &&
          regenerateMode.prompt_modes_list.includes(
            //@ts-ignore
            Functions.getKeyByPromptModeValue(PromptMode.Editor)
          ) &&
          (!stores.userStore.currentUser.subscriptions ||
            stores.userStore.currentUser.subscriptions.length <= 0)
        )
      ) {
        newRegenerateModes = [regenerateMode, ...unlockedModes];
      } else {
        // If the mode is locked, don't modify the list
        newRegenerateModes = unlockedModes;
      }
    } else {
      // Reorder the list to move the selected unlocked mode to the top
      const reorderedModes = unlockedModes.filter(
        (mode) => mode.id !== regenerateMode.id
      );
      newRegenerateModes = [regenerateMode, ...reorderedModes];
    }

    this.setState({ lastUsedRegenerateModes: newRegenerateModes });
    // Set the cookie
    Cookies.set("lastUsedRegenerateModes", JSON.stringify(newRegenerateModes));

    // Show modal or regenerate based on conditions
    if (
      regenerateMode.prompt_modes_list.includes(
        //@ts-ignore
        Functions.getKeyByPromptModeValue(PromptMode.Editor)
      ) &&
      (!stores.userStore.currentUser.subscriptions ||
        stores.userStore.currentUser.subscriptions.length <= 0)
    ) {
      stores.userStore.isPurchaseModalOpened = true;
    } else if (
      stores.userStore.currentUser.openai_api_key &&
      stores.userStore.currentUser.openai_api_key !== "undefined" &&
      stores.userStore.currentUser.openai_api_key.length > 0
    ) {
      this.setState(
        {
          isResultViewVisible: true,
        },
        () => {
          this.resultViewRef.current.regenerate(regenerateMode);
        }
      );
    } else {
      stores.userStore.isOpenAIApiKeyAlertModalOpened = true;
    }
  };

  private handleSortChange = (item: SortType) => {
    stores.regenerateStore.selectedSortType = item;
    stores.regenerateStore.processRegenerateModes();
  };

  private handleSearchChange = (text: string) => {
    stores.regenerateStore.searchText = text;
    stores.regenerateStore.processRegenerateModes();
  };

  private handlePromptModeSelect = (item: PromptMode) => {
    // Multiple selection
    let promptModes = stores.regenerateStore.selectedPromptModes;

    stores.regenerateStore.selectedPromptModes = [];

    if (promptModes.includes(item)) {
      promptModes = promptModes.filter((promptMode) => promptMode !== item);
    } else {
      promptModes.push(item);
    }

    stores.regenerateStore.selectedPromptModes = promptModes;

    Analytics.track("regenerate_mode_selected", [promptModes]);

    stores.regenerateStore.processRegenerateModes();

    // Single selection
    // stores.regenerateStore.selectedPromptModes = [item];
  };

  render() {
    const { onNewActionPress, onEditRegenerateModeClick } = this.props;
    const { isResultViewVisible, lastUsedRegenerateModes } = this.state;

    return (
      <Box sx={styles.styleSheet.container}>
        <Box sx={styles.styleSheet.topTextContainer}>
          {stores.actionStore.selectionType ? (
            <Typography variant="body1" sx={styles.styleSheet.titleActionsText}>
              {`${
                i18n.common.selected
              } ${stores.actionStore.selectionType.valueOf()}`}
            </Typography>
          ) : null}

          {stores.actionStore.selectionType ? (
            <Box sx={styles.styleSheet.divider} />
          ) : null}

          {stores.actionStore.selectedText ? (
            <Typography variant="body2" sx={styles.styleSheet.topText}>
              {stores.actionStore.selectedText}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              sx={styles.styleSheet.topTextNoSelection}
            >
              {i18n.regenerateModesView.noSelection}
            </Typography>
          )}
        </Box>

        {!isResultViewVisible ? (
          <>
            <Box sx={styles.styleSheet.buttonContainer}>
              <Button
                startIcon={<AddIcon />}
                sx={styles.styleSheet.createCustomPromptButton}
                onClick={onNewActionPress}
              >
                {i18n.regenerateModesView.createCustomPrompt}
              </Button>

              <PromptModeSelector
                promptModes={Object.values(PromptMode)}
                selectedPromptModes={stores.regenerateStore.selectedPromptModes}
                handlePromptModeSelect={this.handlePromptModeSelect}
              />
            </Box>

            <Box sx={styles.styleSheet.sortAndFilterBox}>
              <SearchComponent onSearch={this.handleSearchChange} />

              <SortButtonListComponent
                selectedItem={stores.regenerateStore.selectedSortType}
                data={Object.values(SortType)}
                onSort={this.handleSortChange}
              />
            </Box>
          </>
        ) : null}

        {isResultViewVisible ? (
          <ResultView
            ref={this.resultViewRef}
            onClose={() => this.setState({ isResultViewVisible: false })}
          />
        ) : (
          <Box sx={styles.styleSheet.regenerateMenu}>
            {stores.regenerateStore.filteredRegenerateModes.filter(
              (regenerateMode) =>
                regenerateMode.user_id.toString() ===
                stores.userStore.currentUser.id.toString()
            ).length > 0 ? (
              <Typography variant="body1" sx={styles.styleSheet.promptHeader}>
                {`${i18n.common.customPromptsHeader}`}
              </Typography>
            ) : null}

            <List>
              {stores.regenerateStore.filteredRegenerateModes
                .filter(
                  (regenerateMode) =>
                    regenerateMode.user_id.toString() ===
                    stores.userStore.currentUser.id.toString()
                )
                .map((regenerateMode) => {
                  return (
                    <RegenerateMenuItem
                      key={`${
                        regenerateMode.user_id !==
                        keys.publicRegenerateModesUserId
                          ? "customRegenerateMode"
                          : "regenerateMode"
                      }-${regenerateMode.id}`}
                      disabled={stores.actionStore.selectedText ? false : true}
                      regenerateMode={regenerateMode}
                      isCreatedByUser={true}
                      onRegenerateModeClick={this.onRegenerateModeClick}
                      onEditRegenerateModeClick={onEditRegenerateModeClick}
                    />
                  );
                })}
            </List>
            {/* Checking if there are any last used prompts or not. If there are, then show them. */}
            {lastUsedRegenerateModes.length > 0 ? (
              <div>
                <Typography variant="body1" sx={styles.styleSheet.promptHeader}>
                  {`${i18n.formatString(
                    i18n.common.lastUsedPrompt,
                    lastUsedRegenerateModes.length
                  )}`}
                </Typography>
                <List>
                  {lastUsedRegenerateModes.map((lastUsedRegenerateModes) => (
                    <RegenerateMenuItem
                      key={`${
                        lastUsedRegenerateModes.user_id !==
                        keys.publicRegenerateModesUserId
                          ? "customRegenerateMode"
                          : "regenerateMode"
                      }-${lastUsedRegenerateModes.id}`}
                      disabled={stores.actionStore.selectedText ? false : true}
                      regenerateMode={lastUsedRegenerateModes}
                      isCreatedByUser={false}
                      onRegenerateModeClick={this.onRegenerateModeClick}
                      onEditRegenerateModeClick={onEditRegenerateModeClick}
                    />
                  ))}
                </List>
              </div>
            ) : null}
            <Typography variant="body1" sx={styles.styleSheet.promptHeader}>
              {`${i18n.common.allPrompts}`}
            </Typography>
            <List>
              {stores.regenerateStore.filteredRegenerateModes
                .filter(
                  (regenerateMode) =>
                    regenerateMode.user_id.toString() !==
                    stores.userStore.currentUser.id.toString()
                )
                .map((regenerateMode) => (
                  <RegenerateMenuItem
                    key={`${
                      regenerateMode.user_id !==
                      keys.publicRegenerateModesUserId
                        ? "customRegenerateMode"
                        : "regenerateMode"
                    }-${regenerateMode.id}`}
                    disabled={stores.actionStore.selectedText ? false : true}
                    regenerateMode={regenerateMode}
                    isCreatedByUser={false}
                    onRegenerateModeClick={this.onRegenerateModeClick}
                    onEditRegenerateModeClick={onEditRegenerateModeClick}
                  />
                ))}
            </List>
          </Box>
        )}
      </Box>
    );
  }
}
