import { Box, Button } from "@mui/material";
import i18n from "../../data/i18n";
import { LoginButton } from "./LoginButton";
import { SignupButton } from "./SignupButton";
import styles from "./styles"; // Ensure this import path is correct
import Analytics from "../../helpers/Analytics";

const MainHeader = () => {
  const handleBlogClick = () => {
    // Assuming Analytics is set up in a context or imported
    Analytics.track("blog_button_pressed", "");
    window.location.href = "https://www.lingoedit.com/blog";
  };
  const handleHomeClick = () => {
    window.location.href = "https://www.lingoedit.com/";
  };
  const handlePricingClick = () => {
    window.location.href = "https://www.lingoedit.com/pricing";
  };
  const handleFAQClick = () => {
    window.location.href = "https://www.lingoedit.com/faq";
  };
  const handleFeaturesClick = () => {
    window.location.href = "https://www.lingoedit.com/features";
  };

  return (
    <Box
      sx={{
        ...styles.styleSheet.topContainer,
        marginBottom: "20px", // Adjust the margin bottom as per your requirement
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        component="button"
        sx={{ border: "none", background: "none", p: 0, cursor: "pointer" }}
        onClick={handleHomeClick}
      >
        <img
          style={{ ...styles.styleSheet.icon, width: "120px", height: "auto" }}
          alt=""
          src={require(`../../assets/dark/logo.png`)}
        />
      </Box>
      <Box
        sx={{
          ...styles.styleSheet.topButtonContainer,
          marginLeft: "30px",
        }}
      >
        <Button
          sx={{ ...styles.styleSheet.navButton }}
          onClick={handleHomeClick}
        >
          Home
        </Button>
        <Button
          sx={{ ...styles.styleSheet.navButton }}
          onClick={handlePricingClick}
        >
          Pricing
        </Button>
        <Button
          sx={{ ...styles.styleSheet.navButton }}
          onClick={handleFAQClick}
        >
          FAQ
        </Button>
        <Button
          sx={{ ...styles.styleSheet.navButton }}
          onClick={handleFeaturesClick}
        >
          Features
        </Button>
        <Button
          sx={{ ...styles.styleSheet.navButton }}
          onClick={handleBlogClick}
        >
          Blog
        </Button>
        <Box sx={styles.styleSheet.spaceBox} />
        <LoginButton />
        <SignupButton />
      </Box>
    </Box>
  );
};

export default MainHeader;
