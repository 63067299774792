import { OutputBlockData } from "@editorjs/editorjs";
import { Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";

import { TranslationItem } from "../../models/TranslationItem";
import stores from "../../stores";
import keys from "../../constants/keys";

type Props = {
  blockItem: OutputBlockData;
  isMenuVisible: boolean;
  translationItem?: TranslationItem;
  onKeyUp: () => any;
  onMouseUp: (event: any) => any;
  onDoubleCick: (event: any) => any;
  onContextMenu: (event: any) => any;
};

type State = {
  isFocused: boolean;
};

@observer
export default class TranslationParagraphItem extends React.Component<
  Props,
  State
> {
  private inputRef: any;

  constructor(props: any) {
    super(props);

    this.state = {
      isFocused: false,
    };
  }

  public getInnerHTML = () => {
    const { blockItem } = this.props;

    if (this.inputRef && this.inputRef.innerHTML)
      return this.inputRef.innerHTML !== blockItem.data.text
        ? this.inputRef.innerHTML
        : "";
  };

  componentDidMount(): void {
    this.inputRef.addEventListener("DOMSubtreeModified", this.props.onKeyUp);
  }

  componentWillUnmount() {
    this.inputRef.removeEventListener("DOMSubtreeModified", this.props.onKeyUp);
  }

  render() {
    const {
      blockItem,
      onMouseUp,
      onDoubleCick,
      onContextMenu,
      translationItem,
      isMenuVisible,
    } = this.props;
    const { isFocused } = this.state;

    const { settings } = stores.userStore.currentUser;

    const itemStyle = {
      color: isMenuVisible
        ? settings.selected_paragraph_color
        : (!translationItem || translationItem.translation_text === "") &&
          !isFocused
        ? settings.non_translated_text_color
        : settings.translated_text_color,
      marginRight: 30,
      fontStyle:
        (!translationItem || translationItem.translation_text === "") &&
        !isFocused
          ? "italic"
          : "normal",

      fontSize: (() => {
        if (
          (!translationItem || translationItem.translation_text === "") &&
          !isFocused
        ) {
          return settings.non_translated_text_size;
        } else {
          return settings.translated_text_size;
        }
      })(),
    };

    const placeholder = blockItem.data.text;
    const text =
      translationItem &&
      translationItem.translation_text &&
      translationItem.translation_text !== ""
        ? translationItem.translation_text
        : undefined;

    let languageCode;

    if (translationItem && translationItem.language) {
      languageCode = translationItem.language.toUpperCase();
    }

    const normalizedLanguageCode = languageCode || "";

    const textAlign = keys.rightAlignedLanguageCodes.includes(
      normalizedLanguageCode
    )
      ? "right"
      : "left";

    return (
      <Typography
        className={`ce-paragraph cdx-block ${keys.translationBlockClassName}`}
        ref={(ref) => (this.inputRef = ref)}
        dangerouslySetInnerHTML={{ __html: text || placeholder || "" }}
        style={{ ...itemStyle, textAlign }} // Apply the textAlign
        contentEditable={translationItem !== undefined}
        onContextMenu={onContextMenu}
        onFocus={() => this.setState({ isFocused: true })}
        onBlur={() => this.setState({ isFocused: false })}
        onDoubleClick={onDoubleCick}
        onMouseUp={onMouseUp}
      />
    );
  }
}
