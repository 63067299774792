/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";

export default async function updateTranslation(
  id: number,
  language: string,
  translation_text: string,
  updated_at: number
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateTranslation(
        id: ${id}
        language: "${language}"
        translation_text: "${encodeURIComponent(translation_text)}"
        updated_at: ${updated_at}
      ) 
    }
  `;

  const updateTranslationMutationResult = await client.mutate({ mutation });

  const result = get(
    updateTranslationMutationResult,
    "data.updateTranslation",
    null
  );

  return result !== null && result[0] > 0;
}
