import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as React from "react";

import stores from "../../stores";
import i18n from "../../data/i18n";
import Analytics from "../../helpers/Analytics";

type Props = {
  open: boolean;
  onClose: () => void;
};

type State = {};

export default class OpenAIApiKeyAlertModal extends React.Component<
  Props,
  State
> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    const { open, onClose } = this.props;

    return (
      <Dialog
        open={open}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{i18n.settings.openAIApiKey}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {i18n.common.enterApiKeyWarning}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{i18n.common.cancel}</Button>

          <Button
            onClick={() => {
              Analytics.track("settings_drawer_viewed", "");

              onClose();
              stores.userStore.isSettingsDrawerOpened = true;
            }}
          >
            {i18n.common.okay}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
