import { Component } from "react";
import { Menu, Box, Button, MenuItem, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import i18n from "../../data/i18n";
import { PromptMode } from "../../models/RegenerateMode";

import styles from "./styles";

interface Props {
  selectedPromptModes: PromptMode[];
  promptModes: PromptMode[];
  handlePromptModeSelect: (item: PromptMode) => void;
}

interface State {
  anchorEl: HTMLElement | null;
  isMenuVisible: boolean;
}

export default class PromptModeSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
      isMenuVisible: false,
    };
  }

  render() {
    const { handlePromptModeSelect, selectedPromptModes, promptModes } =
      this.props;
    const { anchorEl, isMenuVisible } = this.state;

    return (
      <>
        <Button
          startIcon={<ArrowDropDownIcon />}
          sx={styles.styleSheet.promptModeButton}
          onClick={(event) =>
            this.setState({
              anchorEl: event.currentTarget,
              isMenuVisible: true,
            })
          }
        >
          {i18n.promptModel.promptMode}
        </Button>

        <Box sx={{ position: "relative" }}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isMenuVisible}
            onClose={() => this.setState({ isMenuVisible: false })}
            PaperProps={{
              style: {
                width: anchorEl ? anchorEl.clientWidth : undefined,
              },
            }}
          >
            {promptModes.map((promptMode) => (
              <MenuItem
                key={promptMode}
                sx={{ pl: 0 }}
                selected={selectedPromptModes.includes(promptMode)}
                onClick={() => handlePromptModeSelect(promptMode)}
              >
                {selectedPromptModes.includes(promptMode) ? (
                  <CheckBoxIcon sx={styles.styleSheet.checkIcon} />
                ) : (
                  <CheckBoxOutlineBlankIcon sx={styles.styleSheet.checkIcon} />
                )}

                <Typography variant="body2">{promptMode}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </>
    );
  }
}
