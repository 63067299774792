/* @flow */

import { computed } from "mobx";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        display: "flex",
        flexDirection: "column",
        width: "25vw",
        minWidth: 450,
        justifyContent: "space-between",
        mt: 5,
        mb: 5,
        mr: 5,
        borderRadius: 1,
      },
    };

    return styles;
  }
}
