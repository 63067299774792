/* @flow */

import { computed } from "mobx";

import stores from "../../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      mainContainer: {
        display: "flex",
        flexDirection: "column",
        width: 500,
        height: "50vh",
        p: 3,
      },
      title: {
        color: stores.ui.themeColors.textPrimary,
        fontWeight: "bold",
        mb: 3,
      },
      payNowButton: {
        mt: 2,
        mb: 2,
        textTransform: "none",
        bgcolor: stores.ui.themeColors.primary,
        color: stores.ui.themeColors.textPrimary,
      },
      progress: {
        alignSelf: "center",
        mt: 2,
        mb: 2,
      },
      priceInfoMessage: {
        mb: 2,
      },
      bottomButtonsContainer: {
        display: "flex",
        flex: 1,
        mb: 1,
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
      backButton: {
        textTransform: "none",
        bgcolor: stores.ui.themeColors.secondaryBackground,
        color: stores.ui.themeColors.textPrimary,
      },
    };

    return styles;
  }
}
