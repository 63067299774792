import stores from "../stores";
import React, { useState, useEffect } from "react"; 

  const CharacterWordCounter = () => { 
  const [characterCount, setCharacterCount] = useState(0); 
  const [wordCount, setWordCount] = useState(0); 

  useEffect(() => {      
    const { selectedDocumentContent } = stores.documentStore; 
    let charCount = 0; 
    let wordCount = 0; 

    if (selectedDocumentContent) { 
      for (const block of stores.documentStore.selectedDocumentContent!.blocks) { 
        const blockContent = block.data.text; 
        charCount += blockContent.length; 
        const words: string[] = blockContent.split(/[\s]+/).filter((word: string) => word.length > 0); 
        wordCount += words.length; 

        const nbspCount = (blockContent.match(/&nbsp;/g) || []).length; 
        charCount -= 6 * nbspCount;
        wordCount += nbspCount; 
      } 
    } 

    setCharacterCount(charCount); 
    setWordCount(wordCount); 
  }, [stores.documentStore.selectedDocumentContent]); 

  return ( 

    <div style={{ position: 'absolute', bottom: 50, left: 439, background: 'inherit', padding: 5 }}> 
      <p style={{ fontSize: '0.8em', color: '#808080', margin: '0' }}>{characterCount} {characterCount === 0 || characterCount === 1 ? 'character' : 'characters'}</p> 
      <p style={{ fontSize: '0.8em', color: '#808080', margin: '0' }}>{wordCount} {wordCount === 0 || wordCount === 1 ? 'word' : 'words'}</p> 
    </div> 

  ); 
}; 

export default CharacterWordCounter;