/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import { User } from "../../models/User";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function searchUsers(email: string): Promise<User[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query UserSearchQuery($email: String!) {
        userSearch(email: $email) {
          id
          auth0_id
          name
          email
          email_verified
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          email: email,
        },
        fetchPolicy: "no-cache",
      })
      .then((userSearchQueryResult: any) => {
        const users: User[] = get(
          userSearchQueryResult,
          "data.userSearch",
          null
        );

        if (users && users.length > 0) {
          resolve(users);
        } else {
          resolve([]);
        }
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
