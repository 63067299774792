import React from "react";
import { Button, Box, InputBase, IconButton, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import i18n from "../../data/i18n";
import Analytics from "../../helpers/Analytics";

import styles from "./styles";

interface Props {
  onSearch: (text: string) => void;
}

interface State {
  isSearchActive: boolean;
  searchQuery: string;
}

export default class SearchComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isSearchActive: false,
      searchQuery: "",
    };
  }

  private handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: event.target.value });

    this.props.onSearch(event.target.value);
  };

  private handleSearchButtonClick = () => {
    Analytics.track("search_button_clicked", "");

    this.setState({ isSearchActive: true });
  };

  private handleCloseButtonClick = () => {
    this.setState({ isSearchActive: false, searchQuery: "" });

    this.props.onSearch("");
  };

  render() {
    return (
      <Box sx={styles.styleSheet.container}>
        {this.state.isSearchActive ? (
          <Paper sx={styles.styleSheet.searchBar}>
            <InputBase
              autoFocus
              sx={{ ml: 1, flex: 1, fontSize: 14 }}
              placeholder={i18n.searchComponent.search}
              inputProps={{ "aria-label": i18n.searchComponent.search }}
              onChange={this.handleSearchChange}
            />

            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="directions"
              onClick={this.handleCloseButtonClick}
            >
              <CloseIcon />
            </IconButton>
          </Paper>
        ) : (
          <Button
            variant="text"
            startIcon={<SearchIcon />}
            sx={styles.styleSheet.searchButton}
            onClick={this.handleSearchButtonClick}
          >
            {i18n.searchComponent.search}
          </Button>
        )}
      </Box>
    );
  }
}
