/* @flow */

import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";

class ApolloClientHelper {
  private client: any;

  init = () => {
    this.client = new ApolloClient({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
      cache: new InMemoryCache(),
    });
  };

  getApolloClient = () => this.client;
}

const apolloClient = new ApolloClientHelper();

export default apolloClient;
