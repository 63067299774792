/* @flow */

import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import * as amplitude from "@amplitude/analytics-browser";
import { Identify } from "@amplitude/analytics-browser";

import { User } from "../models/User";
import keys from "../constants/keys";

export type AnalyticEvents =
  // * Home Page Events
  | "join_waiting_list_pressed"
  | "waiting_list_email_submitted"
  | "try_now_for_free_pressed"
  | "blog_button_pressed"
  | "pricing_page_button_pressed"
  | "try_now_pressed"

  // * Editor Page Events
  | "add_new_document_button_clicked"
  | "new_document_added"
  | "document_selected"
  | "document_deleted"
  | "document_updated"
  | "share_document_clicked"
  | "edit_document_clicked"
  | "delete_document_clicked"
  | "shared_document_opened"
  | "upgrade_button_clicked"
  | "settings_button_clicked"
  | "send_feedback_button_clicked"
  | "login_button_clicked"
  | "logout_button_clicked"
  | "signup_button_clicked"
  | "export_button_clicked"
  | "clear_all_button_clicked"
  | "translate_all_button_clicked"
  | "shortcuts_button_clicked"
  | "translation_language_selected"
  | "bottom_view_clicked"
  | "side_by_side_view_clicked"
  | "only_original_view_clicked"
  | "only_translation_view_clicked"
  | "toggle_left_view_clicked"
  | "toggle_right_view_clicked"
  | "create_custom_prompt_clicked"
  | "prompt_modes_selected"
  | "search_button_clicked"
  | "sort_button_clicked"
  | "regenerate_mode_selected"
  | "cancel_subscription_clicked"

  // * Editor Events
  | "export_button_clicked"
  | "clear_all_button_clicked"
  | "editor_text_selected"
  | "editor_word_selected"
  | "editor_paragraph_selected"
  | "editor_sentence_selected"
  | "translation_block_right_clicked"
  | "translation_block_actions_clicked"
  | "translation_block_copy_clicked"
  | "translation_history_viewed"
  | "translation_history_reverted"
  | "translate_tool_button_clicked"
  | "move_up_tune_button_clicked"
  | "move_down_tune_button_clicked"
  | "delete_tune_button_clicked"
  | "add_block_tune_button_clicked"
  | "bold_tool_button_clicked"
  | "italic_tool_button_clicked"
  | "marker_tool_button_clicked"
  | "new_comment_added"
  | "prompt_view_button_clicked"
  | "replace_selected_text_clicked"
  | "replace_selected_block_clicked"
  | "copy_selected_text_clicked"

  // * Settings Drawer Events
  | "settings_drawer_viewed"
  | "valid_open_api_key_entered"
  | "not_valid_open_api_key_entered"
  | "save_open_api_key_clicked"
  | "clear_open_api_key_clicked"
  | "original_text_color_changed"
  | "translated_text_color_changed"
  | "non_translated_text_color_changed"
  | "selected_paragraph_color_changed"
  | "original_text_size_changed"
  | "translated_text_size_changed"
  | "non_translated_text_size_changed"
  | "editor_view_mode_changed"
  | "original_text_color_set_to_default"
  | "translated_text_color_set_to_default"
  | "non_translated_text_color_set_to_default"
  | "selected_paragraph_color_set_to_default"

  // * Create Custom Prompt Drawer Events
  | "custom_prompt_selection_types_selected"
  | "custom_prompt_name_entered"
  | "custom_prompt_entered"
  | "custom_prompt_saved"
  | "custom_prompt_updated"
  | "custom_prompt_deleted";

export default class Analytics {
  // Initialize Firebase
  static app = initializeApp(keys.firebaseConfig);
  static analytics = getAnalytics(Analytics.app);

  static identify(user: User) {
    // Firebase identify
    setUserId(this.analytics, user.auth0_id);
    setUserProperties(this.analytics, {
      language: user.locale ? user.locale : "",
      email: user.email ? user.email : "",
      subscription: user.subscriptions && user.subscriptions.length > 0,
    });

    // Amplitude identify
    amplitude.init(keys.amplitudeApiKey);

    const identifyObj = new Identify();

    amplitude.setUserId(user.auth0_id);

    identifyObj.set("language", user.locale ? user.locale : "");
    identifyObj.set("email", user.email ? user.email : "");
    identifyObj.set(
      "subscription",
      user.subscriptions && user.subscriptions.length > 0 ? true : false
    );

    amplitude.identify(identifyObj);
  }

  static track(event: AnalyticEvents, dimensions: any) {
    // clean out array values from dimensions
    const cleanDimensions = Object.assign({}, dimensions);

    Object.keys(cleanDimensions).forEach((key) => {
      if (
        Array.isArray(cleanDimensions[key]) ||
        typeof cleanDimensions[key] === "object"
      ) {
        delete cleanDimensions[key];
      } else if (typeof key === "string" && key.startsWith("__")) {
        delete cleanDimensions[key];
      } else if (
        typeof cleanDimensions[key] === "string" &&
        cleanDimensions[key].length > 50
      ) {
        delete cleanDimensions[key];
      }
    });

    logEvent(this.analytics, event, cleanDimensions);

    amplitude.track(event, cleanDimensions);
  }
}
