/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import { DocumentItem } from "../../models/DocumentItem";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function getDocuments(
  userId: number
): Promise<DocumentItem[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query DocumentsSearchQuery($user_id: ID!) {
        documentCreatedByUser(user_id: $user_id) {
          id
          user_id
          name
          document_language
          translation_language
          date
          created_at
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          user_id: userId,
        },
        fetchPolicy: "no-cache",
      })
      .then((documentsSearchQueryResult: any) => {
        const documentItems: DocumentItem[] = get(
          documentsSearchQueryResult,
          "data.documentCreatedByUser",
          null
        );

        documentItems.forEach((item) => {
          if (item.document_language === "undefined")
            item.document_language = undefined;
          if (item.translation_language === "undefined")
            item.translation_language = undefined;
        });

        resolve(documentItems);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
