// @flow

import { observable, computed, when, action } from "mobx";

import stores from ".";
import RootStore from "./RootStore";

export type Colors = {
  background: string;
  secondaryBackground: string;
  selectedBackground: string;
  divider: string;
  primary: string;
  textPrimary: string;
  textSecondary: string;
  textWarning: string;
  textInfo: string;
};

class UiStore {
  light: Colors = {
    secondaryBackground: "rgb(247, 247, 247)",
    background: "#fff",
    divider: "#585858",
    primary: "#B0BEC5",
    textPrimary: "#000",
    textSecondary: "#ccc",
    selectedBackground: "#BDBDBD",
    textWarning: "#f44336",
    textInfo: "#e6b800",
  };

  dark: Colors = {
    background: "rgb(25, 25, 25)",
    secondaryBackground: "rgb(32, 32, 32)",
    divider: "#585858",
    primary: "rgb(55, 98, 227)",
    textPrimary: "#fff",
    textSecondary: "#aaa",
    selectedBackground: "#37474F",
    textWarning: "#f44336",
    textInfo: "#e6b800",
  };

  text = {};

  // theme represents the users UI preference (defaults to system)
  @observable theme!: "light" | "dark";

  constructor(rootStore: RootStore) {
    when(
      () => rootStore.userStore.isCurrentUserReady,
      () => {
        this.setTheme();
      }
    );
  }

  @action setTheme = async () => {
    this.theme = stores.userStore.currentUser.settings.dark_mode
      ? "dark"
      : "light";
  };

  @computed
  get resolvedTheme(): "dark" | "light" {
    return this.theme;
  }

  @computed
  get themeColors(): Colors {
    if (this.theme === "dark") {
      return this.dark;
    }
    return this.light;
  }
}

export default UiStore;
