import { OutputBlockData } from "@editorjs/editorjs";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { toast } from "react-toastify";

import i18n from "../../data/i18n";
import Emitter from "../../helpers/EventEmitter";
import {
  getCurrentParagraphTranslation,
  getTranslations,
  updateTranslation,
} from "../../helpers/api";
import { TranslationItem } from "../../models/TranslationItem";
import stores from "../../stores";
import ActionMenu from "../ActionMenu";
import keys from "../../constants/keys";
import Analytics from "../../helpers/Analytics";

import TranslationHeaderItem from "./TranslationHeaderItem";
import TranslationParagraphItem from "./TranslationParagraphItem";

type Props = {
  blockId: string;
};

type State = {
  blockItem?: OutputBlockData;
  translationItem?: TranslationItem;
  translationItems: Array<TranslationItem>;

  anchorEl: any;
  isMenuVisible: boolean;
  isTranslationItemFetched: boolean;
};

@observer
export default class TranslationBlockItem extends React.Component<
  Props,
  State
> {
  private inputChangeTimer: any;

  private itemRef: any = React.createRef();

  constructor(props: any) {
    super(props);

    this.state = {
      translationItems: [],

      anchorEl: null,
      isMenuVisible: false,
      isTranslationItemFetched: false,
    };
  }

  componentDidMount() {
    this.setBlockItem();
  }

  componentWillMount() {
    Emitter.on("UPDATE_DOCUMENT_HISTORY_ITEM", () => {
      if (!this.state.translationItem) this.setBlockItem();
    });
  }

  componentWillUnmount() {
    Emitter.off("UPDATE_DOCUMENT_HISTORY_ITEM");
  }

  private setBlockItem = () => {
    const { blockId } = this.props;

    if (stores.documentStore.selectedDocumentContent) {
      const currentBlockItem =
        stores.documentStore.selectedDocumentContent.blocks.find(
          (item) => item.id === blockId
        );

      if (currentBlockItem) this.setState({ blockItem: currentBlockItem });

      this.setTranslationItem();
    }
  };

  private setTranslationItem = async () => {
    const { blockId } = this.props;

    const translationItem = await getCurrentParagraphTranslation(blockId);

    this.setState({
      translationItem: translationItem,
      isTranslationItemFetched: true,
    });
  };

  private getTranslationItems = async () => {
    const { blockId } = this.props;

    Analytics.track("translation_history_viewed", "");

    const translationItems = await getTranslations(blockId);

    if (translationItems.length > 0) this.setState({ translationItems });
  };

  private updateTranslationItem = async () => {
    clearTimeout(this.inputChangeTimer);

    this.inputChangeTimer = setTimeout(async () => {
      const { translationItem } = this.state;

      if (this.itemRef) {
        let text = "";

        if (this.itemRef.current.getInnerHTML) {
          text = this.itemRef.current.getInnerHTML();
        } else if (this.itemRef.current.getInnerText) {
          text = this.itemRef.current.getInnerText();
        }

        if (translationItem) {
          await stores.documentStore.updateTranslationItem(
            translationItem,
            text
          );
        }
      }
    }, 300);
  };

  private showTranslateWarning = () => {
    toast.warning(i18n.editorScreen.translateWarning, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: stores.ui.theme,
    });
  };

  private onTranslationItemPress = async (translationItem: TranslationItem) => {
    this.setState({ isMenuVisible: false });

    Analytics.track("translation_history_reverted", "");

    const updatedAt = Date.now();

    await updateTranslation(
      translationItem.id,
      translationItem.language,
      translationItem.translation_text,
      updatedAt
    );

    this.setState({
      translationItem,
    });
  };

  private onCopyPress = async () => {
    const { translationItem } = this.state;

    Analytics.track("translation_block_copy_clicked", "");

    this.setState({ isMenuVisible: false });

    const text = translationItem ? translationItem.translation_text : "";

    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  private onActionsPress = async () => {
    const { translationItem, anchorEl } = this.state;

    Analytics.track("translation_block_actions_clicked", "");

    this.setState({ isMenuVisible: false });

    if (translationItem && stores.documentStore.selectedDocumentContent) {
      const blockId =
        stores.documentStore.selectedDocumentContent.blocks.findIndex(
          (item) => item.id === translationItem.block_id
        );

      if (blockId !== -1) {
        stores.actionStore.setSelectionBlockId(anchorEl, blockId);
      }
    }
  };

  private onTextFocused = () => {
    this.getTranslationItems();

    this.setState({ isMenuVisible: true });
  };

  private onContextMenu = (event: any) => {
    Analytics.track("translation_block_right_clicked", "");

    event.preventDefault();

    this.setState({ anchorEl: event.currentTarget });

    this.onTextFocused();
  };

  private onMouseUp = () => {
    const { translationItem } = this.state;

    if (!translationItem) {
      stores.actionStore.selectedBlockId = undefined;
      stores.actionStore.selectedBlockType = undefined;
    }
  };

  private onDoubleClick = () => {
    const { translationItem } = this.state;

    if (!translationItem || translationItem.translation_text === "")
      this.showTranslateWarning();
  };

  private onKeyUp = async () => {
    this.updateTranslationItem();
  };

  render() {
    const {
      translationItem,
      isMenuVisible,
      translationItems,
      anchorEl,
      isTranslationItemFetched,
      blockItem,
    } = this.state;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          textAlign:
            stores.documentStore.selectedDocumentItem &&
            stores.documentStore.selectedDocumentItem.translation_language &&
            keys.rightAlignedLanguageCodes.includes(
              stores.documentStore.selectedDocumentItem.translation_language
            )
              ? "end"
              : "start",
        }}
      >
        {isTranslationItemFetched &&
        blockItem &&
        blockItem.type === "paragraph" ? (
          <TranslationParagraphItem
            ref={this.itemRef}
            blockItem={blockItem}
            isMenuVisible={isMenuVisible}
            onContextMenu={this.onContextMenu}
            onKeyUp={this.onKeyUp}
            onMouseUp={this.onMouseUp}
            onDoubleCick={this.onDoubleClick}
            translationItem={translationItem}
          />
        ) : null}

        {isTranslationItemFetched &&
        blockItem &&
        blockItem.type === "header" ? (
          <TranslationHeaderItem
            ref={this.itemRef}
            blockItem={blockItem}
            level={blockItem.data.level}
            isMenuVisible={isMenuVisible}
            onContextMenu={this.onContextMenu}
            onKeyUp={this.onKeyUp}
            onMouseUp={this.onMouseUp}
            onDoubleCick={this.onDoubleClick}
            translationItem={translationItem}
          />
        ) : null}

        <ActionMenu
          translationItem={translationItem}
          translationItems={translationItems}
          onCopyPress={this.onCopyPress}
          onActionsPress={this.onActionsPress}
          onTranslationItemPress={this.onTranslationItemPress}
          anchorEl={anchorEl}
          isVisible={isMenuVisible}
          onClose={() => this.setState({ isMenuVisible: false })}
        />
      </Box>
    );
  }
}
