/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import { SharedDocumentItem } from "../../models/SharedDocumentItem";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function getSharedDocuments(
  userId: number
): Promise<SharedDocumentItem[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query SharedDocumentsQuery($shared_user_id: ID!) {
        sharedDocuments(shared_user_id: $shared_user_id) {
          id
          creator_user_id
          shared_user_id
          document_id
          permissions
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          shared_user_id: userId,
        },
        fetchPolicy: "no-cache",
      })
      .then((sharedDocumentsQueryResult: any) => {
        const sharedDocuments: SharedDocumentItem[] = get(
          sharedDocumentsQueryResult,
          "data.sharedDocuments",
          null
        );

        resolve(sharedDocuments);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
