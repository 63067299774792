import React from "react";
import {
  IconButton,
  Divider,
  Box,
  LinearProgress,
  List,
  Typography,
  Tooltip,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { observer } from "mobx-react";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";

import { RegenerateMode } from "../../../../models/RegenerateMode";
import stores from "../../../../stores";
import i18n from "../../../../data/i18n";
import { RegenerateResult } from "../../../../models/RegenerateResult";
import Analytics from "../../../../helpers/Analytics";

import styles from "./styles";

type Props = {
  onClose: () => any;
};

type State = {
  results: RegenerateResult[];
  isLoading: boolean;
  isLoadingMessageVisible: boolean;
};

@observer
export default class ResultView extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      results: [],
      isLoading: false,
      isLoadingMessageVisible: false,
    };
  }

  public regenerate = async (regenerateMode: RegenerateMode) => {
    this.setState({ isLoading: true });

    let results = await stores.actionStore.regenerate(regenerateMode);

    this.setState({
      results,
      isLoading: false,
      isLoadingMessageVisible: false,
    });
  };

  private handleReplaceSelectedBlockClick = async (
    result: RegenerateResult
  ) => {
    Analytics.track("replace_selected_block_clicked", "");

    const { onClose } = this.props;

    await stores.actionStore.regenerateAction(result.result);

    onClose();
  };

  private handleReplaceSelectedTextClick = async (result: RegenerateResult) => {
    Analytics.track("replace_selected_text_clicked", "");

    const { onClose } = this.props;

    if (
      stores.actionStore.selectedAreaWholeText &&
      stores.actionStore.selectedText
    ) {
      // Get paragraph text and selection indices
      const paragraphText = stores.actionStore.selectedAreaWholeText;
      const startIndex = paragraphText.indexOf(stores.actionStore.selectedText);
      const endIndex = startIndex + stores.actionStore.selectedText.length;

      // Construct new paragraph with replaced text
      let newParagraphText =
        paragraphText.slice(0, startIndex) +
        result.result +
        paragraphText.slice(endIndex);

      // Wrap replaced text in a span for highlighting
      const highlightedText = `<span class="cdx-marker">${result.result}</span>`;

      // Update text with temporary highlighting
      newParagraphText = newParagraphText.replace(
        result.result,
        highlightedText
      );

      await stores.actionStore.regenerateAction(newParagraphText);

      // Remove the span after 5 seconds
      setTimeout(() => {
        newParagraphText = newParagraphText.replace(
          highlightedText,
          result.result
        );
        stores.actionStore.regenerateAction(newParagraphText);
      }, 5000);

      onClose();
    }
  };

  private handleCopyToClipboardClick = async (result: RegenerateResult) => {
    Analytics.track("copy_selected_text_clicked", "");

    toast.success(i18n.common.copiedToClipboard, {
      position: "top-right",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: stores.ui.theme,
    });

    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(result.result);
    } else {
      return document.execCommand("copy", true, result.result);
    }
  };

  private renderResultItem = (result: RegenerateResult) => {
    let resultText = result.result;

    return (
      <Box
        key={`regenerateResult-${result.id}`}
        sx={styles.styleSheet.promptItemContainer}
      >
        <Box sx={styles.styleSheet.promptItem}>
          <Box sx={styles.styleSheet.promptItemToolbar}>
            <IconButton
              style={styles.styleSheet.toolbarItem}
              aria-label="share"
              size="medium"
              onClick={() => this.handleReplaceSelectedBlockClick(result)}
            >
              <Tooltip followCursor title={i18n.common.replaceSelectedBlock}>
                <img
                  style={styles.styleSheet.toolbarItemIcon}
                  alt=""
                  src={require(`../../../../assets/${stores.ui.theme}/replaceBlock.png`)}
                />
              </Tooltip>
            </IconButton>

            <IconButton
              style={styles.styleSheet.toolbarItem}
              aria-label="share"
              size="medium"
              onClick={() => this.handleReplaceSelectedTextClick(result)}
            >
              <Tooltip followCursor title={i18n.common.replaceSelectedText}>
                <ReadMoreIcon />
              </Tooltip>
            </IconButton>

            <IconButton
              style={styles.styleSheet.toolbarItem}
              aria-label="share"
              size="medium"
              onClick={() => this.handleCopyToClipboardClick(result)}
            >
              <Tooltip followCursor title={i18n.common.copyToClipboard}>
                <ContentCopyIcon />
              </Tooltip>
            </IconButton>
          </Box>

          <Divider sx={styles.styleSheet.divider} />

          <Typography
            dangerouslySetInnerHTML={{ __html: resultText }}
            variant="body2"
          ></Typography>

          {/* <Typography variant="body2">{result.result}</Typography> */}
        </Box>
      </Box>
    );
  };

  render() {
    const { onClose } = this.props;
    const { isLoading, isLoadingMessageVisible, results } = this.state;

    return (
      <Box sx={styles.styleSheet.mainContainer}>
        <Box sx={styles.styleSheet.topContainer}>
          {isLoading ? <LinearProgress /> : null}

          <IconButton sx={{ m: 0.5 }} onClick={onClose}>
            <ChevronLeftIcon />
          </IconButton>

          <Divider />
        </Box>

        {isLoading ? null : (
          <List sx={styles.styleSheet.resultList}>
            {results.map((result, index) => this.renderResultItem(result))}
          </List>
        )}

        {isLoadingMessageVisible ? (
          <Typography variant="body2" sx={styles.styleSheet.loadingInfoText}>
            {i18n.common.loadingInfoMessage}
          </Typography>
        ) : null}
      </Box>
    );
  }
}
