// @flow

import { gql } from "apollo-boost";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import {
  PromptMode,
  RegenerateMode,
  RegenerateModeType,
} from "../../models/RegenerateMode";

export default async function createRegenerateMode(
  name: string,
  user_id: number,
  prompt: string,
  is_translation: boolean,
  types: RegenerateModeType[],
  prompt_modes: PromptMode[]
): Promise<RegenerateMode> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const typesString = types.map((type) => type.valueOf()).join();
    const promptModesString = prompt_modes.map((type) => type.valueOf()).join();

    const mutation = gql`
    mutation {
        createRegenerateMode(
          user_id: ${user_id}
          name: "${name}"
          prompt: "${encodeURIComponent(prompt)}"
          is_translation: ${Number(is_translation)}
          types: "${typesString}"
          prompt_modes: "${promptModesString}"
      ) {
        id
        user_id
        name
        prompt
        is_translation
        types
        prompt_modes
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createRegenerateModeResult: any) => {
        const regenerateMode: RegenerateMode = get(
          createRegenerateModeResult,
          "data.createRegenerateMode",
          null
        );

        if (regenerateMode) {
          resolve(regenerateMode);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
