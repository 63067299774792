import * as React from "react";
import { observer } from "mobx-react";
import { Box } from "@mui/material";

import RegenerateModesView from "./RegenerateModesView";
import styles from "./styles";

type Props = {
  onNewActionPress: () => any;
  onEditRegenerateModeClick: (regenerateModeId: number) => any;
};

type State = {};

@observer
export default class ActionsView extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    const { onNewActionPress, onEditRegenerateModeClick } = this.props;

    return (
      <Box sx={styles.styleSheet.container}>
        <RegenerateModesView
          onEditRegenerateModeClick={onEditRegenerateModeClick}
          onNewActionPress={onNewActionPress}
        />
      </Box>
    );
  }
}
