/* @flow */

import { Subscription } from "../../models/User";

export default async function getSubscriptions(
  customerId: string
): Promise<Subscription[]> {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ customerId: customerId }),
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/customer-subscriptions`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        const subscriptions: Subscription[] = data;

        resolve(subscriptions);
      });
  });
}
