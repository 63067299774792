/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import { TranslationItem } from "../../models/TranslationItem";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function getTranslations(
  blockId: string
): Promise<TranslationItem[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query TranslationsSearchQuery($block_id: String!) {
        translationsSearch(block_id: $block_id) {
          id
          block_id
          original_key_text
          language
          original_text
          translation_text
          updated_at
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          block_id: blockId,
        },
        fetchPolicy: "no-cache",
      })
      .then((translationsSearchQueryResult: any) => {
        const translations: TranslationItem[] = get(
          translationsSearchQueryResult,
          "data.translationsSearch",
          null
        );

        translations.forEach((translationItem) => {
          translationItem.original_key_text = decodeURIComponent(
            translationItem.original_key_text
          );
          translationItem.original_text = decodeURIComponent(
            translationItem.original_text
          );
          translationItem.translation_text = decodeURIComponent(
            translationItem.translation_text
          );
        });

        resolve(translations);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
