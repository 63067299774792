import { observable, action, when } from "mobx";
import moment from "moment";
import EditorJS, { OutputBlockData, OutputData } from "@editorjs/editorjs";
import { toast } from "react-toastify";

import { DocumentItem } from "../models/DocumentItem";
import {
  createDocument,
  createDocumentHistory,
  createTranslation,
  deleteDocument,
  getCurrentDocumentHistory,
  translate,
  updateDocument,
  updateDocumentHistory,
  updateTranslation,
} from "../helpers/api";
import getDocuments from "../helpers/api/getDocuments";
import Emitter from "../helpers/EventEmitter";
import { DocumentHistoryItem } from "../models/DocumentHistoryItem";
import { TranslationItem } from "../models/TranslationItem";
import { TuneType, ViewMode } from "../helpers/Enum";
import i18n from "../data/i18n";
import getSharedDocuments from "../helpers/api/getSharedDocuments";
import { SharedDocumentItem } from "../models/SharedDocumentItem";
import Analytics from "../helpers/Analytics";
import keys from "../constants/keys";
import Functions from "../helpers/Functions";

import RootStore from "./RootStore";
import stores from ".";
import getTranslationResult from "../helpers/api/translate";

export default class DocumentStore {
  @observable documents: DocumentItem[] = [];
  @observable editorDocuments: DocumentItem[] = [];

  @observable sharedDocuments: SharedDocumentItem[] = [];

  @observable editor!: EditorJS;

  @observable selectedDocumentItem?: DocumentItem;
  @observable selectedDocumentContent: OutputData | undefined;

  @observable isDocumentsUpdating: boolean = false;
  @observable isTranslationCompleted: boolean = true;

  @observable isHelpModalOpen: boolean = false;

  constructor(rootStore: RootStore) {
    when(
      () => rootStore.userStore.isCurrentUserReady,
      () => {
        this.getDocuments();
      }
    );
  }

  @action getDocuments = async () => {
    this.documents = await getDocuments(stores.userStore.currentUser.id);

    this.isHelpModalOpen = this.documents.length === 0 ? true : false;

    this.sharedDocuments = await getSharedDocuments(
      stores.userStore.currentUser.id
    );
  };

  @action getPromptResult = async (prompt: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      try {
        // Get chat result based on the prompt
        let content_prompt = `While being very specific about the prompt below, please write a paragraph about: \n\n'${prompt}'"`;

        const chatResultString = await getTranslationResult(content_prompt);

        resolve(chatResultString);
      } catch (error) {
        reject(error);
      }
    });
  };
  @action getAlternativeTextSuggestions = async (
    selectedText: string
  ): Promise<string[]> => {
    this.isDocumentsUpdating = true; // Indicate start of operation
    try {
      // Construct the prompt for generating alternative texts
      const prompt = `Give me 5 alternatives of the following sentence or paragraph while maintaining the original meaning and language. Give me alternatives as an array format. Dont give me any text except array: "${selectedText}"`;

      // Use the getTranslationResult helper to fetch alternative suggestions
      const alternativeTextsJson = await getTranslationResult(prompt);

      // Before parsing, ensure the response is a valid JSON string
      console.log("Alternative Texts JSON:", alternativeTextsJson);

      // Assuming the response is a JSON string that includes an array of alternatives
      let alternativeTexts;
      try {
        alternativeTexts = JSON.parse(alternativeTextsJson);
      } catch (parseError) {
        console.error("Failed to parse JSON:", parseError);
        // Fallback or error handling logic here
        toast.error("Failed to parse alternative text suggestions.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        });
        return []; // Return an empty array or a suitable default
      }

      // Return the alternatives if the operation was successful
      return alternativeTexts;
    } catch (error) {
      console.error(
        "An error occurred while fetching alternative text suggestions: ",
        error
      );
      toast.error(
        "An error occurred while fetching alternative text suggestions.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        }
      );
      throw error; // Re-throw error after handling
    } finally {
      this.isDocumentsUpdating = false; // Indicate end of operation
    }
  };

  @action getLongerText = async (selectedText: string): Promise<string[]> => {
    this.isDocumentsUpdating = true; // Indicate start of operation
    try {
      // Construct the prompt for generating alternative texts
      const prompt = `Return 5 Longer alternatives of the following sentence or paragraph while maintaining the original meaning and language. Ensure that the alternatives are returned in the same language as the original text. Format the output as a JSON array with each alternative enclosed in double quotes and separated by commas, except for the last item. No trailing comma after the last item. Here's the text: "${selectedText}"`;

      // Use the getTranslationResult helper to fetch alternative suggestions
      const alternativeTextsJson = await getTranslationResult(prompt);

      // Before parsing, ensure the response is a valid JSON string
      console.log("Alternative Texts JSON:", alternativeTextsJson);

      // Assuming the response is a JSON string that includes an array of alternatives
      let alternativeTexts;
      try {
        alternativeTexts = JSON.parse(alternativeTextsJson);
      } catch (parseError) {
        console.error("Failed to parse JSON:", parseError);
        // Fallback or error handling logic here
        toast.error("Failed to parse alternative text suggestions.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        });
        return []; // Return an empty array or a suitable default
      }

      // Return the alternatives if the operation was successful
      return alternativeTexts;
    } catch (error) {
      console.error(
        "An error occurred while fetching alternative text suggestions: ",
        error
      );
      toast.error(
        "An error occurred while fetching alternative text suggestions.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        }
      );
      throw error; // Re-throw error after handling
    } finally {
      this.isDocumentsUpdating = false; // Indicate end of operation
    }
  };

  @action getShorterText = async (selectedText: string): Promise<string[]> => {
    this.isDocumentsUpdating = true; // Indicate start of operation
    try {
      // Construct the prompt for generating alternative texts
      const prompt = `Return 5 shorter alternatives of the following sentence or paragraph while maintaining the original meaning and language. Ensure that the alternatives are returned in the same language as the original text. Format the output as a JSON array with each alternative enclosed in double quotes and separated by commas, except for the last item. No trailing comma after the last item. Here's the text: "${selectedText}"`;
      // Use the getTranslationResult helper to fetch alternative suggestions
      const alternativeTextsJson = await getTranslationResult(prompt);

      // Before parsing, ensure the response is a valid JSON string
      console.log("Alternative Texts JSON:", alternativeTextsJson);

      // Assuming the response is a JSON string that includes an array of alternatives
      let alternativeTexts;
      try {
        alternativeTexts = JSON.parse(alternativeTextsJson);
      } catch (parseError) {
        console.error("Failed to parse JSON:", parseError);
        // Fallback or error handling logic here
        toast.error("Failed to parse alternative text suggestions.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        });
        return []; // Return an empty array or a suitable default
      }

      // Return the alternatives if the operation was successful
      return alternativeTexts;
    } catch (error) {
      console.error(
        "An error occurred while fetching alternative text suggestions: ",
        error
      );
      toast.error(
        "An error occurred while fetching alternative text suggestions.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        }
      );
      throw error; // Re-throw error after handling
    } finally {
      this.isDocumentsUpdating = false; // Indicate end of operation
    }
  };

  @action getImprovedText = async (selectedText: string): Promise<string[]> => {
    this.isDocumentsUpdating = true; // Indicate start of operation
    try {
      // Construct the prompt for generating alternative texts
      const prompt = `Return 5 Ä±mproved alternatives of the following sentence or paragraph while maintaining the original meaning and language. Ensure that the alternatives are returned in the same language as the original text. Format the output as a JSON array with each alternative enclosed in double quotes and separated by commas, except for the last item. No trailing comma after the last item. Here's the text: "${selectedText}"`;

      // Use the getTranslationResult helper to fetch alternative suggestions
      const alternativeTextsJson = await getTranslationResult(prompt);

      // Before parsing, ensure the response is a valid JSON string
      console.log("Alternative Texts JSON:", alternativeTextsJson);

      // Assuming the response is a JSON string that includes an array of alternatives
      let alternativeTexts;
      try {
        alternativeTexts = JSON.parse(alternativeTextsJson);
      } catch (parseError) {
        console.error("Failed to parse JSON:", parseError);
        // Fallback or error handling logic here
        toast.error("Failed to parse alternative text suggestions.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        });
        return []; // Return an empty array or a suitable default
      }

      // Return the alternatives if the operation was successful
      return alternativeTexts;
    } catch (error) {
      console.error(
        "An error occurred while fetching alternative text suggestions: ",
        error
      );
      toast.error(
        "An error occurred while fetching alternative text suggestions.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        }
      );
      throw error; // Re-throw error after handling
    } finally {
      this.isDocumentsUpdating = false; // Indicate end of operation
    }
  };
  @action getFixedText = async (selectedText: string): Promise<string[]> => {
    this.isDocumentsUpdating = true; // Indicate start of operation
    try {
      // Construct the prompt for generating alternative texts
      const prompt = `Return 5 fixed grammer alternatives of the following sentence or paragraph while maintaining the original meaning and language. Ensure that the alternatives are returned in the same language as the original text. Format the output as a JSON array with each alternative enclosed in double quotes and separated by commas, except for the last item. No trailing comma after the last item. Here's the text: "${selectedText}"`;

      // Use the getTranslationResult helper to fetch alternative suggestions
      const alternativeTextsJson = await getTranslationResult(prompt);

      // Before parsing, ensure the response is a valid JSON string
      console.log("Alternative Texts JSON:", alternativeTextsJson);

      // Assuming the response is a JSON string that includes an array of alternatives
      let alternativeTexts;
      try {
        alternativeTexts = JSON.parse(alternativeTextsJson);
      } catch (parseError) {
        console.error("Failed to parse JSON:", parseError);
        // Fallback or error handling logic here
        toast.error("Failed to parse alternative text suggestions.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        });
        return []; // Return an empty array or a suitable default
      }

      // Return the alternatives if the operation was successful
      return alternativeTexts;
    } catch (error) {
      console.error(
        "An error occurred while fetching alternative text suggestions: ",
        error
      );
      toast.error(
        "An error occurred while fetching alternative text suggestions.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        }
      );
      throw error; // Re-throw error after handling
    } finally {
      this.isDocumentsUpdating = false; // Indicate end of operation
    }
  };
  @action getEnhancedText = async (selectedText: string): Promise<string[]> => {
    this.isDocumentsUpdating = true; // Indicate start of operation
    try {
      // Construct the prompt for generating alternative texts
      const prompt = `Return 5 language-enhanced alternatives of the following sentence or paragraph while maintaining the original meaning and language. Ensure that the alternatives are returned in the same language as the original text. Format the output as a JSON array with each alternative enclosed in double quotes and separated by commas, except for the last item. No trailing comma after the last item. Here's the text: "${selectedText}"`;

      // Use the getTranslationResult helper to fetch alternative suggestions
      const alternativeTextsJson = await getTranslationResult(prompt);

      // Before parsing, ensure the response is a valid JSON string
      console.log("Alternative Texts JSON:", alternativeTextsJson);

      // Assuming the response is a JSON string that includes an array of alternatives
      let alternativeTexts;
      try {
        alternativeTexts = JSON.parse(alternativeTextsJson);
      } catch (parseError) {
        console.error("Failed to parse JSON:", parseError);
        // Fallback or error handling logic here
        toast.error("Failed to parse alternative text suggestions.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        });
        return []; // Return an empty array or a suitable default
      }

      // Return the alternatives if the operation was successful
      return alternativeTexts;
    } catch (error) {
      console.error(
        "An error occurred while fetching alternative text suggestions: ",
        error
      );
      toast.error(
        "An error occurred while fetching alternative text suggestions.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        }
      );
      throw error; // Re-throw error after handling
    } finally {
      this.isDocumentsUpdating = false; // Indicate end of operation
    }
  };

  @action getHeaderPromptResult = async (prompt: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      try {
        let content_prompt = `Just answer. What would the header for this paragraph be?: \n\n'${prompt}'"`;
        const nameResult = await getTranslationResult(content_prompt);
        resolve(nameResult);
      } catch (error) {
        reject(error);
      }
    });
  };

  @action getVoicePromptResult = async (
    prompt: string,
    block_text: string
  ): Promise<string> => {
    try {
      // console.log("getVoicePromptResult prompt", prompt);
      let content_prompt = `Return only the adjusted 'Text', just answer, don't use quote marks around the returning adjusted 'Text'. I am going to send you a 'Prompt' and a 'Text' right now. You must take the 'Text' and make the changes mentioned in the 'Prompt' with the 'Text', then return ONLY the adjusted 'Text' without changing anything else in the 'Text' (don't change the 'Text's language either, the 'Prompt' might be in an another language and the 'Text' may be multilangual, keep the multilangual state.).\n\n 'Prompt': \n\n """${prompt}"""\n\n'Text': \n\n"""${block_text}"""`;
      const translatedText = await getTranslationResult(content_prompt);
      // console.log("getVoicePromptResult translatedText", translatedText);
      return translatedText;
    } catch (error) {
      // Handle error
      console.error("Error in getVoicePromptResult:", error);
      throw error;
    }
  };

  @action addNewDocument = async (
    documentType: string
  ): Promise<DocumentItem> => {
    return new Promise(async (resolve, reject) => {
      this.isDocumentsUpdating = true;

      this.selectedDocumentItem = undefined;

      const newDocumentItem = await createDocument(
        `New Document`,
        documentType,
        undefined,
        stores.userStore.currentUser.id,
        moment().format("YYYY/MM/DD HH:mm"),
        new Date().getTime()
      );

      this.documents.unshift(newDocumentItem);

      this.selectedDocumentItem = newDocumentItem;

      resolve(newDocumentItem);

      Analytics.track("add_new_document_button_clicked", "");

      this.isDocumentsUpdating = false;

      resolve(newDocumentItem);
    });
  };

  @action addNewDocumentWithHeader = async (
    name: string,
    documentType: string
  ): Promise<DocumentItem> => {
    return new Promise(async (resolve, reject) => {
      this.isDocumentsUpdating = true;
      this.selectedDocumentItem = undefined;

      const newDocumentItem = await createDocument(
        name,
        documentType,
        undefined,
        stores.userStore.currentUser.id,
        moment().format("YYYY/MM/DD HH:mm"),
        new Date().getTime()
      );

      this.documents.unshift(newDocumentItem);
      this.selectedDocumentItem = newDocumentItem;

      Analytics.track("add_new_document_button_clicked", "");

      this.isDocumentsUpdating = false;
      resolve(newDocumentItem);
    });
  };

  @action updateDocumentName = async (
    documentItem: DocumentItem,
    documentNameTextInputValue: string
  ): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      await updateDocument(
        documentItem.id,
        documentNameTextInputValue,
        documentItem.document_language,
        documentItem.translation_language,
        documentItem.user_id,
        documentItem.date
      );

      const documentIndex = this.documents.findIndex(
        (item) => item.id === documentItem.id
      );

      if (documentIndex !== -1) {
        this.documents[documentIndex].name = documentNameTextInputValue;
      }
      resolve(true);
    });
  };

  @action updateDocumentLanguages = async (
    documentItem: DocumentItem,
    documentLanguage: string | undefined,
    translationLanguage: string | undefined
  ): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      await updateDocument(
        documentItem.id,
        documentItem.name,
        documentLanguage,
        translationLanguage,
        documentItem.user_id,
        documentItem.date
      );

      const documentIndex = this.documents.findIndex(
        (item) => item.id === documentItem.id
      );

      if (documentIndex !== -1) {
        this.documents[documentIndex].document_language = documentLanguage;

        this.documents[documentIndex].translation_language =
          translationLanguage;

        if (this.selectedDocumentItem) {
          this.selectedDocumentItem.document_language = documentLanguage;
          this.selectedDocumentItem.translation_language = translationLanguage;
        }
      }
      resolve(true);
    });
  };

  @action deleteDocument = async (
    documentItem: DocumentItem
  ): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      this.documents = this.documents.filter(
        (item) => item.id !== documentItem.id
      );

      setTimeout(() => {
        this.selectedDocumentItem = undefined;
        this.selectedDocumentContent = undefined;
      }, 300);

      const result = await deleteDocument(documentItem.id);

      resolve(result);
    });
  };

  @action getCurrentDocumentHistoryItem = async (
    documentItem: DocumentItem
  ): Promise<DocumentHistoryItem | undefined> => {
    return new Promise(async (resolve, reject) => {
      this.isDocumentsUpdating = true;

      const currentDocumentHistoryItem = await getCurrentDocumentHistory(
        documentItem.id
      );

      if (currentDocumentHistoryItem) {
        this.selectedDocumentContent = currentDocumentHistoryItem.output_data;
      }

      resolve(currentDocumentHistoryItem);

      this.isDocumentsUpdating = false;
    });
  };

  @action setDocumentHistoryItem = async (
    documentItem: DocumentItem,
    currentDocumentHistoryItem: DocumentHistoryItem | undefined,
    content: OutputData
  ): Promise<DocumentHistoryItem> => {
    // Shared documents for the current user are retrieved
    const sharedDocuments = await getSharedDocuments(
      stores.userStore.currentUser.id
    );

    // Current document history item is retrieved for authorization check
    const itemHistory = await this.getCurrentDocumentHistoryItem(documentItem);

    let hasAccess = false;

    // Check if the document is among the user's documents
    const docs = await getDocuments(stores.userStore.currentUser.id);
    const isMatchFound = docs.some((doc) => doc.id === documentItem.id);

    if (
      isMatchFound ||
      sharedDocuments.some((item) => item.document_id === itemHistory?.doc_id)
    ) {
      // User has access to the document either directly or through shared documents
      hasAccess = true;
    } else {
      // If there's no match and the user doesn't have access, show an error and do not proceed
      toast.error("You do not have access to this document");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return Promise.reject(new Error("No authorization"));
    }

    if (!hasAccess) {
      // If the user does not have access, exit the function without updating the document
      return Promise.reject(new Error("No authorization to update document"));
    }

    // Proceed with updating the document history since the user has access
    return new Promise(async (resolve, reject) => {
      this.selectedDocumentContent = content;

      if (currentDocumentHistoryItem) {
        // Update the existing document history item
        const updatedDocumentHistoryItem = currentDocumentHistoryItem;

        updatedDocumentHistoryItem.output_data = content;
        updatedDocumentHistoryItem.updated_at = Date.now();

        await updateDocumentHistory(
          currentDocumentHistoryItem.id,
          updatedDocumentHistoryItem.output_data,
          updatedDocumentHistoryItem.updated_at
        );

        resolve(updatedDocumentHistoryItem);
      } else {
        // Create a new document history item if it doesn't exist
        const newDocumentHistoryItem = await createDocumentHistory(
          documentItem.id,
          content,
          Date.now()
        );

        resolve(newDocumentHistoryItem);
      }

      // Emit an event after updating the document history
      Emitter.emit("UPDATE_DOCUMENT_HISTORY_ITEM", {});
    });
  };

  @action translate = async (
    blockId: string,
    language: string
  ): Promise<TranslationItem> => {
    return new Promise(async (resolve, reject) => {
      this.isDocumentsUpdating = true;
      let blockData: OutputBlockData | undefined = undefined;

      if (this.selectedDocumentContent) {
        blockData = this.selectedDocumentContent.blocks.find(
          (block) => block.id === blockId
        );
      }

      this.isDocumentsUpdating = true;

      if (blockData && blockData.id) {
        // Additional checks for blockData.data and blockData.data.text
        if (
          blockData.data &&
          blockData.data.text &&
          blockData.data.text.trim() !== ""
        ) {
          const blockText = blockData.data.text;
          const translatedText = await this.translateSelection(
            language,
            blockText
          );

          const newTranslationItem = await createTranslation(
            blockData.id,
            String.stringToKey(blockText),
            language,
            blockText,
            String.formatText(translatedText),
            Date.now()
          );

          resolve(newTranslationItem);
        }
      }

      this.isDocumentsUpdating = false;
    });
  };

  @action translateAll = async (
    language: string
  ): Promise<OutputData | undefined> => {
    return new Promise(async (resolve, reject) => {
      this.isDocumentsUpdating = true;
      this.isTranslationCompleted = false;

      if (
        this.selectedDocumentItem &&
        this.selectedDocumentContent &&
        this.selectedDocumentContent.blocks
      ) {
        for (
          let index = 0;
          index < this.selectedDocumentContent.blocks.length;
          index++
        ) {
          const blockData = this.selectedDocumentContent.blocks[index];

          const result = Functions.checkBlankCharacter(blockData.data.text);

          if (!result) {
            toast.warning("Translation not possible for blank text");
          }

          if (blockData && blockData.id && result) {
            // if only one language display mode is shown, it will switch to multi-view mode
            if (
              blockData.data &&
              blockData.data.text &&
              blockData.data.text.trim() !== ""
            ) {
              const blockText = blockData.data.text;
              const viewMode = stores.userStore.currentUser.settings.view_mode;

              // if only one language display mode is shown, it will switch to multi-view mode
              if (
                viewMode === ViewMode.OnlyOriginal ||
                viewMode === ViewMode.OnlyTranslation
              ) {
                stores.userStore.currentUser.settings.view_mode =
                  ViewMode.BottomView;
                stores.userStore.updateUserData();
              }

              const translatedText = await this.translateSelection(
                language,
                blockText
              );

              await createTranslation(
                blockData.id,
                String.stringToKey(blockText),
                language,
                blockText,
                String.formatText(translatedText),
                Date.now()
              );

              //@ts-ignore
              this.selectedDocumentContent.blocks[index].tunes["translation"] =
                TuneType.Translated;

              Emitter.emit("UPDATE_EDITOR_DATA", this.selectedDocumentContent);
            }
          }
        }

        await this.setDocumentHistoryItem(
          this.selectedDocumentItem,
          undefined,
          this.selectedDocumentContent
        );
      }

      this.isTranslationCompleted = true;

      resolve(this.selectedDocumentContent);

      this.isDocumentsUpdating = false;
    });
  };

  @action updateTranslationItem = async (
    translationItem: TranslationItem,
    text: string
  ): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      this.isDocumentsUpdating = true;

      const result = await updateTranslation(
        translationItem.id,
        translationItem.language,
        text,
        Date.now()
      );

      this.isDocumentsUpdating = false;

      resolve(result);
    });
  };

  private translateSelection = async (
    language: string,
    text: string
  ): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      Functions.saveLanguageUsage(language);
      try {
        const splittedText = String.splitStringIntoSentences(text);
        let result = "";

        if (splittedText.length === 0) {
          toast.warn(i18n.common.notValidParagraphWarning, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: stores.ui.theme,
          });

          return;
        }

        for (let index = 0; index < splittedText.length; index++) {
          const value = splittedText[index];

          this.isDocumentsUpdating = true;

          result += await this.translateText(language, value);
        }

        resolve(result);

        this.isDocumentsUpdating = false;
      } catch (error) {
        console.error(error);

        toast.error(i18n.common.translateError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        });

        this.isDocumentsUpdating = false;
      }
    });
  };

  private translateText = async (
    language: string,
    text: string
  ): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      try {
        let prompt = `Please act as a translator, spell checker, and text improver in [language]. Detect the language of the input, translate it, and enhance the text by correcting any spelling mistakes and upgrading basic words and sentences to more elegant and advanced equivalents in [language]. Keep the original meaning intact and make the language more literary. Do not engage in a conversational response or provide explanations; only deliver the corrected and improved text. Here is my paragraph: '[selection]'`;

        prompt = prompt.replaceAll("[selection]", text);
        prompt = prompt.replaceAll(
          "[language]",
          keys.languages.find((lang) => lang.code === language)!.title
        );

        let result = await translate(prompt);

        result = Functions.cleanDoubleQuoteString(result, text);

        if (result) {
          resolve(result);
        } else {
          resolve("");
        }
      } catch (error) {
        this.isDocumentsUpdating = false;

        toast.error(i18n.common.translateError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        });

        reject();
      }
    });
  };
}
