import keys from "../constants/keys";
import { PromptMode } from "../models/RegenerateMode";

import Analytics from "./Analytics";
import { StringType } from "./Enum";

declare global {
  interface StringConstructor {
    formatText(value: string): string;
    stringToKey(value: string): string;
    clearSpecialCharacters(value: string): string;
    clearSpecialTags(value: string): string;
    clearToolTags(value: string): string;
    typeOf(value: string): StringType;
    splitStringIntoSentences(value: string): string[];
    stripHtmlTags(value: string): string;
    cleanDoubleQuoteString(value: string): string;
  }
}

String.formatText = (value: string) => {
  return String.clearSpecialCharacters(value).trim();
};

String.clearSpecialCharacters = (value: string) => {
  return value.replaceAll(/(\r\n|\n|\r)/gm, "");
};

String.clearSpecialTags = (value: string) => {
  // clear special tags
  const regex = /&(nbsp|amp|quot|lt|gt);/g;
  let result = value.replace(regex, " ");

  // clear html tags
  result = result.replace(/<[^>]*>.*?<\/[^>]*>/gs, "");

  return result;
};

String.clearToolTags = (value: string) => {
  let result = value.replace(/<sup[^>]*>(.*?)<\/sup>/g, "");

  result = result.replace(/<\/?[^>]+(>|$)/g, "");

  return result;
};

String.splitStringIntoSentences = (value) => {
  if (value.length > keys.characterLimits.translationBlockCharacterLimit) {
    let sentences = value.match(/[^.!?]+[.!?]+/g);

    if (!sentences) return [];

    for (let i = 0; i < sentences.length; ++i) {
      sentences[i] = sentences[i].trim();
      if (sentences[i].split(" ").length <= 5 && i !== 0) {
        // check if it's not the first sentence
        sentences[i - 1] += " " + sentences[i];
        sentences.splice(i, 1);
        i--; // adjust the index due to the removal
      }
    }
    return sentences;
  }
  return [value];
};

String.stringToKey = (value: string) => {
  let str = String.clearSpecialCharacters(String.clearSpecialTags(value));

  const trChars = {
    ı: "i",
    İ: "I",
    ş: "s",
    Ş: "S",
    ğ: "g",
    Ğ: "G",
    ü: "u",
    Ü: "U",
    ö: "o",
    Ö: "O",
    ç: "c",
    Ç: "C",
  };

  str = str.replace(/[ıİşŞğĞüÜöÖçÇ]/g, function (c) {
    //@ts-ignore
    return trChars[c];
  });

  // Replace special characters and spaces with underscores
  str = str.replace(/[^a-zA-Z0-9]/g, "_");
  // Split the string on underscores
  let stringArray = str.split("_");
  // Loop through the resulting array and uppercase the first letter of each word
  for (var i = 0; i < stringArray.length; i++) {
    stringArray[i] =
      String(stringArray[i]).charAt(0).toUpperCase() +
      String(stringArray[i]).substring(1, String(stringArray[i]).length);
  }
  // Join the array into a single string
  return stringArray.join("");
};

String.typeOf = (value: string) => {
  const trimmedInput = value.trim();
  const punctuationCount = (trimmedInput.match(/[.!?]/g) || []).length;
  const wordCount = (trimmedInput.match(/\b(\w+)\b/g) || []).length;

  if (trimmedInput.length === 0) {
    return StringType.None;
  } else if (punctuationCount > 1 && wordCount > 1) {
    Analytics.track("editor_paragraph_selected", "");

    return StringType.Paragraph;
  } else if (punctuationCount === 1 && wordCount > 1) {
    Analytics.track("editor_sentence_selected", "");

    return StringType.Sentence;
  } else if (wordCount === 1) {
    Analytics.track("editor_word_selected", "");

    return StringType.Word;
  } else {
    Analytics.track("editor_text_selected", "");

    return StringType.Text;
  }
};

String.stripHtmlTags = (text: string) => {
  const regex = /(<([^>]+)>)/gi;
  return text.replace(regex, "");
};

const getPassTimeInfo = (prevTime: number) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = new Date().getTime() - prevTime;

  if (elapsed < msPerMinute) {
    return `${String(Math.round(elapsed / 1000))} seconds ago`;
  } else if (elapsed < msPerHour) {
    return `${String(Math.round(elapsed / msPerMinute))} minutes ago`;
  } else if (elapsed < msPerDay) {
    return `${String(Math.round(elapsed / msPerHour))} hours ago`;
  } else if (elapsed < msPerMonth) {
    return `${String(Math.round(elapsed / msPerDay))} day ago`;
  } else if (elapsed < msPerYear) {
    return `${String(Math.round(elapsed / msPerMonth))} month ago`;
  } else {
    return `${String(Math.round(elapsed / msPerYear))} year ago`;
  }
};

const getKeyByPromptModeValue = (value: string): keyof typeof PromptMode => {
  const indexOfValue = (
    Object.values(PromptMode) as unknown as string[]
  ).indexOf(value);

  const key = Object.keys(PromptMode)[indexOfValue];

  return key as keyof typeof PromptMode;
};

const wrapTextWithSpan = (
  text: string,
  targetText: string,
  backgroundColor: string
): string => {
  const startTag = `<span style="color: ${backgroundColor}">`;
  const endTag = "</span>";

  const startIndex = text.indexOf(targetText);
  if (startIndex === -1) {
    // Hedef metin bulunamadıysa, orijinal metni döndür
    return text;
  }

  const endIndex = startIndex + targetText.length;
  const wrappedText =
    text.substring(0, startIndex) +
    startTag +
    text.substring(startIndex, endIndex) +
    endTag +
    text.substring(endIndex);

  return wrappedText;
};

const cleanDoubleQuoteString = (
  translationText: string,
  originalText: string
): string => {
  if (translationText.startsWith('"') && !originalText.startsWith('"')) {
    // Remove only the leading double quote of string1
    translationText = translationText.slice(1);
  }

  if (translationText.endsWith('"') && !originalText.endsWith('"')) {
    // Remove only the trailing double quote of string1
    translationText = translationText.slice(0, -1);
  }

  return translationText;
};

const hideEmail = (email: string | undefined, hidePercentage: number) => {
  if (!email) return "";

  const atIndex = email.indexOf("@");

  if (atIndex === -1) {
    return email;
  }

  const username = email.slice(0, atIndex);
  const domain = email.slice(atIndex + 1);

  const hiddenUsername = hideCharacters(username, hidePercentage);
  const hiddenDomain = hideCharacters(domain, hidePercentage);

  return hiddenUsername + "@" + hiddenDomain;
};

const hideCharacters = (str: string, hidePercentage: number) => {
  const charCount = Math.floor(str.length * hidePercentage);
  const hiddenPart = "*".repeat(charCount);
  const visiblePart = str.slice(charCount);

  return hiddenPart + visiblePart;
};

function checkBlankCharacter(inputString: string): boolean {
  //  '&nbsp;' Remove expressions and spaces.
  const stringWithoutNbspAndSpace = inputString.replace(/&nbsp;| /g, '');

  // If the remaining string is empty, the original string will only be '&nbsp;' and/or consisted of spaces.
  const containsOtherThanNbspAndSpace = stringWithoutNbspAndSpace.length > 0;

  return containsOtherThanNbspAndSpace;
}

function saveLanguageUsage(langCode: string): void {
  let languageUsage = localStorage.getItem("languageUsage");
  let usage: Record<string, number> = languageUsage ? JSON.parse(languageUsage) : {};

  if (usage[langCode]) {
    usage[langCode]++;
  } else {
    usage[langCode] = 1;
  }

  if (usage[langCode] === 20) {
    usage = {}; 
  }

  localStorage.setItem("languageUsage", JSON.stringify(usage));
}

const getMostUsedLanguages = (): string[] => {
  let languageUsage = localStorage.getItem("languageUsage");
  let usage: Record<string, number> = languageUsage ? JSON.parse(languageUsage) : {};

  const mostUsedLanguages: string[] = Object.entries(usage)
    .sort((a, b) => b[1] - a[1]) 
    .slice(0, 3)
    .map((entry) => {
      return entry[0]; 
    });

  return mostUsedLanguages;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  hideEmail,
  cleanDoubleQuoteString,
  wrapTextWithSpan,
  getPassTimeInfo,
  getKeyByPromptModeValue,
  checkBlankCharacter,
  saveLanguageUsage,
  getMostUsedLanguages,
};
