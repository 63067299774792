// @flow

import { gql } from "apollo-boost";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { User, UserSettings } from "../../models/User";

export default async function createUser(
  name: string | undefined,
  auth0_id: string,
  email: string | undefined,
  email_verified: boolean | undefined,
  gender: string | undefined,
  locale: string | undefined,
  phone_number: string | undefined,
  phone_number_verified: boolean | undefined,
  settings: UserSettings,
  openai_api_key: string | undefined
): Promise<User> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createUser(
        auth0_id: "${auth0_id}"
        name: "${name}"
        email: "${email}"
        email_verified: ${Number(email_verified)}
        gender: "${gender}"
        locale: "${locale}"
        phone_number: "${phone_number}"
        phone_number_verified: ${Number(phone_number_verified)}
        settings: "${encodeURIComponent(JSON.stringify(settings))}"
        openai_api_key: "${openai_api_key}"
      ) {
        id
        auth0_id
        name
        email
        email_verified
        gender
        locale
        phone_number
        phone_number_verified
        settings
        openai_api_key
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createUserMutationResult: any) => {
        const user: User = get(
          createUserMutationResult,
          "data.createUser",
          null
        );

        if (user) {
          //@ts-ignore
          user.settings = JSON.parse(decodeURIComponent(user.settings));

          resolve(user);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
