/* @flow */

import keys from "../../constants/keys";
import { PaymentMethod } from "../../models/PaymentMethod";

export default async function attachPaymentMethod(
  customerId: string,
  paymentMethodId: string
): Promise<PaymentMethod> {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customerId: customerId,
        paymentMethodId: paymentMethodId,
      }),
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/customer-attach-payment-method`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const paymentMethod: PaymentMethod = data;

          resolve(paymentMethod);
        } else {
          reject();
        }
      });
  });
}
