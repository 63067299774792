/* @flow */

import { computed } from "mobx";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import stores from "../../stores";

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: stores.ui.themeColors.secondaryBackground,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: stores.ui.themeColors.primary,
  },
}));

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      mainContainer: {
        display: "flex",
        flexDirection: "column",
        width: 500,
        height: 500,
        p: 3,
      },
      title: {
        color: stores.ui.themeColors.textPrimary,
        fontWeight: "bold",
        mb: 3,
      },
      description: {
        color: stores.ui.themeColors.textPrimary,
        mb: 1,
      },
      progressView: {
        mb: 3,
      },
      upgradeButton: {
        textTransform: "none",
        bgcolor: stores.ui.themeColors.primary,
        color: stores.ui.themeColors.textPrimary,
      },
      bottomButtonsContainer: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
      closeButton: {
        textTransform: "none",
        bgcolor: stores.ui.themeColors.secondaryBackground,
        color: stores.ui.themeColors.textPrimary,
      },
      featureContainer: {
        mt: 2,
        p: 1,
      },
      feature: {
        color: stores.ui.themeColors.textPrimary,
        mb: 1.5,
      },
    };

    return styles;
  }
}
