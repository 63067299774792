/* @flow */

import keys from "../../constants/keys";
import { Price } from "../../models/Price";

export default async function getPrices(): Promise<Price[]> {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(`${process.env.REACT_APP_API_URL}/prices`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        const prices: Price[] = data.data;

        resolve(prices);
      });
  });
}
