import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import styles from "./styles";

interface ScrollAnimationProps {
  index: number;
  children: React.ReactNode;
}
export const ScrollAnimation: React.FC<ScrollAnimationProps> = ({
  index,
  children,
}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [hasBeenRevealed, setHasBeenRevealed] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef && observer) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      setHasBeenRevealed(true);
    }
  }, [isIntersecting]);

  return (
    <Box
      ref={ref}
      sx={{
        ...styles.styleSheet.featureContainer,
        opacity: isIntersecting || hasBeenRevealed ? 1 : 0,
        pointerEvents: isIntersecting || hasBeenRevealed ? "auto" : "none",
        animation:
          isIntersecting || hasBeenRevealed
            ? `slideInRight 1s forwards, fadeIn 1s forwards`
            : "",
      }}
    >
      {children}
    </Box>
  );
};
