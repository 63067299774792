/* @flow */

import { computed } from "mobx";

import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        display: "flex",
        flexDirection: "column",
        width: 600,
        height: 400,
        pl: 1,
        pr: 1,
      },
      topContainer: {
        position: "fixed",
        width: 600,
        zIndex: 2,
        bgcolor: stores.ui.themeColors.selectedBackground,
        pb: 2,
      },
      searchBar: {
        display: "flex",
        ml: 2,
        mr: 2,
        fontSize: 14,
      },
      listContainer: {
        flex: 1,
        m: 2,
        mt: 16,
      },
      usersListContainer: {},
      itemContainer: {
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        bgcolor: stores.ui.themeColors.secondaryBackground,
        mb: 1,
        borderRadius: 1,
      },
      itemLabel: {},
      infoLabel: {
        color: stores.ui.themeColors.textSecondary,
      },
      clearButton: {
        textTransform: "none",
      },
      saveButton: {
        textTransform: "none",
      },
    };

    return styles;
  }
}
