/* @flow */

export default async function checkAPIKey(apiKey: string): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        apiKey: apiKey,
      }),
    };

    fetch(`${process.env.REACT_APP_API_URL}/checkAPIKey`, requestOptions).then(
      (response) => {
        if (response.status === 400) {
          resolve(false);
        } else if (response.status === 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    );
  });
}
