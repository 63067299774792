/* @flow */

import { computed } from "mobx";

import stores from "../../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      mainContainer: {
        display: "flex",
        flexDirection: "column",
        width: 500,
        height: 500,
        p: 3,
      },
      title: {
        color: stores.ui.themeColors.textPrimary,
        fontWeight: "bold",
      },
      description: {
        color: stores.ui.themeColors.textPrimary,
        mb: 1,
      },
      packageContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        mt: 2,
      },
      progress: {
        mt: 3,
        mb: 4,
      },
      purchasePackageItem: {
        display: "flex",
        flex: 1,
        width: "100%",
        alignItems: "center",
        p: 2,
        borderRadius: 1,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "gray",
        mb: 2,
      },
      priceTitleContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        ml: 2,
      },
      priceTitle: {
        fontSize: 16,
      },
      priceSave: {},
      price: {
        fontSize: 32,
      },
      pricePeriod: {
        ml: 0.5,
        mt: 0.5,
      },
      bottomButtonsContainer: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
      nextButton: {
        textTransform: "none",
        bgcolor: stores.ui.themeColors.secondaryBackground,
        color: stores.ui.themeColors.textPrimary,
      },
      backButton: {
        textTransform: "none",
        color: stores.ui.themeColors.textPrimary,
        bgColor: "transparent",
        mr: 2,
      },
      couponCodeContainer: {
        display: "flex",
        mb: 2,
        height: 40,
      },
      couponCodeBar: {
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "50%",
      },
      saveButton: {
        textTransform: "none",
        ml: 1,
      },
    };

    return styles;
  }
}
