// @flow

import { gql } from "apollo-boost";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { DocumentItem } from "../../models/DocumentItem";

export default async function createDocumentItem(
  name: string,
  documentLanguage: string | undefined,
  translationLanguage: string | undefined,
  userId: number,
  date: string,
  createdAt: number
): Promise<DocumentItem> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createDocument(
        name: "${name}"
        document_language: "${documentLanguage}"
        translation_language: "${translationLanguage}"
        user_id: ${userId}
        date: "${date}"
        created_at: ${Number(createdAt)}
      ) {
        id
        name
        # document_language is temporarily holding the documentType, beware!
        document_language
        user_id
        date
        created_at
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createDocumentMutationResult: any) => {
        const documentItem: DocumentItem = get(
          createDocumentMutationResult,
          "data.createDocument",
          null
        );

        if (documentItem) {
          resolve(documentItem);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
