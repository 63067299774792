/* @flow */

import { computed } from "mobx";

import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      mainContainer: {
        p: 3,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        bgcolor: stores.ui.themeColors.secondaryBackground,
        height: "100vh",
      },
      titleContainer: {
        display: "flex",
        alignItems: "center",
      },
      titleText: {
        flex: 1,
      },
      settingContainer: {
        display: "flex",
        flexDirection: "column",
        mt: 2,
      },
      settingRow: {
        display: "flex",
        alignItems: "center",
        marginTop: 20,
      },
      apiKeyLabelContainer: {
        display: "flex",
        flex: 1,
        alignItems: "center",
      },
      apiKeyLabellText: {
        color: stores.ui.themeColors.textSecondary,
      },
      captionContainer: {
        display: "flex",
        alignItems: "center",
      },
      captionText: {
        color: stores.ui.themeColors.textPrimary,
        mr: 1,
      },
      labelContainer: {
        display: "flex",
        flex: 1,
        alignItems: "center",
      },
      labelText: {
        flex: 1,
        color: stores.ui.themeColors.textSecondary,
      },
      colorPaletteContainer: {
        width: 120,
        display: "flex",
        padding: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: "solid",
        cursor: "pointer",
      },
      colorPaletteBox: {
        width: 20,
        height: 20,
        padding: 5,
        borderRadius: 5,
      },
      clearButton: {
        textTransform: "none",
        alignSelf: "flex-end",
        mt: 2,
        mr: 1,
      },
      saveButton: {
        textTransform: "none",
        alignSelf: "flex-end",
        mt: 2,
      },
    };

    return styles;
  }
}
