/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import {
  PromptMode,
  RegenerateMode,
  RegenerateModeType,
} from "../../models/RegenerateMode";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function getRegenerateModes(): Promise<RegenerateMode[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query PublicRegenerateModesQuery {
        publicRegenerateModes {
          id
          user_id
          name
          prompt
          is_translation
          types
          prompt_modes
        }
      }
    `;

    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
      })
      .then((regenerateModesQueryResult: any) => {
        const regenerateModes: RegenerateMode[] = get(
          regenerateModesQueryResult,
          "data.publicRegenerateModes",
          null
        );

        for (let index = 0; index < regenerateModes.length; index++) {
          regenerateModes[index].prompt = decodeURIComponent(
            regenerateModes[index].prompt
          );

          if (
            regenerateModes[index].types &&
            regenerateModes[index].types !== ""
          )
            regenerateModes[index].types_list = regenerateModes[index].types
              .split(",")
              .map((type) => type as RegenerateModeType);
          else regenerateModes[index].types_list = [];

          if (
            regenerateModes[index].prompt_modes &&
            regenerateModes[index].prompt_modes !== ""
          )
            regenerateModes[index].prompt_modes_list = regenerateModes[
              index
            ].prompt_modes
              .split(",")
              .map((mode) => mode as PromptMode);
          else regenerateModes[index].prompt_modes_list = [];
        }

        resolve(regenerateModes);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
