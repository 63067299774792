import { useState, useEffect } from "react";
import { useWhisper } from "@chengsokdara/use-whisper";
import stores from "../../../stores";
import {
  IconButton,
  Modal,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { OutputBlockData } from "@editorjs/editorjs";

import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import SettingsIcon from "@mui/icons-material/Settings";
import { text } from "body-parser";

const WhisperCall = () => {
  const {
    // recording,
    // speaking,
    // transcribing,
    transcript,
    // pauseRecording,
    startRecording,
    stopRecording,
  } = useWhisper({
    apiKey: stores.userStore.currentUser.openai_api_key,
    timeSlice: 2_000,
    streaming: false,
    removeSilence: true,
    whisperConfig: {
      prompt: "previous conversation, gerçekçi konuşma, realistic speech",
      temperature: 0.0,
      language: stores.documentStore.selectedDocumentItem?.translation_language,
    },
  });

  const [isRecording, setIsRecording] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
      handleStopRecording();
    } else {
      transcript.text = "";
      startRecording();
    }
    setIsRecording(!isRecording);
  };

  useEffect(() => {
    if (transcript.text) {
      setOpenModal(true);
    }
  }, [transcript.text]);

  const handleStopRecording = async () => {
    setIsRecording(false);
  };

  const changeText = (blockId: any, param: any) => {
    const doc_id = stores.documentStore.selectedDocumentItem!.id;

    const selectedBlockIndex =
      stores.documentStore.selectedDocumentContent!.blocks.findIndex(
        (item) => item.id === blockId
      );
    // console.log("selectedBlockIndex", selectedBlockIndex);
    const editorElement = document.querySelector(
      `#editorjs-document-${doc_id} > div > div.codex-editor__redactor > div:nth-child(${
        selectedBlockIndex + 1
      }) > div > div:nth-child(1) > div:nth-child(1) > div`
    );
    // console.log("editorElement", editorElement);
    if (editorElement) {
      if (editorElement.textContent) {
        // console.log(
        //   "changeText editorElement.textContent",
        //   editorElement.textContent
        // );
        // console.log(
        //   "changeText editorElement.innerHTML",
        //   editorElement.innerHTML
        // );
        editorElement.innerHTML = param;
      }
    }
  };

  const handleReplaceSelectedTextClick = async (result: any) => {
    if (
      stores.actionStore.selectedAreaWholeText &&
      stores.actionStore.selectedText
    ) {
      // Get paragraph text and selection indices
      const paragraphText = stores.actionStore.selectedAreaWholeText;
      const startIndex = paragraphText.indexOf(stores.actionStore.selectedText);
      const endIndex = startIndex + stores.actionStore.selectedText.length;

      // Construct new paragraph with replaced text
      let newParagraphText =
        paragraphText.slice(0, startIndex) +
        result +
        paragraphText.slice(endIndex);

      // Wrap replaced text in a span for highlighting
      const highlightedText = `<span class="cdx-marker">${result}</span>`;
      // console.log(
      //   "handleReplaceSelectedTextClick highlightedText:",
      //   highlightedText
      // );

      // Update text with temporary highlighting
      newParagraphText = newParagraphText.replace(result, highlightedText);
      // console.log(
      //   "handleReplaceSelectedTextClick newParagraphText1:",
      //   newParagraphText
      // );

      changeText(stores.actionStore.selectedBlockId!, newParagraphText);

      // Remove the span after 5 seconds
      setTimeout(() => {
        newParagraphText = newParagraphText.replace(highlightedText, result);
        // console.log(
        //   "handleReplaceSelectedTextClick newParagraphText2:",
        //   newParagraphText
        // );

        changeText(stores.actionStore.selectedBlockId!, newParagraphText);
      }, 5000);
    }
  };

  const handleTranscriptPromptResult = async (prompt: any) => {
    // console.log("handleTranscriptPromptResult called with prompt:", prompt);

    const textToReplace = stores.actionStore.selectedText;
    const docHistory = await stores.documentStore.getCurrentDocumentHistoryItem(
      stores.documentStore.selectedDocumentItem!
    );
    if (textToReplace) {
      // console.log("handleTranscriptPromptResult textToReplace:", textToReplace);
      const newTextToReplace = await stores.documentStore.getVoicePromptResult(
        prompt,
        textToReplace
      );
      // console.log(
      //   "handleTranscriptPromptResult newTextToReplace:",
      //   newTextToReplace
      // );
      if (newTextToReplace) handleReplaceSelectedTextClick(newTextToReplace); // Use newTextToReplace instead of textToReplace
    }

    await stores.documentStore.setDocumentHistoryItem(
      stores.documentStore.selectedDocumentItem!,
      docHistory,
      stores.documentStore.selectedDocumentContent!
    );
  };

  const buttonIcon = isRecording ? (
    <SettingsVoiceIcon sx={{ fontSize: 15 }} />
  ) : (
    <SettingsVoiceIcon sx={{ fontSize: 15 }} />
  );
  const buttonColor = isRecording ? "red" : "white";

  return (
    <div>
      {/* <Tooltip title="Toggle Action Voice"> */}
      <IconButton
        onClick={toggleRecording}
        style={{ color: buttonColor, gap: -5 }}
      >
        {buttonIcon}
        <SettingsIcon style={{ left: 5, fontSize: 15 }} />
      </IconButton>
      {/* </Tooltip> */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="voice-modal"
        aria-describedby="voice-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            backgroundColor: "rgb(25,25,25)",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              textAlign: "center",
              paddingBottom: 10,
              color: "white",
              fontWeight: "400",
            }}
          >
            Did we hear you right?
          </Typography>
          <TextField
            autoFocus
            id="transcript-textfield"
            variant="outlined"
            label="Please try to be very specific with your Action"
            fullWidth
            multiline
            defaultValue={transcript.text}
            InputProps={{ style: { color: "white", fontWeight: "500" } }} // Set text color to white
            InputLabelProps={{ style: { color: "gray", fontWeight: "500" } }} // Set label color to gray
            sx={{ bgcolor: "rgb(25,25,25)" }} // Add this line to set background color to white
          />
          <Button
            onClick={() => {
              setOpenModal(false);

              handleTranscriptPromptResult(
                // @ts-ignore
                document.getElementById("transcript-textfield").value
              );
            }}
          >
            Apply
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default WhisperCall;
