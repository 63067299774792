/* @flow */

import { OutputData } from "@editorjs/editorjs";
import { gql } from "apollo-boost";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";

export default async function updateDocumentHistory(
  id: number,
  editorData: OutputData,
  updatedAt: number
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateDocumentHistory(
        id: ${id}
        editor_data: "${encodeURIComponent(JSON.stringify(editorData))}"
        updated_at: ${updatedAt}
      ) 
    }
  `;

  const updateDocumentHistoryMutationResult = await client.mutate({ mutation });

  const result = get(
    updateDocumentHistoryMutationResult,
    "data.updateDocumentHistory",
    null
  );

  return result !== null && result[0] > 0;
}
