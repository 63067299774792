import { OutputBlockData } from "@editorjs/editorjs";
import { observer } from "mobx-react";
import React from "react";

import { TranslationItem } from "../../models/TranslationItem";
import stores from "../../stores";
import DynamicHeader from "../DynamicHeader";

type Props = {
  blockItem: OutputBlockData;
  isMenuVisible: boolean;
  translationItem?: TranslationItem;
  level: number;
  onKeyUp: (event: any) => any;
  onMouseUp: (event: any) => any;
  onDoubleCick: (event: any) => any;
  onContextMenu: (event: any) => any;
};

type State = {
  isFocused: boolean;
};

@observer
export default class TranslationHeaderItem extends React.Component<
  Props,
  State
> {
  private headerRef: any;

  constructor(props: any) {
    super(props);

    this.state = {
      isFocused: false,
    };
  }

  public getInnerText = () => {
    const { blockItem } = this.props;

    if (this.headerRef)
      return this.headerRef.inputRef.current.innerHTML !== blockItem.data.text
        ? this.headerRef.inputRef.current.innerHTML
        : "";
  };

  render() {
    const {
      blockItem,
      onMouseUp,
      onDoubleCick,
      onContextMenu,
      onKeyUp,
      level,
      translationItem,
      isMenuVisible,
    } = this.props;
    const { isFocused } = this.state;

    const { settings } = stores.userStore.currentUser;

    const itemStyle = {
      color: isMenuVisible
        ? settings.selected_paragraph_color
        : (!translationItem || translationItem.translation_text === "") &&
          !isFocused
        ? settings.non_translated_text_color
        : settings.translated_text_color,
      marginRight: 30,
      fontStyle:
        (!translationItem || translationItem.translation_text === "") &&
        !isFocused
          ? "italic"
          : "normal",
    };

    return (
      <DynamicHeader
        ref={(ref) => {
          if (ref) this.headerRef = ref;
        }}
        text={
          translationItem &&
          translationItem.translation_text &&
          translationItem.translation_text !== ""
            ? translationItem.translation_text
            : undefined
        }
        placeholder={String.clearToolTags(blockItem.data.text)}
        level={level}
        style={itemStyle}
        contentEditable={translationItem !== undefined}
        onContextMenu={onContextMenu}
        onKeyUp={onKeyUp}
        onFocus={() => this.setState({ isFocused: true })}
        onBlur={() => this.setState({ isFocused: false })}
        onDoubleClick={onDoubleCick}
        onMouseUp={onMouseUp}
      />
    );
  }
}
