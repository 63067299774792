// GradientTextSection.js
import React from "react";
import { Typography, Box } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@emotion/react";

interface GradientTextSectionProps {
  title: string;
  subTitle?: string;
  gradientIndices: number[];
  buttonText?: string;
  handleClick?: () => void;
  sx?: SxProps<Theme>;
  typographyVariant?: string;
}

export const GradientText: React.FC<GradientTextSectionProps> = ({
  title,
  gradientIndices,
  buttonText,
  handleClick,
  sx,
  typographyVariant = "h3",
}) => {
  return (
    <Box>
      <Typography variant={typographyVariant as any} sx={{ mb: 3 }}>
        {title.split(" ").map((word, index) => (
          <span
            key={index}
            style={{
              color: gradientIndices.includes(index)
                ? "transparent"
                : "inherit",
              backgroundImage: gradientIndices.includes(index)
                ? "linear-gradient(to top, rgba(255, 255, 255, 0.4) 20%, rgba(117, 226, 94, 1) 80% )"
                : "none",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              fontWeight: gradientIndices.includes(index) ? "bold" : "normal",
            }}
          >
            {word}{" "}
          </span>
        ))}
      </Typography>
    </Box>
  );
};
