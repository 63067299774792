import { Box, Button, Dialog, Typography } from "@mui/material";
import * as React from "react";
import { observer } from "mobx-react";

import keys from "../../constants/keys";
import i18n from "../../data/i18n";
import { Product } from "../../models/Product";
import stores from "../../stores";
import { SubscriptionCoupon } from "../../models/SubscriptionCoupon";

import PackagesView from "./PackagesView";
import StripeView from "./StripeView";
import styles, { BorderLinearProgress } from "./styles";

type Props = {
  open: boolean;
  onClose: () => void;
};

type State = {
  currentStep: number;
  selectedProduct?: Product;
  selectedSubscriptionCoupon?: SubscriptionCoupon;
};

@observer
export default class SubscriptionModal extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentStep: 0,
    };
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.open === false && this.props.open === true) {
      setTimeout(() => {
        this.setState({ currentStep: 0 });
      }, 1000);
    }
  }

  render() {
    const { open, onClose } = this.props;
    const { currentStep, selectedProduct, selectedSubscriptionCoupon } =
      this.state;

    const leftRegenerateModeCount =
      keys.proFeatureLimits.freeRegenerateModeCount -
      stores.regenerateStore.userRegenerateModes.length;

    const percentage =
      (100 *
        (stores.regenerateStore.userRegenerateModes.length >
        keys.proFeatureLimits.freeRegenerateModeCount
          ? keys.proFeatureLimits.freeRegenerateModeCount
          : stores.regenerateStore.userRegenerateModes.length)) /
      keys.proFeatureLimits.freeRegenerateModeCount;

    return (
      <Dialog onClose={onClose} open={open}>
        {currentStep === 0 ? (
          <Box sx={styles.styleSheet.mainContainer}>
            <Typography variant="body1" sx={styles.styleSheet.title}>
              {i18n.upgradeModal.upgrade}
            </Typography>

            <Typography variant="body2" sx={styles.styleSheet.description}>
              {i18n.formatString(
                i18n.upgradeModal.upgradeInfo,
                leftRegenerateModeCount <= 0 ? 0 : leftRegenerateModeCount,
                keys.proFeatureLimits.freeRegenerateModeCount
              )}
            </Typography>

            <BorderLinearProgress
              sx={styles.styleSheet.progressView}
              variant="determinate"
              value={percentage}
            />

            <Button
              sx={styles.styleSheet.upgradeButton}
              color="secondary"
              onClick={() => this.setState({ currentStep: 1 })}
            >
              {i18n.upgradeModal.upgrade}
            </Button>

            <Box sx={styles.styleSheet.featureContainer}>
              <Typography variant="body2" sx={styles.styleSheet.feature}>
                {i18n.upgradeModal.proFeatures.promptFeature}
              </Typography>

              <Typography variant="body2" sx={styles.styleSheet.feature}>
                {i18n.upgradeModal.proFeatures.customPromptFeature}
              </Typography>

              <Typography variant="body2" sx={styles.styleSheet.feature}>
                {i18n.upgradeModal.proFeatures.documentFeature}
              </Typography>

              <Typography variant="body2" sx={styles.styleSheet.feature}>
                {i18n.upgradeModal.proFeatures.exportFeature}
              </Typography>
            </Box>

            <Box style={styles.styleSheet.bottomButtonsContainer}>
              <Button
                sx={styles.styleSheet.closeButton}
                color="secondary"
                onClick={onClose}
              >
                {i18n.common.cancel}
              </Button>
            </Box>
          </Box>
        ) : null}

        {currentStep === 1 ? (
          <PackagesView
            onBackPress={() => this.setState({ currentStep: 0 })}
            onNextPress={() => this.setState({ currentStep: 2 })}
            onSelectedProductChange={(selectedProduct) =>
              this.setState({ selectedProduct })
            }
            onSubscriptionCouponSelect={(subscriptionCoupon) =>
              this.setState({ selectedSubscriptionCoupon: subscriptionCoupon })
            }
            selectedSubscriptionCoupon={selectedSubscriptionCoupon}
          />
        ) : null}

        {currentStep === 2 && selectedProduct ? (
          <StripeView
            selectedProduct={selectedProduct}
            selectedSubscriptionCoupon={selectedSubscriptionCoupon}
          />
        ) : null}
      </Dialog>
    );
  }
}
