import React from "react";
import { IconButton } from "@mui/material";
import { LinkedIn, YouTube } from "@mui/icons-material";

const SocialMediaLinks: React.FC = () => {
  return (
    <div>
      <IconButton
        href="https://www.linkedin.com/company/lingoedit/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedIn />
      </IconButton>
      <IconButton
        href="https://www.youtube.com/@lingoedit"
        target="_blank"
        rel="noopener noreferrer"
      >
        <YouTube />
      </IconButton>
    </div>
  );
};

export default SocialMediaLinks;
