/* @flow */

import keys from "../../constants/keys";

export default async function cancelSubscription(
  subscriptionId: string
): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ subscriptionId: subscriptionId }),
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/cancel-subscription`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          resolve(true);
        } else {
          reject();
        }
      });
  });
}
