/* @flow */

import { computed } from "mobx";

import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      sortableButtonContainer: {
        mr: "8px",
      },
      sortButton: {
        textTransform: "none",
        color: stores.ui.themeColors.textPrimary,
        borderRadius: 1,
        height: 50,
        pl: 2,
        pr: 2,
        mr: 2,
      },
    };

    return styles;
  }
}
