import stores from "../../../stores";
import React from "react";
import { createRoot } from "react-dom/client";
import IconButton from "@mui/material/IconButton";
import AutoAwesome from "@mui/icons-material/AutoAwesome";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { toast } from "react-toastify";
import { replace } from "lodash";
import WhisperCall from "../ActionBar/WhisperActionCall";

export default class ActionTool {
  static get isInline() {
    return true;
  }

  constructor({ api }) {
    this.api = api;
    this.menuAnchorEl = null;
    this.menuVisible = false;
    this.alternatives = [];
    this.menuContainer = document.createElement("div");
    document.body.appendChild(this.menuContainer);
    this.menuRoot = createRoot(this.menuContainer);

    this.renderMenu = this.renderMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
  }

  render() {
    const container = document.createElement("div");
    const buttonRoot = createRoot(container);
    buttonRoot.render(<WhisperCall />);
    return container;
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
    this.renderMenu();
  }

  handleMouseEnter(event) {
    this.menuAnchorEl = event.currentTarget;
  }

  renderMenu() {
    this.menuRoot.render();
  }

  closeMenu() {
    if (this.menuVisible) {
      this.menuVisible = false;
      this.renderMenu(); // Re-render the menu to update its open state
    }
  }

  surround(range) {
    if (this.state) {
      this.unwrap(range);
      return;
    }

    this.wrap(range);
  }

  wrap(range) {
    const tempElement = document.createElement(this.tag);
    tempElement.appendChild(range.cloneContents());

    // const selectedText = tempElement.innerHTML;
    // const wholeText = range.commonAncestorContainer.wholeText;
    let anchorEl = undefined;

    if (
      document.getElementsByClassName("ce-inline-toolbar--showed").length > 0
    ) {
      anchorEl = document.getElementsByClassName(
        "ce-inline-toolbar--showed"
      )[0];
    }

    stores.actionStore.setSelectionBlockId(
      anchorEl,
      this.api.blocks.getCurrentBlockIndex()
    );

    this.api.inlineToolbar.close();
  }

  unwrap(range) {}

  checkState() {
    const mark = this.api.selection.findParentTag(this.tag);

    this.state = !!mark;

    if (this.state) {
      // this.showActions(mark);
    } else {
      // this.hideActions();
    }

    let anchorEl = undefined;

    if (
      document.getElementsByClassName("ce-inline-toolbar--showed").length > 0
    ) {
      anchorEl = document.getElementsByClassName(
        "ce-inline-toolbar--showed"
      )[0];
    }

    stores.actionStore.setSelectionBlockId(
      anchorEl,
      this.api.blocks.getCurrentBlockIndex()
    );
  }

  renderActions() {
    this.colorPicker = document.createElement("input");
    this.colorPicker.type = "color";
    this.colorPicker.value = "#f5f1cc";
    this.colorPicker.hidden = true;

    return this.colorPicker;
  }
}
