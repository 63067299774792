// @flow

import { gql } from "apollo-boost";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { SharedDocumentItem } from "../../models/SharedDocumentItem";

export default async function createSharedDocument(
  creatorUserId: number,
  sharedUserId: number,
  documentId: number,
  permissions: string
): Promise<SharedDocumentItem> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createSharedDocument(
        creator_user_id: ${creatorUserId}
        shared_user_id: ${sharedUserId}
        document_id: ${documentId}
        permissions: "${permissions}"
      ) {
        id
        creator_user_id
        shared_user_id
        document_id
        permissions
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createSharedDocumentMutationResult: any) => {
        const sharedDocumentItem: SharedDocumentItem = get(
          createSharedDocumentMutationResult,
          "data.createSharedDocument",
          null
        );

        if (sharedDocumentItem) {
          resolve(sharedDocumentItem);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
