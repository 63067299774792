/* @flow */

import { computed } from "mobx";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      dialogContainer: {},
    };

    return styles;
  }
}
