/* @flow */

import { SubscriptionCoupon } from "../../models/SubscriptionCoupon";
import { Subscription } from "../../models/User";

export default async function createSubscription(
  customerId: string,
  price: string,
  coupon?: SubscriptionCoupon
): Promise<Subscription> {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customerId: customerId,
        price: price,
        coupon: coupon,
      }),
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/create-subscription`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const subscription: Subscription = data;

          resolve(subscription);
        } else {
          reject();
        }
      });
  });
}
