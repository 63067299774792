// @flow

import { gql } from "apollo-boost";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { LeadMailItem } from "../../models/LeadMailItem";

export default async function createLeadMail(
  mail: string
): Promise<LeadMailItem> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createLeadMail(
        mail: "${mail}"
      ) {
        id
        mail
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createLeadMailMutationResult: any) => {
        const leadMailItem: LeadMailItem = get(
          createLeadMailMutationResult,
          "data.createLeadMail",
          null
        );

        if (leadMailItem) {
          resolve(leadMailItem);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
