import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import React from "react";

import i18n from "../../../data/i18n";
import Analytics from "../../../helpers/Analytics";

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    Analytics.track("login_button_clicked", "");

    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <Button
      sx={{ mr: 2, textTransform: "none" }}
      variant="outlined"
      onClick={handleLogin}
    >
      {i18n.common.login}
    </Button>
  );
};
