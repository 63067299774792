import { Dialog } from "@mui/material";
import * as React from "react";
import ReactPlayer from "react-player/youtube";

type Props = {
  open: boolean;
  onClose: () => void;
};

type State = {};

export default class HelpModal extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    const { open, onClose } = this.props;

    return (
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "60vw",
            },
          },
        }}
        PaperProps={{
          sx: {
            maxHeight: "32vw",
          },
        }}
        onClose={onClose}
        open={open}
      >
        <ReactPlayer
          url="https://www.youtube.com/watch?v=KiGmwPP9n-0"
          pip={true}
          width={"60vw"}
          height={"32vw"}
          playing={true}
        />
      </Dialog>
    );
  }
}
