/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import { SharedDocumentItem } from "../../models/SharedDocumentItem";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function getSharesOfDocuments(
  documentId: number
): Promise<SharedDocumentItem[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query SharesOfDocumentsQuery($document_id: ID!) {
        sharesOfDocuments(document_id: $document_id) {
          id
          creator_user_id
          shared_user_id
          document_id
          permissions
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          document_id: documentId,
        },
        fetchPolicy: "no-cache",
      })
      .then((sharesOfdocumentQueryResult: any) => {
        const sharesOfDocuments: SharedDocumentItem[] = get(
          sharesOfdocumentQueryResult,
          "data.sharesOfDocuments",
          null
        );

        resolve(sharesOfDocuments);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
