import LocalizedStrings from "react-localization";

import en from "./langs/en.json";
// import bn from "./langs/bn.json";
// import cs from "./langs/cs.json";
// import de from "./langs/de.json";
// import es from "./langs/es.json";
// import fr from "./langs/fr.json";
// import hi from "./langs/hi.json";
// import hu from "./langs/hu.json";
// import id from "./langs/id.json";
// import it from "./langs/it.json";
// import ja from "./langs/ja.json";
// import ko from "./langs/ko.json";
// import ms from "./langs/ms.json";
// import nl from "./langs/nl.json";
// import pa from "./langs/pa.json";
// import pl from "./langs/pl.json";
// import pt from "./langs/pt.json";
// import ro from "./langs/ro.json";
// import ru from "./langs/ru.json";
// import sk from "./langs/sk.json";
// import sr from "./langs/sr.json";
// import sv from "./langs/sv.json";
// import ta from "./langs/ta.json";
// import uk from "./langs/uk.json";
// import ur from "./langs/ur.json";
// import vi from "./langs/vi.json";
// import zh from "./langs/zh.json";

const strings = new LocalizedStrings({
  en,
  // bn,
  // cs,
  // de,
  // es,
  // fr,
  // hi,
  // hu,
  // id,
  // it,
  // ja,
  // ko,
  // ms,
  // nl,
  // pa,
  // pl,
  // pt,
  // ro,
  // ru,
  // sk,
  // sr,
  // sv,
  // ta,
  // uk,
  // ur,
  // vi,
  // zh,
});

export default strings;
