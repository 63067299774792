/* @flow */

import { computed } from "mobx";

import stores from "../../../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      resultList: {
        flex: 1,
      },
      promptItemContainer: {
        m: 1,
      },
      promptItem: {
        p: 2,
        whiteSpace: "normal",
        borderRadius: 1,
        bgcolor: stores.ui.themeColors.selectedBackground + "30",
      },
      promptItemToolbar: {
        display: "flex",
        justifyContent: "end",
        pb: 1,
      },
      toolbarItem: {
        padding: 5,
        width: 42,
        height: 42,
      },
      toolbarItemIcon: {
        opacity: stores.ui.theme === "dark" ? 1 : 0.6,
        width: 32,
        height: 32,
      },
      divider: {
        mb: 1,
      },
      mainContainer: {
        flex: 1,
        overflow: "auto",
      },
      topContainer: {
        position: "sticky",
        top: 0,
        bgcolor: stores.ui.themeColors.secondaryBackground,
        zIndex: 2,
      },
      iconButton: {
        alignSelf: "flex-start",
        ml: 2,
        mr: 0,
      },
      topTextContainer: {
        p: 2,
        display: "flex",
      },
      topTextTitle: {
        mr: 1,
        flex: 0.22,
      },
      topText: {
        flex: 1,
        fontStyle: "italic",
      },
      regenerateMenuItem: {
        maxHeight: "100%",
        overflow: "auto",
      },
      loadingInfoText: {
        color: stores.ui.themeColors.textPrimary,
        opacity: 0.5,
        m: 2,
      },
    };

    return styles;
  }
}
