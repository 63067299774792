/* @flow */

import { computed } from "mobx";

import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
      },
      inputContainer: {
        flex: 1,
        display: "flex",
      },
      formContainer: {
        flex: 1,
      },
      helperText: {
        mt: 1,
        ml: 1.5,
        mr: 1,
        flex: 1,
        justifyContent: "flex-start",
        color: stores.ui.themeColors.textWarning,
      },
    };

    return styles;
  }
}
