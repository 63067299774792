import React, { useEffect, useState } from "react";
import { Grid, Typography, List, ListItem, ListItemText } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import SocialMediaLinks from "./SocialMediaLinks";

const Footer: React.FC = () => {
  const isXs = useMediaQuery("(max-width:600px)");
  const [blogs, setBlogs] = useState<{ url: string; title: string }[]>([]);

  useEffect(() => {
    const blogsData = [
      {
        url: "https://blog.lingoedit.com/uncover-intricate-meanings-behind-wo-ai-ni-chinese-love-phrases/",
        title:
          "Discover The Meaning & Significance Of Chinese Love Phrases | Wo Ai Ni",
      },
      {
        url: "https://blog.lingoedit.com/2023s-best-apps-for-document-editing-find-perfect-app-your-needs/",
        title: "2023'S Best Apps To Edit Docs: Find The Perfect App For You!",
      },
      {
        url: "https://blog.lingoedit.com/learn-how-to-view-word-document-editing-history-comprehensive-step-by-step-guide/",
        title: "Word Doc History: A Step-By-Step Guide To View Edits In 2020",
      },
    ];

    const updatedBlogs = blogsData.map((blog) => {
      const shortenedTitle =
        blog.title.length > 20
          ? `${blog.title.substring(0, 20)}...`
          : blog.title;
      return { url: blog.url, title: shortenedTitle };
    });

    setBlogs(updatedBlogs);
  }, []);

  const handleLogoClick = () => {
    window.location.href = "https://www.lingoedit.com/";
  };

  return (
    <footer style={{ backgroundColor: "#191919" }}>
      <center>
        <Grid container paddingTop={5} paddingBottom={2} maxWidth={1200}>
          <Grid item xs={12} sm={4} style={{ textAlign: "center", marginTop: "20px" }}>
            <a href="https://www.lingoedit.com/" onClick={handleLogoClick} style={{ display: "block", marginTop: "20px" }}>
              <img
                height={120}
                src="/static/media/logo.8c6051c75fcae24b2c43.png"
                alt="Görsel"
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Useful Links
            </Typography>
            <List style={{ maxWidth: "70%", margin: "0 auto", color: "white" }}>
              <ListItem
                component="a"
                href="/faq"
                style={{ justifyContent: "center", textDecoration: "none" }}
              >
                <ListItemText
                  primary="FAQ"
                  style={{ color: "white", textAlign: "center" }}
                />
              </ListItem>
              <ListItem
                component="a"
                href="/pricing"
                style={{ justifyContent: "center", textDecoration: "none" }}
              >
                <ListItemText
                  primary="Pricing"
                  style={{ color: "white", textAlign: "center" }}
                />
              </ListItem>
              <ListItem
                component="a"
                href="/features"
                style={{ justifyContent: "center", textDecoration: "none" }}
              >
                <ListItemText
                  primary="Features"
                  style={{ color: "white", textAlign: "center" }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" style={{ color: "white" }}>
              Popular Articles
            </Typography>
            <List style={{ maxWidth: "70%", margin: "0 auto", color: "white" }}>
              {blogs.map((blog, index) => (
                <ListItem
                  key={index}
                  component="a"
                  href={blog.url}
                  style={{
                    justifyContent: "center",
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  <ListItemText
                    primary={blog.title}
                    style={{ textAlign: "center", color: "white" }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </center>
      <center>
        <Grid
          container
          maxWidth={1200}
          justifyContent="space-between"
          bgcolor="#191919"
          style={{ textAlign: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={4}>
            {isXs ? (
              <SocialMediaLinks />
            ) : (
              <Typography variant="body2" color="textSecondary">
                <span style={{ fontSize: "larger" }}>
                  &copy; 2024 LingoEdit All rights reserved
                </span>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            {!isXs ? (
              <SocialMediaLinks />
            ) : (
              <Typography variant="caption" color="textSecondary">
                <span style={{ fontSize: "larger" }}>
                  Designed in ☀️ California & Built Globally With ❤️
                </span>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            {!isXs ? (
              <Typography variant="caption" color="textSecondary">
                <span style={{ fontSize: "larger" }}>
                  Designed in ☀️ California & Built Globally With ❤️
                </span>
              </Typography>
            ) : (
              <Typography variant="caption" color="textSecondary">
                <span style={{ fontSize: "larger" }}>
                  &copy; 2024 LingoEdit
                </span>
              </Typography>
            )}
          </Grid>
        </Grid>
      </center>
    </footer>
  );
};

export default Footer;
