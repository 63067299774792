import { observable, action, when } from "mobx";

import { SubscriptionCoupon } from "../models/SubscriptionCoupon";
import { getSubscriptionCoupons } from "../helpers/api";

import RootStore from "./RootStore";

export default class SubscriptionStore {
  @observable subscriptionCoupons: SubscriptionCoupon[] = [];

  constructor(rootStore: RootStore) {
    when(
      () => rootStore.userStore.isCurrentUserReady,
      () => {
        this.getSubscriptionCoupons();
      }
    );
  }

  @action getSubscriptionCoupons = async () => {
    this.subscriptionCoupons = await getSubscriptionCoupons();
  };
}
