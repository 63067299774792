/* @flow */

import { computed } from "mobx";

import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      drawer: {},
      container: {
        p: 3,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        bgcolor: stores.ui.themeColors.secondaryBackground,
        height: "100vh",
      },
      titleContainer: {
        display: "flex",
        alignItems: "center",
      },
      titleText: {
        flex: 1,
      },
      mainContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      },
      divider: {
        mb: 3,
        mt: 3,
        ml: -3,
        mr: -3,
      },
      settingRow: {
        display: "flex",
        alignItems: "center",
        marginBottom: 25,
      },
      actionContainer: {
        display: "flex",
        m: 1,
        justifyContent: "flex-end",
        alignItems: "flex-start",
      },
      actionContainerMain: {
        alignItems: "center",
        display: "flex",
        flex: 1,
      },
      actionButtonContainer: {
        m: 1,
        textAlign: "right",
      },
      actionButton: {
        textTransform: "none",
        m: 1,
      },
      promptCharacterCount: {
        position: "absolute",
        bottom: "8px",
        left: "8px",
      },
      examplePromptLabel: {
        m: 1,
        flex: 1,
        justifyContent: "flex-start",
        color: stores.ui.themeColors.textInfo,
      },
    };

    return styles;
  }
}
