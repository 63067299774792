


// ** Demo Imports
import PricingPlans from './pricing/PricingPlans'

// ** React Imports
import { useState, ChangeEvent } from 'react'


// ** MUI Imports
import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'
import MuiCardContent, { CardContentProps } from '@mui/material/CardContent'
import { Container } from '@mui/material'
import PricingHeader from './pricing/PricingHeader'
import MainHeader from '../HomePage/MainHeader'
import Footer from '../../components/Footer'
import stores from "../../stores";



// ** Styled Components
const CardContent = styled(MuiCardContent)<CardContentProps>(({ theme }) => ({
  padding: `${theme.spacing(20, 36)} !important`,
  [theme.breakpoints.down('xl')]: {
    padding: `${theme.spacing(0)} !important`
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(0, 0)} !important`
  }
}))

const data = [
  {
    imgWidth: 80,
    imgHeight: 80,
    title: "Basic",
    monthlyPrice: 0,
    currentPlan: stores.userStore.currentUser
      ? stores.userStore.currentUser.subscriptions
        ? false
        : true
      : true,
    popularPlan: false,
    subtitle: "A simple start for everyone",
    imgSrc: "../../assets/pricing-plan-basic.png",
    yearlyPlan: {
      perMonth: 0,
      totalAnnual: 0,
    },
    planBenefits: [
      "Limited translation languages",
      "Limited forms and surveys",
      "Basic grammar and spelling checks",
      "Limited fields",
    ]
  },
  {
    imgWidth: 80,
    imgHeight: 80,
    monthlyPrice: 9.99,
    title: "Premium",
    popularPlan: true,
    currentPlan: stores.userStore.currentUser
      ? stores.userStore.currentUser.subscriptions
        ? false
        : true
      : false,
    subtitle: "For unlimited translations",
    imgSrc: "/images/pages/pricing-plan-standard.png",
    yearlyPlan: {
      perMonth: 7.99,
      totalAnnual: 95.88,
    },
    planBenefits: [
      "Full access to all translation languages",
      "Unlimited block-by-block translations",
      "Comprehensive grammar and spelling checks",
      "Custom sentence editing tools",
      "Priority customer support",
      "Cancel anytime"
    ]
  }
]

const Pricing = () => {


  // ** States
  const [plan, setPlan] = useState<'monthly' | 'annually'>('annually')


  const handleChange = (e: ChangeEvent<{ checked: boolean }>) => {
    if (e.target.checked) {
      setPlan('annually')
    } else {
      setPlan('monthly')
    }
  }
  

  return (
    <div style={{ width: "100vw", backgroundColor: "#1e1e1e" }}>
      <MainHeader/>
      <Container maxWidth="md" >
        <Card>
          <CardContent>
            <div style={{ paddingTop: 150}}> <PricingHeader plan={plan} handleChange={handleChange} /></div>
            <div style={{paddingBottom: 100}}><PricingPlans plan={plan} data={data} /></div>
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </div>
  )
}


export default Pricing
