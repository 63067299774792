/* @flow */

import { Price } from "../../models/Price";
import { Product } from "../../models/Product";

import getPrices from "./getPrices";

export default async function getProducts(): Promise<Product[]> {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(`${process.env.REACT_APP_API_URL}/products`, requestOptions)
      .then((res) => res.json())
      .then(async (data) => {
        const products: Product[] = data.data;
        const prices: Price[] = await getPrices();

        for (let index = 0; index < products.length; index++) {
          const product = products[index];
          products[index].prices = [];

          prices.forEach((price) => {
            if (price.product === product.id) {
              products[index].prices.push(price);
            }
          });
        }

        resolve(products);
      });
  });
}
