/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import {
  PromptMode,
  RegenerateMode,
  RegenerateModeType,
} from "../../models/RegenerateMode";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function getRegenerateMode(
  id: number
): Promise<RegenerateMode> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query RegenerateModeQuery($id: ID!) {
        regenerateMode(id: $id) {
          id
          user_id
          name
          prompt
          is_translation
          types
          prompt_modes
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          id: id,
        },
        fetchPolicy: "no-cache",
      })
      .then((regenerateModeQueryResult: any) => {
        const regenerateMode: RegenerateMode = get(
          regenerateModeQueryResult,
          "data.regenerateMode",
          null
        );

        if (regenerateMode) {
          regenerateMode.prompt = decodeURIComponent(regenerateMode.prompt);

          if (regenerateMode.types && regenerateMode.types !== "")
            regenerateMode.types_list = regenerateMode.types
              .split(",")
              .map((type) => type as RegenerateModeType);
          else regenerateMode.types_list = [];

          if (regenerateMode.prompt_modes && regenerateMode.prompt_modes !== "")
            regenerateMode.prompt_modes_list = regenerateMode.prompt_modes
              .split(",")
              .map((mode) => mode as PromptMode);
          else regenerateMode.prompt_modes_list = [];

          resolve(regenerateMode);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
