/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import { User } from "../../models/User";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function getUser(id: number): Promise<User | undefined> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query UserQuery($id: ID!) {
        user(id: $id) {
          id
          auth0_id
          name
          email
          email_verified
          gender
          locale
          phone_number
          phone_number_verified
          settings
          openai_api_key
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          id: id,
        },
        fetchPolicy: "no-cache",
      })
      .then((userQueryResult: any) => {
        const user: User = get(userQueryResult, "data.user", null);

        if (user) {
          // if (user.settings)
          //   //@ts-ignore
          //   user.settings = JSON.parse(decodeURIComponent(user.settings));

          resolve(user);
        } else {
          resolve(undefined);
        }
      })
      .catch((error: any) => {
        console.error(error);

        resolve(undefined);
      });
  });
}
