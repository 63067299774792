/* @flow */

import { computed } from "mobx";

import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        ml: 2,
      },
      searchBar: {
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        flex: 1,
        height: 50,
      },
      searchButton: {
        textTransform: "none",
        color: stores.ui.themeColors.textPrimary,
        borderRadius: 1,
        height: 50,
        pl: 2,
        pr: 2,
        mr: 2,
      },
    };

    return styles;
  }
}
