import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import CloseIcon from "@mui/icons-material/Close";

import { getRegenerateMode } from "../../helpers/api";
import stores from "../../stores";
import { RegenerateModeType } from "../../models/RegenerateMode";
import i18n from "../../data/i18n";
import keys from "../../constants/keys";
import RegenerateModeTypeSelector from "../RegenerateModeTypeSelector";
import Analytics from "../../helpers/Analytics";

import styles from "./styles";

type Props = {
  regenerateModeId: number | undefined;
  isOpen: boolean;
  onClose: () => any;
};

type State = {
  name: string;
  prompt: string;
  promptCharacterCount: number;
  regenerateModeTypes: RegenerateModeType[];
  isSubmitted: boolean;
  isConfirmDialogOpen: boolean;
  actionType?: "Delete" | "Save" | "Update";
  isLoading: boolean;
};

@observer
export default class PromptDrawer extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      name: "",
      prompt: "",
      promptCharacterCount: 0,
      regenerateModeTypes: [],
      isSubmitted: false,
      isConfirmDialogOpen: false,
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.regenerateModeId &&
      nextProps.regenerateModeId !== undefined
    ) {
      this.getPromptData(nextProps.regenerateModeId);
    } else {
      this.setState({
        name: "",
        prompt: "",
        actionType: undefined,
        isConfirmDialogOpen: false,
        isLoading: false,
        regenerateModeTypes: [],
      });
    }
  }

  private getPromptData = async (regenerateModeId: number) => {
    if (regenerateModeId) {
      this.setState({ isLoading: true });

      const regenerateMode = await getRegenerateMode(regenerateModeId);

      this.setState({ isLoading: false });

      this.setState({
        name: regenerateMode.name,
        prompt: regenerateMode.prompt,
        regenerateModeTypes: regenerateMode.types_list,
      });
    }
  };
  private handleChangePrompt = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    Analytics.track("custom_prompt_entered", "");

    const prompt = event.target.value;
    const promptCharacterCount = prompt.length;
    this.setState({ prompt, promptCharacterCount });
  };

  private handleConfirmClick = async () => {
    const { onClose, regenerateModeId } = this.props;
    const { name, prompt, actionType, regenerateModeTypes } = this.state;

    if (actionType) {
      stores.documentStore.isDocumentsUpdating = true;

      onClose();

      switch (actionType) {
        case "Save":
          Analytics.track("custom_prompt_saved", "");

          await stores.regenerateStore.addNewUserRegenerateMode(
            name,
            prompt,
            regenerateModeTypes,
            []
          );
          break;
        case "Update":
          if (regenerateModeId) {
            Analytics.track("custom_prompt_updated", "");

            await stores.regenerateStore.updateUserRegenerateMode(
              regenerateModeId,
              name,
              prompt,
              regenerateModeTypes,
              []
            );
          }
          break;
        case "Delete":
          if (regenerateModeId) {
            Analytics.track("custom_prompt_deleted", "");

            await stores.regenerateStore.deleteUserRegenerateMode(
              regenerateModeId
            );
          }
          break;
      }

      this.setState({
        name: "",
        prompt: "",
        actionType: undefined,
        isConfirmDialogOpen: false,
        regenerateModeTypes: [],
      });

      stores.documentStore.isDocumentsUpdating = false;

      stores.regenerateStore.processRegenerateModes();
    }
  };

  private handleRegenerateModeTypeChange = (
    selectedItems: RegenerateModeType[]
  ) => {
    Analytics.track("custom_prompt_selection_types_selected", "");

    this.setState({ regenerateModeTypes: selectedItems });
  };

  render() {
    const { isOpen, onClose, regenerateModeId } = this.props;
    const {
      name,
      prompt,
      promptCharacterCount,
      isSubmitted,
      isConfirmDialogOpen,
      regenerateModeTypes,
      isLoading,
    } = this.state;

    return (
      <Drawer sx={styles.styleSheet.drawer} anchor={"right"} open={isOpen}>
        <Box sx={{ width: "40vw" }} role="presentation">
          {isLoading ? (
            <LinearProgress />
          ) : (
            <Box sx={styles.styleSheet.container}>
              <Box sx={styles.styleSheet.titleContainer}>
                <Typography sx={styles.styleSheet.titleText} variant="h5">
                  {name !== "" ? name : i18n.promptDrawer.newAction}
                </Typography>

                <IconButton
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Divider sx={styles.styleSheet.divider} />

              <Box sx={styles.styleSheet.mainContainer}>
                <Box style={styles.styleSheet.settingRow}>
                  {/* <Typography variant="body1" sx={{ mr: 2 }}>
                    {`${i18n.promptDrawer.selectionType}:`}
                  </Typography> */}

                  <RegenerateModeTypeSelector
                    data={Object.values(RegenerateModeType)}
                    selectedItems={regenerateModeTypes}
                    onSelect={this.handleRegenerateModeTypeChange}
                  />
                </Box>

                <TextField
                  id="outlined-basic"
                  helperText={i18n.promptDrawer.nameHelperText}
                  label={i18n.promptDrawer.actionLabel}
                  variant="outlined"
                  value={name}
                  error={name.length === 0}
                  disabled={isSubmitted}
                  onChange={(event) => {
                    Analytics.track("custom_prompt_name_entered", "");
                    this.setState({ name: event.target.value });
                  }}
                  sx={{ mb: 2 }}
                />

                <TextField
                  id="outlined-multiline-static"
                  label={i18n.promptDrawer.promptLabel}
                  multiline
                  variant="filled"
                  minRows={10}
                  maxRows={40}
                  value={prompt}
                  focused
                  required
                  onChange={this.handleChangePrompt}
                  disabled={isSubmitted}
                  inputProps={{
                    maxLength: keys.characterLimits.promptCharacterLimit,
                  }}
                  error={prompt.length === 0 || !prompt.includes("[selection]")}
                  helperText={
                    prompt.length === 0 || !prompt.includes("[selection]")
                      ? i18n.promptDrawer.promptHelperText
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={styles.styleSheet.promptCharacterCount}
                      >
                        {promptCharacterCount}/
                        {keys.characterLimits.promptCharacterLimit}
                      </Typography>
                    ),
                  }}
                />

                {isConfirmDialogOpen ? (
                  <Box sx={styles.styleSheet.actionContainer}>
                    <Box sx={styles.styleSheet.actionContainerMain}>
                      <Typography variant="subtitle1" sx={{ m: 1 }}>
                        {i18n.promptDrawer.confirm}
                      </Typography>

                      <Divider sx={{ flex: 1 }} />

                      <Button
                        sx={styles.styleSheet.actionButton}
                        color="secondary"
                        onClick={() =>
                          this.setState({ isConfirmDialogOpen: false })
                        }
                      >
                        {i18n.common.cancel}
                      </Button>

                      <Button
                        sx={styles.styleSheet.actionButton}
                        variant="contained"
                        color="primary"
                        onClick={this.handleConfirmClick}
                      >
                        {i18n.common.confirm}
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={styles.styleSheet.actionButtonContainer}>
                    {regenerateModeId ? (
                      <Button
                        color="error"
                        onClick={() => {
                          this.setState({
                            isConfirmDialogOpen: true,
                            actionType: "Delete",
                          });
                        }}
                        variant="contained"
                        sx={styles.styleSheet.actionButton}
                      >
                        {i18n.common.delete}
                      </Button>
                    ) : null}

                    <Button
                      disabled={
                        isSubmitted ||
                        name === "" ||
                        prompt === "" ||
                        regenerateModeTypes.length === 0 ||
                        !prompt.includes("[selection]")
                      }
                      color="success"
                      onClick={() => {
                        this.setState({
                          isConfirmDialogOpen: true,
                          actionType: regenerateModeId ? "Update" : "Save",
                        });
                      }}
                      variant="contained"
                      sx={styles.styleSheet.actionButton}
                    >
                      {i18n.common.save}
                    </Button>
                  </Box>
                )}

                <Typography
                  variant="subtitle2"
                  sx={styles.styleSheet.examplePromptLabel}
                >
                  {i18n.promptDrawer.examplePromptText}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    );
  }
}
