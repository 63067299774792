export enum RegenerateModeType {
  Text = "Text",
  Paragraph = "Paragraph",
  Sentence = "Sentence",
  Word = "Word",
}

export enum PromptMode {
  Standard = "Standard Mode",
  Editor = "Editor Mode",
  // WriterMode = "Writer Mode",
  // BusinessMode = "Business Mode",
  // LegalMode = "Legal Mode",
  // HRMode = "HR Mode",
}

export type RegenerateMode = {
  id: number;
  user_id: number;
  name: string;
  prompt: string;
  is_translation: boolean;
  types: string;
  prompt_modes: string;
  types_list: RegenerateModeType[];
  prompt_modes_list: PromptMode[];
};
