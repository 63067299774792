import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import i18n from "../../data/i18n";
import { RegenerateModeType } from "../../models/RegenerateMode";

import styles from "./styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  data: RegenerateModeType[];
  selectedItems: RegenerateModeType[];
  onSelect: (items: RegenerateModeType[]) => void;
}

interface State {}

export default class RegenerateModeTypeSelector extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  private handleChange = (event: any) => {
    const { onSelect } = this.props;
    const {
      target: { value },
    } = event;

    onSelect(value);
  };

  render() {
    const { data, selectedItems } = this.props;

    return (
      // @ts-ignore
      <div style={styles.styleSheet.container}>
        <div style={styles.styleSheet.inputContainer}>
          <FormControl sx={styles.styleSheet.formContainer}>
            <InputLabel
              error={selectedItems.length === 0}
              id="demo-multiple-chip-label"
            >
              {i18n.promptDrawer.selectionType}
            </InputLabel>

            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={selectedItems}
              onChange={this.handleChange}
              error={selectedItems.length === 0}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label={i18n.promptDrawer.selectionType}
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((item) => (
                    <Chip key={item} label={item} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {data.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {selectedItems.length === 0 ? (
          <FormHelperText sx={styles.styleSheet.helperText}>
            {i18n.promptDrawer.selectionTypeHelperText}
          </FormHelperText>
        ) : null}
      </div>
    );
  }
}
