import React from "react";
import { observer } from "mobx-react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Menu,
  MenuItem,
  Typography,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
// import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import SettingsDrawer from "../../components/SettingsDrawer";
import ActionBar from "../../components/Editor/ActionBar";
import Editor from "../../components/Editor";
import stores from "../../stores";
import { Language } from "../../models/Language";
import keys from "../../constants/keys";
import PromptDrawer from "../../components/PromptDrawer";
import SubscriptionModal from "../../components/SubscriptionModal";
import CancelSubscriptionModal from "../../components/CancelSubscriptionModal";
import LoginAlertModal from "../../components/LoginAlertModal";
import i18n from "../../data/i18n";
import Parser from "../../utils/parser";
import { ExportType } from "../../helpers/Enum";
import ShareModal from "../../components/ShareModal";
import Analytics from "../../helpers/Analytics";

import styles from "./styles";
import DocumentsView from "./DocumentsView";
import UserInfoView from "./UserInfoView";
import ActionsView from "./ActionsView";
import OpenAIApiKeyAlertModal from "../../components/OpenAIApiKeyAlertModal";
import DiamondIcon from '@mui/icons-material/Diamond';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RootStore from "../../stores/RootStore";
import CharacterWordCounter from "../../components/CharacterWordCounter";
import DocumentStore from "../../stores/DocumentStore";


type Props = {};

type State = {
  updatedAt: number;
  isPromptDrawerOpened: boolean;
  isCancelSubscriptionModalOpened: boolean;
  isLeftViewOpened: boolean;
  isRightViewOpened: boolean;
  selectedRegenerateModeId?: number;
  exportMenuIsVisible: boolean;
  exportMenuAnchorEl?: any;
  showUpgradedFeaturesPopUp: boolean;
  documentStore: DocumentStore;
};

@observer
export default class EditorPage extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    const rootStore = new RootStore();

    this.state = {
      updatedAt: Date.now(),
      isPromptDrawerOpened: false,
      isCancelSubscriptionModalOpened: false,
      isLeftViewOpened: true,
      isRightViewOpened: true,
      exportMenuIsVisible: false,
      showUpgradedFeaturesPopUp: false,
      documentStore: new DocumentStore(rootStore),
    };
  }

  private onExportClick = async (exportType: ExportType) => {
    if (
      !stores.userStore.currentUser.subscriptions ||
      stores.userStore.currentUser.subscriptions.length <= 0
    ) {
      stores.userStore.isPurchaseModalOpened = true;
    } else if (
      stores.documentStore.selectedDocumentItem &&
      stores.documentStore.selectedDocumentContent
    ) {
      this.setState({ exportMenuIsVisible: false });

      let html = await Parser.parseDataToHtml(
        stores.documentStore.selectedDocumentContent
      );
      let translateHtml = await Parser.parseDataToTranslationHtml(
        stores.documentStore.selectedDocumentContent
      );
      let originalHtml = await Parser.parseDataToOriginalHtml(
        stores.documentStore.selectedDocumentContent
      );

      if (exportType === ExportType.Word) {
        Parser.downloadDocxFile(
          html.join(""),
          stores.documentStore.selectedDocumentItem.name
        );
      } else if (exportType === ExportType.TranslationWord) {
        Parser.downloadDocxFile(
          translateHtml.join(""),
          stores.documentStore.selectedDocumentItem.name
        );
      } else if (exportType === ExportType.OrginalWord) {
        Parser.downloadDocxFile(
          originalHtml.join(""),
          stores.documentStore.selectedDocumentItem.name
        );
      } else if (exportType === ExportType.Pdf) {
        Parser.downloadPdfFile(
          html.join(""),
          stores.documentStore.selectedDocumentItem.name
        );
      } else if (exportType === ExportType.HTML) {
        Parser.downloadHtmlFile(
          html.join(""),
          stores.documentStore.selectedDocumentItem.name
        );
      } else if (exportType === ExportType.TranslationHTML) {
        Parser.downloadHtmlFile(
          translateHtml.join(""),
          stores.documentStore.selectedDocumentItem.name
        );
      } else if (exportType === ExportType.OrginalHTML) {
        Parser.downloadHtmlFile(
          originalHtml.join(""),
          stores.documentStore.selectedDocumentItem.name
        );
      }
    }
  };

  private onEditRegenerateModeClick = (regenerateModeId: number) => {
    this.setState({
      selectedRegenerateModeId: regenerateModeId,
      isPromptDrawerOpened: true,
    });
  };

  private onNewActionPress = () => {
    Analytics.track("create_custom_prompt_clicked", "");

    if (stores.userStore.currentUser.isAnonymous === true) {
      stores.userStore.isLoginAlertModalOpened = true;
    } else if (
      stores.regenerateStore.userRegenerateModes.length >=
      keys.proFeatureLimits.freeRegenerateModeCount &&
      (!stores.userStore.currentUser.subscriptions ||
        stores.userStore.currentUser.subscriptions.length <= 0)
    ) {
      stores.userStore.isPurchaseModalOpened = true;
    } else {
      this.setState({ selectedRegenerateModeId: undefined }, () => {
        this.setState({
          isPromptDrawerOpened: true,
        });
      });
    }
  };

  private handleExportClick = (event: React.MouseEvent<HTMLElement>) => {
    Analytics.track("export_button_clicked", "");

    this.setState({
      exportMenuAnchorEl: event.currentTarget,
      exportMenuIsVisible: true,
    });
  };

  private handleClearAllClick = (event: React.MouseEvent<HTMLElement>) => {
    Analytics.track("clear_all_button_clicked", "");

    stores.documentStore.editor.clear();
  };
  toggleSubscriptionModal = () => {
    stores.userStore.isPurchaseModalOpened = !stores.userStore.isPurchaseModalOpened;
    this.forceUpdate(); // Since you're using mobx for state management outside React's setState, force an update
  };
  toggleUpgradedFeaturesPopUp = () => {
    this.setState(prevState => ({
      showUpgradedFeaturesPopUp: !prevState.showUpgradedFeaturesPopUp
    }));
  };

  render() {
    const {
      isPromptDrawerOpened,
      isCancelSubscriptionModalOpened,
      isLeftViewOpened,
      isRightViewOpened,
      selectedRegenerateModeId,
      exportMenuIsVisible,
      exportMenuAnchorEl,
      documentStore,
    } = this.state;

    let documentLanguage: Language | undefined = undefined;
    let translationLanguage: Language | undefined = undefined;

    if (stores.documentStore.selectedDocumentItem) {
      documentLanguage = keys.languages.find(
        (item) =>
          item.code ===
          //@ts-ignore
          stores.documentStore.selectedDocumentItem.document_language
      );
      translationLanguage = keys.languages.find(
        (item) =>
          item.code ===
          //@ts-ignore
          stores.documentStore.selectedDocumentItem.translation_language
      );
    }

    return (
      <Box sx={styles.styleSheet.container}>
        {isLeftViewOpened ? (
          <Box sx={styles.styleSheet.leftContainer}>
            <DocumentsView />
            {/* Upgraded Information */}
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2, marginLeft: 3, marginRight: 3 }}>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }}> {/* Adjust this line */}
                  {!stores.userStore.currentUser.isAnonymous && (<> {stores.userStore.currentUser.subscriptions && stores.userStore.currentUser.subscriptions.length > 0 ? (
                  <Chip
                    icon={<DiamondIcon fontSize='small' />}
                    label="Upgraded"
                    color="success"
                    sx={{ width: '100%' }} // Make Chip stretch
                    onClick={this.toggleUpgradedFeaturesPopUp}
                  />
                ) : (
                  <Chip
                    icon={<ArrowCircleUpIcon />}
                    label="Free Plan"
                    color="warning"
                    sx={{ width: '100%' }} // Make Chip stretch
                    onClick={this.toggleSubscriptionModal}
                  />
                      )}</>
                )}
              </Box>
              {this.state.showUpgradedFeaturesPopUp && (
                <Dialog
                  open={this.state.showUpgradedFeaturesPopUp}
                  onClose={this.toggleUpgradedFeaturesPopUp}
                  maxWidth="sm"
                  fullWidth
                  sx={{
                    '& .MuiDialog-paper': {
                      borderRadius: 2, // Rounded corners for the dialog
                      padding: '16px', // Padding around the dialog content
                    }
                  }}
                >
                  <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', color: 'primary.main' }}>
                    Your Upgraded Features
                  </DialogTitle>
                  <DialogContent  sx={{ paddingTop: '8px', paddingBottom: '16px' }}>
                    <List sx={{ margin: 0 }}>
                      {[
                        "Full access to all translation languages",
                        "Unlimited block-by-block translations",
                        "Comprehensive grammar and spelling checks",
                        "Custom sentence editing tools",
                        "Priority customer support",
                        "Cancel anytime",
                      ].map((feature, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            padding: '8px 0',
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                            '&:last-child': {
                              border: 0,
                            },
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: '40px', color: 'success.main' }}>
                            <CheckCircleOutlineIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                                {feature}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.toggleUpgradedFeaturesPopUp} variant="contained" color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Box>

            <UserInfoView
              onCancelSubscriptionPress={() => {
                Analytics.track("cancel_subscription_clicked", "");

                this.setState({ isCancelSubscriptionModalOpened: true });
              }}
              onSettingPress={() => {
                Analytics.track("settings_button_clicked", "");

                stores.userStore.isSettingsDrawerOpened = true;

                Analytics.track("settings_drawer_viewed", "");
              }}
              onUpgradePress={() => {
                Analytics.track("upgrade_button_clicked", "");

                stores.userStore.isPurchaseModalOpened = true;
              }}
            />
          </Box>
        ) : null}

        {stores.documentStore.selectedDocumentItem !== undefined ? (
          <Box sx={styles.styleSheet.mainContainer}>
            <Box sx={styles.styleSheet.centerContainer}>
              <ActionBar
                docLangCountryCode={
                  documentLanguage ? documentLanguage.country : undefined
                }
                docTransLangCountryCode={
                  translationLanguage ? translationLanguage.country : undefined
                }
                isRightViewOpened={isRightViewOpened}
                onToggleRightView={() => {
                  Analytics.track("toggle_right_view_clicked", "");

                  this.setState({ isRightViewOpened: !isRightViewOpened });
                }}
                isLeftViewOpened={isLeftViewOpened}
                onToggleLeftView={() => {
                  Analytics.track("toggle_left_view_clicked", "");

                  this.setState({ isLeftViewOpened: !isLeftViewOpened });
                }}
              />

              <Box
                sx={styles.styleSheet.editorContainer}
                key={`tabContent${stores.documentStore.selectedDocumentItem.id}`}
              >
                <Editor
                  documentItem={stores.documentStore.selectedDocumentItem}
                />
              </Box>

              <Box sx={styles.styleSheet.bottomContainer}>
                <Box sx={styles.styleSheet.progressContainer}>
                  {stores.documentStore.isDocumentsUpdating ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : null}
                </Box>

                <Box sx={styles.styleSheet.bottomButtonContainer}>
                  <Button
                    disabled={
                      stores.documentStore.selectedDocumentContent &&
                        stores.documentStore.selectedDocumentContent.blocks
                          .length > 0
                        ? false
                        : true
                    }
                    sx={styles.styleSheet.bottomButton}
                    onClick={this.handleExportClick}
                  >
                    <IosShareIcon sx={styles.styleSheet.bottomButtonIcon} />

                    <Typography
                      variant="body2"
                      sx={styles.styleSheet.bottomButtonText}
                    >
                      {i18n.common.export}
                    </Typography>
                  </Button>
                </Box>

                <Box sx={styles.styleSheet.bottomButtonContainer}>
                  <Button
                    disabled={
                      stores.documentStore.selectedDocumentContent &&
                        stores.documentStore.selectedDocumentContent.blocks
                          .length > 0
                        ? false
                        : true
                    }
                    sx={styles.styleSheet.bottomButton}
                    onClick={this.handleClearAllClick}
                  >
                    <DeleteSweepIcon sx={styles.styleSheet.bottomButtonIcon} />

                    <Typography
                      variant="body2"
                      sx={styles.styleSheet.bottomButtonText}
                    >
                      {i18n.common.clearAll}
                    </Typography>
                  </Button>
                  <CharacterWordCounter />
                </Box>
              </Box>
            </Box>

            {isRightViewOpened ? (
              <ActionsView
                onNewActionPress={this.onNewActionPress}
                onEditRegenerateModeClick={this.onEditRegenerateModeClick}
              />
            ) : null}
          </Box>
        ) : null}

        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          sx={styles.styleSheet.exportMenu}
          anchorEl={exportMenuAnchorEl}
          open={exportMenuIsVisible}
          onClose={() => this.setState({ exportMenuIsVisible: false })}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={() => this.onExportClick(ExportType.Word)}>
            <img
              style={styles.styleSheet.exportMenuIcon}
              alt=""
              src={require(`../../assets/${stores.ui.theme}/docxIcon.png`)}
            />
            All
          </MenuItem>
          <MenuItem
            onClick={() => this.onExportClick(ExportType.TranslationWord)}
          >
            <img
              style={styles.styleSheet.exportMenuIcon}
              alt=""
              src={require(`../../assets/${stores.ui.theme}/docxIcon.png`)}
            />
            Translation Only
          </MenuItem>
          <MenuItem onClick={() => this.onExportClick(ExportType.OrginalWord)}>
            <img
              style={styles.styleSheet.exportMenuIcon}
              alt=""
              src={require(`../../assets/${stores.ui.theme}/docxIcon.png`)}
            />
            Orginal Only
          </MenuItem>

          <Divider />

          {/* <MenuItem onClick={() => this.onExportClick(ExportType.Pdf)}>
            <PictureAsPdfIcon sx={styles.styleSheet.exportMenuIcon} /> Pdf
          </MenuItem> */}

          {/* <Divider /> */}

          <MenuItem onClick={() => this.onExportClick(ExportType.HTML)}>
            <img
              style={styles.styleSheet.exportMenuIcon}
              alt=""
              src={require(`../../assets/${stores.ui.theme}/htmlIcon.png`)}
            />
            All
          </MenuItem>
          <MenuItem
            onClick={() => this.onExportClick(ExportType.TranslationHTML)}
          >
            <img
              style={styles.styleSheet.exportMenuIcon}
              alt=""
              src={require(`../../assets/${stores.ui.theme}/htmlIcon.png`)}
            />
            Translation Only
          </MenuItem>
          <MenuItem onClick={() => this.onExportClick(ExportType.OrginalHTML)}>
            <img
              style={styles.styleSheet.exportMenuIcon}
              alt=""
              src={require(`../../assets/${stores.ui.theme}/htmlIcon.png`)}
            />
            Orginal Only
          </MenuItem>
        </Menu>

        <SettingsDrawer
          isOpen={stores.userStore.isSettingsDrawerOpened}
          onClose={() => (stores.userStore.isSettingsDrawerOpened = false)}
        />

        <PromptDrawer
          regenerateModeId={selectedRegenerateModeId}
          isOpen={isPromptDrawerOpened}
          onClose={() => this.setState({ isPromptDrawerOpened: false })}
        />

        {stores.documentStore.selectedDocumentItem ? (
          <ShareModal
            open={stores.userStore.isShareModalOpened}
            onClose={() => (stores.userStore.isShareModalOpened = false)}
            documentItem={stores.documentStore.selectedDocumentItem}
          />
        ) : null}

        <SubscriptionModal
          onClose={() => (stores.userStore.isPurchaseModalOpened = false)}
          open={stores.userStore.isPurchaseModalOpened}
        />

        <CancelSubscriptionModal
          onClose={() =>
            this.setState({ isCancelSubscriptionModalOpened: false })
          }
          open={isCancelSubscriptionModalOpened}
        />

        <LoginAlertModal
          onClose={() => (stores.userStore.isLoginAlertModalOpened = false)}
          open={stores.userStore.isLoginAlertModalOpened}
        />

        <OpenAIApiKeyAlertModal
          onClose={() =>
            (stores.userStore.isOpenAIApiKeyAlertModalOpened = false)
          }
          open={stores.userStore.isOpenAIApiKeyAlertModalOpened}
        />
      </Box>
    );
  }
}
