/* @flow */

import { computed } from "mobx";

import stores from "../../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        display: "flex",
        ml: 5,
        mr: 5,
        mb: 2,
        p: 0,
        minWidth: 800,
      },
      tabContainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      },
      tabs: {
        "& button": {
          color: stores.ui.themeColors.textPrimary,
          borderRadius: 1,
          backgroundColor: stores.ui.themeColors.background,
        },
        "& button:hover": {
          backgroundColor: stores.ui.themeColors.secondaryBackground,
          color: stores.ui.themeColors.textPrimary,
        },
        "& button.Mui-selected": {
          backgroundColor: stores.ui.themeColors.selectedBackground,
          color: stores.ui.themeColors.textPrimary,
        },
        ml: 5,
      },
      tab: {
        minWidth: 40,
        maxWidth: 50,
        mr: 1,
      },
      showHideLeftView: {
        minWidth: 40,
        maxWidth: 50,
        height: 40,
        alignItems: "flex-start",
        mr: 2,
      },
      showHideRightView: {
        minWidth: 40,
        maxWidth: 50,
        height: 40,
        alignItems: "flex-start",
        ml: 1,
      },
      translateAllButton: {
        textTransform: "none",
        color: stores.ui.themeColors.textPrimary,
        bgcolor: stores.ui.themeColors.selectedBackground,
        borderRadius: 1,
        height: 50,
        pl: 2,
        pr: 2,
      },
      removeTranslationButton: {
        display: "flex",
        color: stores.ui.themeColors.textPrimary,
        bgcolor: stores.ui.themeColors.selectedBackground,
        minWidth: 40,
        maxWidth: 50,
        height: 50,
      },
      icon: {
        width: 25,
        height: 25,
      },
      languageSelectorItemContainer: {
        display: "flex",
        width: 160,
        mr: 1,
      },
    };

    return styles;
  }
}
