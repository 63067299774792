// @flow
import UserStore from "./UserStore";
import DocumentStore from "./DocumentStore";
import RegenerateStore from "./RegenerateStore";
import ActionStore from "./ActionStore";
import UiStore from "./UIStore";
import SubscriptionStore from "./SubscriptionStore";

export default class RootStore {
  userStore: UserStore;
  subscriptionStore: SubscriptionStore;
  documentStore: DocumentStore;
  regenerateStore: RegenerateStore;
  actionStore: ActionStore;
  ui: UiStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.subscriptionStore = new SubscriptionStore(this);
    this.documentStore = new DocumentStore(this);
    this.regenerateStore = new RegenerateStore(this);
    this.actionStore = new ActionStore(this);
    this.ui = new UiStore(this);
  }

  logout() {}
}
