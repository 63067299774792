import React from "react";
import {
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  Button,
  Box,
  ThemeProvider,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HistoryIcon from "@mui/icons-material/History";
import { NestedMenuItem } from "mui-nested-menu";
import SubjectIcon from "@mui/icons-material/Subject";

import { TranslationItem } from "../../models/TranslationItem";
import Functions from "../../helpers/Functions";
import i18n from "../../data/i18n";
import { theme } from "../../app";

import styles from "./styles";

type Props = {
  isVisible: boolean;
  translationItem?: TranslationItem;
  translationItems?: Array<TranslationItem>;
  anchorEl: any;
  onClose: () => any;
  onCopyPress: () => any;
  onTranslationItemPress?: (translationItem: TranslationItem) => any;
  onActionsPress: () => any;
};

type State = {};

export default class ActionMenu extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  private renderTranslationItem = (
    item: TranslationItem,
    translationItem?: TranslationItem
  ) => {
    const { onTranslationItemPress } = this.props;

    return (
      <MenuItem
        sx={styles.styleSheet.translationHistoryMenuItem}
        key={`translationItem-${item.id}`}
        divider={true}
      >
        <Box sx={styles.styleSheet.translationHistoryContainer}>
          <Box sx={{ flex: 1, mr: 2 }}>
            {translationItem &&
            translationItem.original_key_text !== item.original_key_text ? (
              <Typography
                variant="body2"
                sx={styles.styleSheet.translationHistoryText}
              >
                {`${i18n.actionMenu.oldOriginalText}:\n${item.original_text}`}
              </Typography>
            ) : null}

            <Typography
              dangerouslySetInnerHTML={{ __html: item.translation_text }}
              variant="body2"
            />
          </Box>

          <Box sx={{ m: 1 }}>
            <Button
              variant="contained"
              sx={{ maxHeight: 40 }}
              onClick={() =>
                onTranslationItemPress ? onTranslationItemPress(item) : {}
              }
            >
              {i18n.common.revert}
            </Button>

            <Typography
              variant="body2"
              sx={styles.styleSheet.translationHistoryPassTimeInfoText}
            >
              {Functions.getPassTimeInfo(item.updated_at)}
            </Typography>
          </Box>
        </Box>
      </MenuItem>
    );
  };

  render() {
    const {
      isVisible,
      anchorEl,
      onClose,
      onCopyPress,
      onActionsPress,
      translationItem,
      translationItems,
    } = this.props;

    const filteredTranslationItems = translationItems
      ? translationItems.filter((item) =>
          translationItem
            ? translationItem.translation_text !== item.translation_text
            : true
        )
      : [];

    return (
      <ThemeProvider theme={theme}>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={isVisible}
          onClose={onClose}
          onContextMenu={(event) => event.preventDefault()}
          PaperProps={{
            elevation: 0,
            sx: styles.styleSheet.menu,
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <MenuItem onClick={onCopyPress}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>

            <Typography variant="body2" sx={styles.styleSheet.menuItem}>
              {i18n.common.copy}
            </Typography>
          </MenuItem>

          <MenuItem disabled={!translationItem} onClick={onActionsPress}>
            <ListItemIcon>
              <SubjectIcon fontSize="small" />
            </ListItemIcon>

            <Typography variant="body2" sx={styles.styleSheet.menuItem}>
              {i18n.common.actions}
            </Typography>
          </MenuItem>

          <NestedMenuItem
            // @ts-ignore
            label={
              <Typography variant="body2" sx={styles.styleSheet.nestedMenuItem}>
                {i18n.actionMenu.translationHistory}
              </Typography>
            }
            MenuProps={{
              PaperProps: { style: { pointerEvents: "auto" } },
            }}
            leftIcon={<HistoryIcon fontSize="small" />}
            sx={{ ml: 1.5, fontSize: 12 }}
            parentMenuOpen={isVisible}
            disabled={filteredTranslationItems.length === 0}
          >
            {filteredTranslationItems
              .reverse()
              .map((item) => this.renderTranslationItem(item, translationItem))}
          </NestedMenuItem>
        </Menu>
      </ThemeProvider>
    );
  }
}
