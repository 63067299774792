import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import { observer } from "mobx-react";

import i18n from "../../../data/i18n";
import Analytics from "../../../helpers/Analytics";

export const LogoutButton: React.FC = observer(() => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    Analytics.track("logout_button_clicked", "");

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Button
      sx={{ mr: 2, textTransform: "none" }}
      variant="outlined"
      onClick={handleLogout}
    >
      {i18n.common.logout}
    </Button>
  );
});
