export enum ViewMode {
  OnlyOriginal = "OnlyOriginal",
  OnlyTranslation = "OnlyTranslation",
  SideView = "Side",
  BottomView = "Bottom",
}

export enum DialogType {
  Add = "Add",
  Update = "Update",
  Delete = "Delete",
  Confirm = "Confirm",
  Save = "Save",
}

export enum TuneType {
  Translated = "translated",
}

export enum ExportType {
  OrginalWord = "OrginalWord",
  TranslationWord = "TranslationWord",
  Word = "Word",
  Pdf = "Pdf",
  HTML = "HTML",
  OrginalHTML = "OrginalHTML",
  TranslationHTML = "TranslationHTML",
}

export enum BlockType {
  BlockItem = "OriginalText",
  TranslationBlockItem = "TranslationText",
}

export enum StringType {
  None = "",
  Paragraph = "Paragraph",
  Sentence = "Sentence",
  Text = "Text",
  Word = "Word",
}

export enum SortType {
  AZ = "A-Z",
  ZA = "Z-A",
}
