/* @flow */
import { OutputData } from "@editorjs/editorjs";
import { saveAs } from "file-saver";
import { asBlob } from "html-docx-js-typescript";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { ParseFunctionError } from "./errors";
import transforms, { block } from "./transforms";
import TranslationTransforms from "./TranslationTransforms";
import OriginalTransforms from "./OriginalTransforms";

export default class Parser {
  static parsers = Object.assign({}, transforms, {});
  static translationTransforms = Object.assign({}, TranslationTransforms, {});
  static originalParsers = Object.assign({}, OriginalTransforms, {});

  static parseDataToHtml(data: OutputData): Promise<string[]> {
    return Promise.all(
      data.blocks.map((block) => {
        if (this.parsers[block.type]) {
          return this.parsers[block.type](block);
        } else {
          return Promise.reject(ParseFunctionError(block.type));
        }
      })
    );
  }
  static parseDataToTranslationHtml(data: OutputData): Promise<string[]> {
    return Promise.all(
      data.blocks.map((block) => {
        if (this.translationTransforms[block.type]) {
          return this.translationTransforms[block.type](block);
        } else {
          return Promise.reject(ParseFunctionError(block.type));
        }
      })
    );
  }
  static parseDataToOriginalHtml(data: OutputData): Promise<string[]> {
    return Promise.all(
      data.blocks.map((block) => {
        if (this.originalParsers[block.type]) {
          return this.originalParsers[block.type](block);
        } else {
          return Promise.reject(ParseFunctionError(block.type));
        }
      })
    );
  }

  static parseBlockToHtml(block: block) {
    return this.parsers[block.type]
      ? this.parsers[block.type](block)
      : ParseFunctionError(block.type);
  }

  static downloadHtmlFile(html: string, fileName: string) {
    const blob = new Blob([html], { type: "text/html" });

    saveAs(blob, `${fileName}.html`);
  }

  static async downloadDocxFile(html: string, fileName: string) {
    asBlob(html).then((data) => {
      //@ts-ignore
      saveAs(data, `${fileName}.docx`);
    });
  }

  static async downloadPdfFile(html: string, fileName: string) {
    const editorRef = document.querySelector("#editor") as HTMLElement;

    editorRef.blur();

    html2canvas(editorRef, {
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: canvas.width > canvas.height ? "l" : "p",
        unit: "pt",
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
      pdf.save(`${fileName}.pdf`);
    });
  }
}
