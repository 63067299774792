import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Box } from "@mui/material";
import { observer } from "mobx-react";

import stores from "../../../stores";
import { Product } from "../../../models/Product";
import { SubscriptionCoupon } from "../../../models/SubscriptionCoupon";

import { CheckoutForm } from "./CheckoutForm";
import styles from "./styles";

type Props = {
  selectedProduct: Product;
  selectedSubscriptionCoupon?: SubscriptionCoupon;
};

type State = {
  stripe?: any;
};

@observer
export default class StripeView extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        : ""
    ).then((stripe) => {
      if (stripe) this.setState({ stripe });
    });
  }

  render() {
    const { selectedProduct, selectedSubscriptionCoupon } = this.props;
    const { stripe } = this.state;

    const appearance = {
      theme: stores.ui.theme === "dark" ? "night" : "flat",
    };
    const options = {
      appearance,
    };

    return (
      <Box sx={styles.styleSheet.mainContainer}>
        {stripe && (
          <Elements options={options} stripe={stripe}>
            <CheckoutForm
              selectedProduct={selectedProduct}
              selectedSubscriptionCoupon={selectedSubscriptionCoupon}
            />
          </Elements>
        )}
      </Box>
    );
  }
}
