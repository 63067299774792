import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import * as React from "react";
import { observer } from "mobx-react";
import CloseIcon from "@mui/icons-material/Close";

import i18n from "../../data/i18n";
import { getUser, searchUsers } from "../../helpers/api";
import { User } from "../../models/User";
import getSharesOfDocuments from "../../helpers/api/getSharesOfDocuments";
import { DocumentItem } from "../../models/DocumentItem";
import { SharedDocumentItem } from "../../models/SharedDocumentItem";
import deleteSharedDocument from "../../helpers/api/deleteSharedDocument";
import createSharedDocument from "../../helpers/api/createSharedDocument";
import stores from "../../stores";
import Functions from "../../helpers/Functions";

import styles from "./styles";

type Props = {
  open: boolean;
  documentItem: DocumentItem;
  onClose: () => void;
};

type State = {
  documentItem: DocumentItem;
  searchQuery: string;
  users: User[];
  sharedUsers: User[];
  sharesOfDocuments: SharedDocumentItem[];
};

@observer
export default class ShareModal extends React.Component<Props, State> {
  private inputChangeTimer: any;

  constructor(props: any) {
    super(props);

    this.state = {
      documentItem: this.props.documentItem,
      searchQuery: "",
      users: [],
      sharedUsers: [],
      sharesOfDocuments: [],
    };

    this.getDocumentData();
  }

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.documentItem &&
      nextProps.documentItem.id !== this.state.documentItem.id
    ) {
      this.setState(
        { searchQuery: "", users: [], sharedUsers: [], sharesOfDocuments: [] },
        () => this.getDocumentData()
      );
    }
  }

  private getDocumentData = async () => {
    const { documentItem } = this.state;

    const sharedUsers: User[] = [];

    const sharesOfDocuments = await getSharesOfDocuments(documentItem.id);

    sharesOfDocuments.forEach(async (item) => {
      const sharedUser = await getUser(item.shared_user_id);

      if (sharedUser) sharedUsers.push(sharedUser);
    });

    this.setState({ sharedUsers, sharesOfDocuments });
  };

  private handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;

    this.setState({ searchQuery });

    clearTimeout(this.inputChangeTimer);

    this.inputChangeTimer = setTimeout(async () => {
      const users = await searchUsers(searchQuery);

      this.setState({ users });
    }, 300);
  };

  private handleSearchClearButtonClick = () => {
    this.setState({ searchQuery: "" });
  };

  private handleListItemClick = (item: User) => {};

  private handleListItemRemoveClick = async (item: User) => {
    const { sharedUsers, sharesOfDocuments } = this.state;

    const sharedDocumentIndex = sharesOfDocuments.findIndex(
      (sharedDocument) =>
        sharedDocument.shared_user_id.toString() === item.id.toString()
    );

    if (sharedDocumentIndex !== -1) {
      await deleteSharedDocument(sharesOfDocuments[sharedDocumentIndex].id);

      let updatedSharesOfDocuments = sharesOfDocuments.filter(
        (sharedDocument) =>
          sharedDocument.id.toString() !==
          sharesOfDocuments[sharedDocumentIndex].id.toString()
      );

      let updatedSharedUsers = sharedUsers;
      updatedSharedUsers = sharedUsers.filter((user) => user.id !== item.id);

      this.setState({
        sharedUsers: updatedSharedUsers,
        sharesOfDocuments: updatedSharesOfDocuments,
      });
    }
  };

  private handleListItemShareClick = async (item: User) => {
    const { sharedUsers, documentItem, sharesOfDocuments } = this.state;

    const sharedDocumentItem = await createSharedDocument(
      stores.userStore.currentUser.id,
      item.id,
      documentItem.id,
      "555"
    );

    if (sharedDocumentItem) {
      let updatedSharedUsers = sharedUsers;
      sharedUsers.push(item);

      let updatedSharesOfDocuments = sharesOfDocuments;
      updatedSharesOfDocuments.push(sharedDocumentItem);

      this.setState({
        sharedUsers: updatedSharedUsers,
        sharesOfDocuments: updatedSharesOfDocuments,
      });
    }
  };

  render() {
    const { open, onClose } = this.props;
    const { searchQuery, users, sharedUsers, documentItem, sharesOfDocuments } =
      this.state;

    const nonSharedUsers = users.filter(
      (user) =>
        sharesOfDocuments.findIndex(
          (sharedDocument) =>
            sharedDocument.shared_user_id.toString() === user.id.toString()
        ) === -1 &&
        user.id.toString() !== stores.userStore.currentUser.id.toString()
    );

    if (!document) return null;

    return (
      <Dialog onClose={onClose} open={open}>
        <Box sx={styles.styleSheet.topContainer}>
          <DialogTitle>
            {i18n.formatString(i18n.shareModal.title, documentItem.name)}
          </DialogTitle>

          <Paper sx={styles.styleSheet.searchBar}>
            <InputBase
              autoFocus
              value={searchQuery}
              sx={{ ml: 1, flex: 1, fontSize: 14 }}
              placeholder={i18n.shareModal.addEmail}
              inputProps={{ "aria-label": i18n.shareModal.addEmail }}
              onChange={this.handleSearchChange}
            />

            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="directions"
              onClick={this.handleSearchClearButtonClick}
            >
              <CloseIcon />
            </IconButton>
          </Paper>
        </Box>

        <Box sx={styles.styleSheet.container}>
          <Box sx={styles.styleSheet.listContainer}>
            <Typography sx={styles.styleSheet.itemLabel} variant="h6">
              {i18n.shareModal.emailWithAccess}
            </Typography>

            {sharedUsers.length === 0 ? (
              <Typography sx={styles.styleSheet.infoLabel} variant="body2">
                {i18n.shareModal.noUserFound}
              </Typography>
            ) : (
              <List
                sx={styles.styleSheet.usersListContainer}
                component="nav"
                aria-label="shortcut list"
              >
                {sharedUsers.map((item) => (
                  <ListItem
                    key={item.id}
                    onClick={() => this.handleListItemClick(item)}
                    sx={styles.styleSheet.itemContainer}
                  >
                    <Typography
                      sx={styles.styleSheet.itemLabel}
                      variant="body2"
                    >
                      {item.email}
                    </Typography>

                    <Button
                      color="info"
                      onClick={() => this.handleListItemRemoveClick(item)}
                      variant="outlined"
                      sx={styles.styleSheet.clearButton}
                    >
                      {i18n.common.remove}
                    </Button>
                  </ListItem>
                ))}
              </List>
            )}

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Typography sx={styles.styleSheet.itemLabel} variant="h6">
              {i18n.shareModal.listedUsers}
            </Typography>

            {users.length === 0 ? (
              <Typography sx={styles.styleSheet.infoLabel} variant="body2">
                {i18n.shareModal.noUserFound}
              </Typography>
            ) : (
              <List
                sx={styles.styleSheet.usersListContainer}
                component="nav"
                aria-label="shortcut list"
              >
                {nonSharedUsers.map((item) => (
                  <ListItem
                    key={item.id}
                    onClick={() => this.handleListItemClick(item)}
                    sx={styles.styleSheet.itemContainer}
                  >
                    <Typography
                      sx={styles.styleSheet.itemLabel}
                      variant="body2"
                    >
                      {item.email &&
                      item.email.length > 3 &&
                      searchQuery.length > 3 &&
                      item.email.substring(0, 3) === searchQuery.substring(0, 3)
                        ? item.email
                        : Functions.hideEmail(item.email, 0.5)}
                    </Typography>

                    <Button
                      color="success"
                      onClick={() => this.handleListItemShareClick(item)}
                      variant="contained"
                      sx={styles.styleSheet.saveButton}
                    >
                      {i18n.common.share}
                    </Button>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>
      </Dialog>
    );
  }
}
