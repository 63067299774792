// @flow

import { gql } from "apollo-boost";
import { get } from "lodash";
import { OutputData } from "@editorjs/editorjs";

import ApolloClientHelper from "../ApolloClientHelper";
import { DocumentHistoryItem } from "../../models/DocumentHistoryItem";

export default async function createDocumentHistory(
  documentId: number,
  editorData: OutputData,
  updatedAt: number
): Promise<DocumentHistoryItem> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createDocumentHistory(
        doc_id: ${documentId}
        editor_data: "${encodeURIComponent(JSON.stringify(editorData))}"
        updated_at: ${updatedAt}
      ) {
        id
        doc_id
        editor_data
        updated_at
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createDocumentHistoryMutationResult: any) => {
        const documentHistoryItem: DocumentHistoryItem = get(
          createDocumentHistoryMutationResult,
          "data.createDocumentHistory",
          null
        );

        if (documentHistoryItem) {
          resolve(documentHistoryItem);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
