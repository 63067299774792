import * as React from "react";
import { observer } from "mobx-react";
import {
  Box,
  IconButton,
  List,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";

import i18n from "../../../data/i18n";
import stores from "../../../stores";
import { DocumentItem } from "../../../models/DocumentItem";
import { SharedDocumentItem } from "../../../models/SharedDocumentItem";
import keys from "../../../constants/keys";
import Analytics from "../../../helpers/Analytics";

import styles from "./styles";
import DocumentRowItem from "./DocumentRowItem";
import SharedDocumentRowItem from "./SharedDocumentRowItem";
import { Keyboard } from "@mui/icons-material";

type Props = {};

type State = {
  isPromptDialogOpen: boolean;
  isSubmitting: boolean;
  promptValue: string;
  isDocumentTypeDialogOpen: boolean; // Add state for document type selection dialog
  selectedDocumentType: string; // Add state for selected document type
};

@observer
export default class DocumentsView extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isPromptDialogOpen: false,
      isSubmitting: false,
      promptValue: "",
      isDocumentTypeDialogOpen: false,
      selectedDocumentType: "",
    };
  }

  private handleNewDocumentClick = () => {
    Analytics.track("add_new_document_button_clicked", "");

    if (
      stores.documentStore.documents.length >=
        keys.proFeatureLimits.freeDocumentCount &&
      (!stores.userStore.currentUser.subscriptions ||
        stores.userStore.currentUser.subscriptions.length <= 0)
    ) {
      stores.userStore.isPurchaseModalOpened = true;
    } else {
      this.setState({ isDocumentTypeDialogOpen: true, promptValue: "" });
    }
  };

  private handleDocumentTypeSelection = (type: string) => {
    this.setState({
      selectedDocumentType: type,
      isDocumentTypeDialogOpen: false,
      isPromptDialogOpen: true,
    });
  };

  private handlePromptDialogClose = () => {
    this.setState({ isPromptDialogOpen: false });
  };
  private handlePromptDialogEmptySubmit = async () => {
    const { selectedDocumentType } = this.state; // Get the selected document type

    await stores.documentStore.addNewDocument(selectedDocumentType);
    this.setState({ isPromptDialogOpen: false });
  };

  private handlePromptDialogPromptedSubmit = async () => {
    const userPrompt = this.state.promptValue.trim(); // Trim whitespace from input
    const { selectedDocumentType } = this.state;

    if (!userPrompt) {
      // If prompt value is empty, call handlePromptDialogEmptySubmit
      this.handlePromptDialogEmptySubmit();
      return;
    }

    // Proceed with the current logic
    this.setState({ isSubmitting: true });
    try {
      const chatResult = await stores.documentStore.getPromptResult(userPrompt);
      const titleResult = await stores.documentStore.getHeaderPromptResult(
        chatResult
      );

      // Create a new document with the selected document type
      const doc = await stores.documentStore.addNewDocumentWithHeader(
        titleResult,
        selectedDocumentType
      );
      const doc_id = doc.id;
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.setState({
        isPromptDialogOpen: false,
        isSubmitting: false,
      });
      this.handlePromptResult(doc_id, chatResult);
    } catch (error) {
      // Handle error
      this.setState({ isSubmitting: false });
    }
  };

  private handlePromptResult = async (doc_id: Number, chatResult: string) => {
    const editorElement = document.querySelector(
      `#editorjs-document-${doc_id} > div > div.codex-editor__redactor > div > div > div:nth-child(1) > div:nth-child(1) > div`
    );
    if (editorElement) {
      editorElement.textContent = chatResult;
      await stores.documentStore.setDocumentHistoryItem(
        stores.documentStore.selectedDocumentItem!,
        undefined,
        {
          blocks: [
            {
              type: "paragraph",
              data: {
                text: chatResult,
              },
            },
          ],
        }
      );
    } else {
      // console.error("Editor element not found");
    }
  };

  private handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    documentItem: DocumentItem
  ) => {
    Analytics.track("document_selected", "");

    if (
      (stores.documentStore.selectedDocumentItem &&
        stores.documentStore.selectedDocumentItem.id !== documentItem.id) ||
      stores.documentStore.selectedDocumentItem === undefined
    ) {
      stores.actionStore.setSelectionProps(undefined, undefined, undefined);
      stores.documentStore.selectedDocumentItem = documentItem;
    }
  };

  private handleEditApplyClick = async (
    documentNameTextInputValue: string,
    documentItem: DocumentItem
  ) => {
    Analytics.track("document_updated", "");

    await stores.documentStore.updateDocumentName(
      documentItem,
      documentNameTextInputValue
    );
  };

  private handleDeleteApplyClick = async (documentItem: DocumentItem) => {
    Analytics.track("document_deleted", "");

    stores.documentStore.deleteDocument(documentItem);
  };

  private renderDocumentRowItem = (documentItem: DocumentItem) => {
    return (
      <DocumentRowItem
        key={`documentRowItem${documentItem.id}`}
        selected={
          stores.documentStore.selectedDocumentItem &&
          stores.documentStore.selectedDocumentItem.id === documentItem.id
            ? true
            : false
        }
        documentItem={documentItem}
        handleEditApplyClick={this.handleEditApplyClick}
        handleDeleteApplyClick={this.handleDeleteApplyClick}
        handleListItemClick={this.handleListItemClick}
        userDocumentItems={stores.documentStore.documents}
      />
    );
  };

  private renderSharedDocumentRowItem = (
    sharedDocumentItem: SharedDocumentItem
  ) => {
    return (
      <SharedDocumentRowItem
        key={`sharedDocumentRowItem${sharedDocumentItem.document_id}`}
        selected={
          stores.documentStore.selectedDocumentItem &&
          stores.documentStore.selectedDocumentItem.id.toString() ===
            sharedDocumentItem.document_id.toString()
            ? true
            : false
        }
        sharedDocumentItem={sharedDocumentItem}
        handleListItemClick={this.handleListItemClick}
      />
    );
  };

  render() {
    let documentsToShow = [];
    let sharedDocumentsToShow = [];
    if (
      stores.userStore.currentUser.subscriptions &&
      stores.userStore.currentUser.subscriptions.length >= 1
    ) {
      documentsToShow = stores.documentStore.documents;
      sharedDocumentsToShow = stores.documentStore.sharedDocuments;
    } else {
      documentsToShow = stores.documentStore.documents.slice(0, 2);
      sharedDocumentsToShow = stores.documentStore.sharedDocuments.slice(0, 2);
    }
    return (
      <>
        <Box sx={styles.styleSheet.titleContainer}>
          <Typography variant="body1" sx={styles.styleSheet.titleText}>
            {i18n.editorScreen.documents}
          </Typography>

          <Box sx={styles.styleSheet.divider} />

          <IconButton
            sx={styles.styleSheet.addButton}
            aria-label="edit"
            size="medium"
            onClick={this.handleNewDocumentClick}
          >
            {" "}
            <AddIcon />
          </IconButton>
        </Box>

        <Box sx={styles.styleSheet.documentsList}>
          <List component="nav">
            {documentsToShow.map((documentItem) =>
              this.renderDocumentRowItem(documentItem)
            )}

            {sharedDocumentsToShow.map((sharedDocumentItem) =>
              this.renderSharedDocumentRowItem(sharedDocumentItem)
            )}
          </List>
        </Box>

        {/* Document type selection dialog */}
        <Dialog
          open={this.state.isDocumentTypeDialogOpen}
          onClose={() => this.setState({ isDocumentTypeDialogOpen: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent sx={{ pb: 2, pr: 5 }}>
            <DialogContentText sx={{ pb: 2, textAlign: "center" }}>
              Please select the document type:
            </DialogContentText>
            <Button
              onClick={() => this.handleDocumentTypeSelection("Translation")}
              variant="outlined"
              sx={{ border: "1px solid", gap: 0.4 }} // Add border and margin bottom
            >
              <DescriptionOutlinedIcon sx={{ mb: 0.4 }} /> Translation
            </Button>
            <Button
              onClick={() => this.handleDocumentTypeSelection("Editor")}
              variant="outlined"
              sx={{ border: "1px solid", gap: 0.4, ml: 5 }} // Add border and margin bottom
            >
              <PanoramaFishEyeIcon sx={{ mb: 0.3 }} /> Editor
            </Button>
            <Button
              onClick={() => this.handleDocumentTypeSelection("Voice")}
              variant="outlined"
              sx={{ border: "1px solid", gap: 0.4, ml: 5 }} // Add border and margin bottom
            >
              <KeyboardVoiceIcon sx={{ mb: 0.3 }} /> Voice
            </Button>
          </DialogContent>
        </Dialog>

        {/* Dialog for providing a prompt */}
        <Dialog
          open={this.state.isPromptDialogOpen}
          onClose={this.handlePromptDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <DialogContentText>
              What are you writing for? (recommended for better results){" "}
              <i style={{ fontSize: "0.9em" }}>
                <br />
                (e.g., A guide about traveling to Japan)
              </i>
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              id="prompt"
              label="Subject"
              type="text"
              fullWidth
              value={this.state.promptValue}
              onChange={(e) => this.setState({ promptValue: e.target.value })}
            />
          </DialogContent>
          <DialogActions sx={{ pr: 3, pb: 3 }}>
            {this.state.isSubmitting ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  onClick={this.handlePromptDialogPromptedSubmit}
                  sx={{ gap: 0.5 }}
                >
                  Next
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
