/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";
import { PromptMode, RegenerateModeType } from "../../models/RegenerateMode";

import ApolloClientHelper from "../ApolloClientHelper";

export default async function updateRegenerateMode(
  id: number,
  name: string,
  prompt: string,
  is_translation: boolean,
  types: RegenerateModeType[],
  prompt_modes: PromptMode[]
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const typesString = types.map((type) => type.valueOf()).join();
  const promptModesString = prompt_modes.map((type) => type.valueOf()).join();

  const mutation = gql`
    mutation {
      updateRegenerateMode(
        id: ${id}
        name: "${name}"
        prompt: "${encodeURIComponent(prompt)}"
        is_translation: ${Number(is_translation)}
        types: "${typesString}"
        prompt_modes: "${promptModesString}"
      ) 
    }
  `;

  const updateRegenerateModeResult = await client.mutate({ mutation });

  const result = get(
    updateRegenerateModeResult,
    "data.updateRegenerateMode",
    null
  );

  return result !== null && result[0] > 0;
}
