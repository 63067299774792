import * as React from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";

import IosShareIcon from "@mui/icons-material/IosShare";

import { DocumentItem } from "../../../../models/DocumentItem";
import stores from "../../../../stores";
import Analytics from "../../../../helpers/Analytics";

import styles from "./styles";

type Props = {
  selected: boolean;
  userDocumentItems: DocumentItem[];
  documentItem: DocumentItem;
  handleListItemClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    documentItem: DocumentItem
  ) => any;
  handleEditApplyClick: (
    documentNameTextInputValue: string,
    documentItem: DocumentItem
  ) => any;
  handleDeleteApplyClick: (documentItem: DocumentItem) => any;
};

type State = {
  isEditDocumentItem: boolean;
  isDeleteDocumentItem: boolean;
  documentNameTextInputValue: string;
};

export default class DocumentRowItem extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isEditDocumentItem: false,
      isDeleteDocumentItem: false,
      documentNameTextInputValue: "",
    };
  }

  private handleNotApplyClick = () => {
    const { isEditDocumentItem, isDeleteDocumentItem } = this.state;

    if (isDeleteDocumentItem) {
      this.setState({ isDeleteDocumentItem: false });
    } else if (isEditDocumentItem) {
      this.setState({ isEditDocumentItem: false });
      this.setState({ documentNameTextInputValue: "" });
    }
  };

  private handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    documentItem: DocumentItem
  ) => {
    if (documentItem.user_id !== stores.userStore.currentUser.id)
      this.props.handleListItemClick(event, documentItem);
  };

  private handleEditApplyClick = () => {
    const { documentItem } = this.props;
    const { documentNameTextInputValue } = this.state;

    if (documentNameTextInputValue !== "") {
      this.setState({ isEditDocumentItem: false });

      this.props.handleEditApplyClick(documentNameTextInputValue, documentItem);
    }
  };

  private handleDeleteApplyClick = () => {
    const { documentItem } = this.props;

    this.setState({ isDeleteDocumentItem: false });

    this.props.handleDeleteApplyClick(documentItem);
  };

  render() {
    const { documentItem, selected } = this.props;
    const { isEditDocumentItem, isDeleteDocumentItem } = this.state;

    return (
      <Box
        key={documentItem.id}
        sx={[
          styles.styleSheet.container,
          {
            bgcolor: selected
              ? stores.ui.themeColors.selectedBackground
              : "transparent",
          },
        ]}
        onClick={(event) => this.handleListItemClick(event, documentItem)}
      >
        <Box sx={styles.styleSheet.mainContainer}>
          {isEditDocumentItem && selected ? (
            <TextField
              sx={{ width: "100%", mr: 1 }}
              id="outlined-basic"
              defaultValue={documentItem.name}
              variant="outlined"
              onChange={(event) =>
                this.setState({
                  documentNameTextInputValue: event.target.value,
                })
              }
              onKeyUp={(event) =>
                event.key === "Enter" ? this.handleEditApplyClick() : {}
              }
            />
          ) : (
            <Box sx={styles.styleSheet.nameContainer}>
              {documentItem.document_language === "Editor" ? (
                <PanoramaFishEyeIcon sx={{ mr: 1 }} />
              ) : documentItem.document_language === "Voice" ? (
                <KeyboardVoiceIcon sx={{ mr: 1 }} />
              ) : (
                <DescriptionOutlinedIcon sx={{ mr: 1 }} />
              )}

              <Typography sx={styles.styleSheet.nameText} variant="body2">
                {documentItem.name}
              </Typography>
            </Box>
          )}

          {selected && !isDeleteDocumentItem && !isEditDocumentItem ? (
            <div style={{ display: "flex" }}>
              <IconButton
                sx={{ p: 0.5 }}
                aria-label="share"
                size="medium"
                onClick={() => {
                  Analytics.track("share_document_clicked", "");

                  stores.userStore.isShareModalOpened = true;
                }}
              >
                <IosShareIcon />
              </IconButton>

              <IconButton
                sx={{ p: 0.5 }}
                aria-label="edit"
                size="medium"
                onClick={() => {
                  Analytics.track("edit_document_clicked", "");

                  this.setState({ isEditDocumentItem: true });
                }}
              >
                <EditOutlinedIcon />
              </IconButton>

              <IconButton
                sx={{ p: 0.5 }}
                aria-label="delete"
                size="medium"
                onClick={() => {
                  Analytics.track("delete_document_clicked", "");

                  this.setState({ isDeleteDocumentItem: true });
                }}
              >
                <DeleteTwoToneIcon />
              </IconButton>
            </div>
          ) : null}

          {selected && (isEditDocumentItem || isDeleteDocumentItem) ? (
            <div style={{ display: "flex" }}>
              <IconButton
                sx={{ p: 0.5 }}
                aria-label="edit"
                size="medium"
                onClick={() =>
                  isEditDocumentItem
                    ? this.handleEditApplyClick()
                    : isDeleteDocumentItem
                    ? this.handleDeleteApplyClick()
                    : {}
                }
              >
                <CheckOutlinedIcon />
              </IconButton>

              <IconButton
                sx={{ p: 0.5 }}
                aria-label="delete"
                size="medium"
                onClick={this.handleNotApplyClick}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </div>
          ) : null}
        </Box>
      </Box>
    );
  }
}
