/* @flow */

import keys from "../../constants/keys";
import { StripeProfile } from "../../models/User";

export default async function getStripeProfile(
  email: string
): Promise<StripeProfile | undefined> {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    };

    fetch(`${process.env.REACT_APP_API_URL}/customer-search`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.data.length > 0) {
          const stripeProfile: StripeProfile = data.data[0];

          resolve(stripeProfile);
        } else {
          resolve(undefined);
        }
      });
  });
}
