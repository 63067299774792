import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import React from "react";

import i18n from "../../../data/i18n";
import Analytics from "../../../helpers/Analytics";

export const SignupButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    Analytics.track("signup_button_clicked", "");

    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  return (
    <Button
      sx={{ textTransform: "none" }}
      variant="contained"
      onClick={handleSignUp}
    >
      {i18n.common.signup}
    </Button>
  );
};
