import * as React from "react";
import { observer } from "mobx-react";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import SettingsIcon from "@mui/icons-material/Settings";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import HelpIcon from "@mui/icons-material/Help";

import stores from "../../../stores";
import i18n from "../../../data/i18n";
import FeedbackModal from "../../../components/FeedbackModal";
import Analytics from "../../../helpers/Analytics";
import HelpModal from "../../../components/HelpModal";

import { SignupButton } from "./SignupButton";
import { LoginButton } from "./LoginButton";
import { LogoutButton } from "./LogoutButton";
import styles from "./styles";

type Props = {
  onSettingPress: () => any;
  onUpgradePress: () => any;
  onCancelSubscriptionPress: () => any;
};

type State = {
  isFeedbackModalOpen: boolean;
  anchorEl: any;
  isUserMenuVisible: boolean;
};

@observer
export default class UserInfoView extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isFeedbackModalOpen: false,
      anchorEl: null,
      isUserMenuVisible: false,
    };
  }

  private handleSyncClick = async () => {};

  private handleUserClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget, isUserMenuVisible: true });
  };

  render() {
    const { onSettingPress, onUpgradePress, onCancelSubscriptionPress } =
      this.props;
    const { isFeedbackModalOpen, anchorEl, isUserMenuVisible } = this.state;

    if (
      !stores.userStore.currentUser.email ||
      stores.userStore.currentUser.email === "undefined" ||
      stores.userStore.currentUser.email === undefined
    ) {
      return (
        <>
          <Typography variant="body1" sx={styles.styleSheet.loginInfoText}>
            {i18n.editorScreen.loginMessage}
          </Typography>

          <Box sx={styles.styleSheet.buttonContainer}>
            <LoginButton />

            <SignupButton />
          </Box>

          <List sx={{ mt: 2 }} component="nav">
            <ListItemButton onClick={onSettingPress}>
              <ListItemIcon sx={{ mr: -2 }}>
                <SettingsIcon />
              </ListItemIcon>

              <Typography variant="body2">{i18n.common.settings}</Typography>

              {!stores.userStore.currentUser.openai_api_key ||
              stores.userStore.currentUser.openai_api_key === "undefined" ? (
                <ReportProblemIcon color="warning" sx={{ ml: 1 }} />
              ) : null}
            </ListItemButton>

            <ListItemButton
              onClick={() => (stores.documentStore.isHelpModalOpen = true)}
            >
              <ListItemIcon sx={{ mr: -2 }}>
                <HelpIcon />
              </ListItemIcon>

              <Typography variant="body2">{i18n.common.help}</Typography>
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                Analytics.track("send_feedback_button_clicked", "");

                this.setState({ isFeedbackModalOpen: true });
              }}
            >
              <ListItemIcon sx={{ mr: -2 }}>
                <QuestionAnswerIcon />
              </ListItemIcon>

              <Typography variant="body2">
                {i18n.common.sendFeedback}
              </Typography>
            </ListItemButton>
          </List>

          <FeedbackModal
            open={isFeedbackModalOpen}
            onClose={() => this.setState({ isFeedbackModalOpen: false })}
          />

          <HelpModal
            open={stores.documentStore.isHelpModalOpen}
            onClose={() => (stores.documentStore.isHelpModalOpen = false)}
          />
        </>
      );
    }

    return (
      <>
        <Box sx={styles.styleSheet.titleContainer}>
          <IconButton
            sx={styles.styleSheet.menuButton}
            size="medium"
            onClick={this.handleUserClick}
          >
            {isUserMenuVisible ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </IconButton>

          <Typography
            onClick={this.handleUserClick}
            variant="body1"
            sx={styles.styleSheet.titleText}
          >
            {stores.userStore.currentUser.email}
          </Typography>

          <Box sx={styles.styleSheet.divider} />

          <IconButton
            sx={styles.styleSheet.syncButton}
            aria-label="edit"
            size="medium"
            onClick={this.handleSyncClick}
          >
            {stores.documentStore.isDocumentsUpdating ? (
              <CloudSyncIcon />
            ) : (
              <CloudDoneIcon />
            )}
          </IconButton>
        </Box>

        <List component="nav">
          {stores.userStore.currentUser.subscriptions &&
          stores.userStore.currentUser.subscriptions.length > 0 ? null : (
            <ListItemButton onClick={onUpgradePress}>
              <ListItemIcon sx={{ mr: -2 }}>
                <UpgradeIcon />
              </ListItemIcon>

              <Typography variant="body2">{i18n.common.upgrade}</Typography>
            </ListItemButton>
          )}

          <ListItemButton onClick={onSettingPress}>
            <ListItemIcon sx={{ mr: -2 }}>
              <SettingsIcon />
            </ListItemIcon>
            <Typography variant="body2">{i18n.common.settings}</Typography>
            {!stores.userStore.currentUser.openai_api_key ? (
              <ReportProblemIcon color="warning" sx={{ ml: 1 }} />
            ) : null}{" "}
          </ListItemButton>

          <ListItemButton
            onClick={() => this.setState({ isFeedbackModalOpen: true })}
          >
            <ListItemIcon sx={{ mr: -2 }}>
              <QuestionAnswerIcon />
            </ListItemIcon>

            <Typography variant="body2">{i18n.common.sendFeedback}</Typography>
          </ListItemButton>

          <ListItemButton
            onClick={() => (stores.documentStore.isHelpModalOpen = true)}
          >
            <ListItemIcon sx={{ mr: -2 }}>
              <HelpIcon />
            </ListItemIcon>

            <Typography variant="body2">{i18n.common.help}</Typography>
          </ListItemButton>
        </List>

        <Menu
          id="basic-menu"
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "bottom", horizontal: "left" }}
          anchorEl={anchorEl}
          open={isUserMenuVisible}
          onClose={() => this.setState({ isUserMenuVisible: false })}
        >
          {stores.userStore.currentUser.subscriptions &&
          stores.userStore.currentUser.subscriptions.length > 0 ? (
            <MenuItem>
              <Button
                variant="outlined"
                sx={styles.styleSheet.cancelSubscriptionButton}
                onClick={onCancelSubscriptionPress}
              >
                {i18n.upgradeModal.cancelSubscription}
              </Button>
            </MenuItem>
          ) : null}

          <MenuItem>
            <LogoutButton />
          </MenuItem>
        </Menu>

        <FeedbackModal
          open={isFeedbackModalOpen}
          onClose={() => this.setState({ isFeedbackModalOpen: false })}
        />

        <HelpModal
          open={stores.documentStore.isHelpModalOpen}
          onClose={() => (stores.documentStore.isHelpModalOpen = false)}
        />
      </>
    );
  }
}
