/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";

export default async function updateDocumentItem(
  id: number,
  name: string,
  documentLanguage: string | undefined,
  translationLanguage: string | undefined,
  userId: number,
  date: string
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateDocument(
        id: ${id}
        name: "${name}"
        document_language: "${documentLanguage}"
        translation_language: "${translationLanguage}"
        user_id: ${userId}
        date: "${date}"
      ) 
    }
  `;

  const updateDocumentMutationResult = await client.mutate({ mutation });

  const result = get(updateDocumentMutationResult, "data.updateDocument", null);

  return result !== null && result[0] > 0;
}
