import ReactDOM from "react-dom/client";
import { toast } from "react-toastify";

import DarkTranslateIcon from "../../../assets/dark/translateIcon.png";
import LightTranslateIcon from "../../../assets/light/translateIcon.png";
import * as $ from "../../../utils/dom";
import stores from "../../../stores";
import { TuneType } from "../../../helpers/Enum";
import TranslationBlockItem from "../../TranslationBlockItem";
import i18n from "../../../data/i18n";
import Analytics from "../../../helpers/Analytics";

/**
 * Available predefined variants
 */
export const TranslationVariant = {
  /**
   * For the translation process, the author needs to choose
   */
  Translate: TuneType.Translated,
};

export default class TranslationTune {
  /**
   * Tell editor.js that this Tool is a Block Tune
   *
   * @returns {boolean}
   */
  static get isTune() {
    return true;
  }

  /**
   * CSS selectors used in Tune
   */
  static get CSS() {
    return {
      toggler: "cdx-text-variant__toggler",
    };
  }

  /**
   * Tune constructor. Called on Block creation
   *
   * @param {object} options - constructor params
   * @param {API} api - editor.js Core API
   * @param {BlockAPI} block - editor.js Block API
   * @param {TextVariantData} data - previously saved data
   * @param {TextVariantTuneConfig} config - configuration supported by Tune
   */
  constructor({ api, data, config, block }) {
    this.api = api;
    this.data = data;
    this.config = config;
    this.block = block;

    this.variants = [
      {
        name: TranslationVariant.Translate,
        icon:
          stores.ui.theme === "dark" ? DarkTranslateIcon : LightTranslateIcon,
        title:
          this.data === TuneType.Translated
            ? this.api.i18n.t("Translate Again")
            : this.api.i18n.t("Translate"),
      },
    ];

    this.wrapper = undefined;
  }

  render() {
    const tuneWrapper = $.make("div", ["ce-popover__custom-item"]);

    this.variants.forEach(({ name, icon, title }) => {
      const toggler = $.make(
        "div",
        [this.api.styles.settingsButton, TranslationTune.CSS.toggler],
        {
          innerHTML: `
            <div class="ce-popover__item-icon ce-popover-item__icon"><img style="width: 20px" src='${icon}' /></div>
            <div class="ce-popover__item-label" style="font-size: 14px;">${title}</div>`,
        }
      );

      toggler.dataset.name = name;

      tuneWrapper.appendChild(toggler);
    });

    /**
     * Delegate click event on all the controls
     */
    this.api.listeners.on(tuneWrapper, "click", (event) => {
      this.tuneClicked(event);
    });

    return tuneWrapper;
  }

  /**
   * Handler for Tune controls click
   * Toggles the variant
   *
   * @param {MouseEvent} event - click
   * @returns {void}
   */
  async tuneClicked(event) {
    Analytics.track("translate_tool_button_clicked", "");

    const tune = event.target.closest(`.${this.api.styles.settingsButton}`);
    const isEnabled = tune.classList.contains(
      this.api.styles.settingsButtonActive
    );

    const elements = document.getElementsByClassName("ce-settings");

    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }

    if (
      !stores.userStore.currentUser.openai_api_key ||
      stores.userStore.currentUser.openai_api_key === "undefined"
    ) {
      stores.userStore.isOpenAIApiKeyAlertModalOpened = true;
      return;
    }

    if (
      stores.documentStore.selectedDocumentItem &&
      stores.documentStore.selectedDocumentItem.translation_language
    ) {
      await stores.documentStore.translate(
        this.block.id,
        stores.documentStore.selectedDocumentItem.translation_language
      );

      tune.classList.toggle(this.api.styles.settingsButtonActive, !isEnabled);

      this.variant = !isEnabled ? tune.dataset.name : "";
    } else {
      toast.error(i18n.editorScreen.languageSelectWarning, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: stores.ui.theme,
      });
    }
  }

  /**
   * Wraps Block Content to the Tunes wrapper
   *
   * @param {Element} blockContent - editor.js block inner container
   * @returns {Element} - created wrapper
   */
  wrap(blockContent) {
    this.wrapper = $.make("div");

    this.variant = this.data;

    this.wrapper.appendChild(blockContent);

    return this.wrapper;
  }

  /**
   * Save current variant in memory and apply style for that
   *
   * @param {string} name - variant to save
   */
  set variant(name) {
    this.data = name;

    this.variants.forEach((variant) => {
      const rootNode = $.make("div", [
        "ce-block__content",
        "ce-translation-block__content",
      ]);

      ReactDOM.createRoot(rootNode).render(
        <TranslationBlockItem blockId={this.block.id} />
      );

      var elements = this.wrapper.getElementsByClassName(
        "ce-translation-block__content"
      );

      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }

      setTimeout(() => {
        this.wrapper.appendChild(rootNode);
      }, "100");

      this.wrapper.classList.toggle(
        `cdx-text-variant--${variant.name}`,
        variant.name === this.data
      );
    });
  }

  /**
   * Returns Tune state
   *
   * @returns {string}
   */
  save() {
    return this.data || "";
  }
}
