/* @flow */

import { computed } from "mobx";

import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      editor: {
        flex: 1,
        margin: 30,
        marginLeft: 40,
        fontSize: stores.userStore.currentUser.settings.original_text_size,
        color:
          stores.userStore.currentUser.settings.original_text_color ===
            "#ffffff" && stores.ui.theme === "light"
            ? "#000000"
            : stores.userStore.currentUser.settings.original_text_color ===
                "#000000" && stores.ui.theme === "dark"
            ? "#ffffff"
            : stores.userStore.currentUser.settings.original_text_color,
      },
    };

    return styles;
  }
}
