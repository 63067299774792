import React from "react";
import { observer } from "mobx-react";
import { Box, Button, Typography, Paper } from "@mui/material";
import { toast } from "react-toastify";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ReactPlayer from "react-player/youtube";

import FeatureCards from "./FeatureCards";
import { GradientText } from "./GradientText";
import { ScrollAnimation } from "./ScrollAnimation";
import { TestimonialsCarousel } from "./TestimonialsCarousel";
import { LoginButton } from "./LoginButton";
import { SignupButton } from "./SignupButton";

import i18n from "../../data/i18n";
import InputEmailField from "../../components/InputEmailField";
import { createLeadMail } from "../../helpers/api";
import stores from "../../stores";
import Analytics from "../../helpers/Analytics";

import styles from "./styles";
import MainHeader from "./MainHeader";
import Footer from "../../components/Footer";

interface Props extends RouteComponentProps {}

type State = {
  mail: string;
  isValidMail: boolean;
  isMailFieldVisible: boolean;
};

@observer
class HomePage extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      mail: "",
      isValidMail: false,
      isMailFieldVisible: false,
    };
  }

  private handleSubmitMail = async () => {
    const { mail, isValidMail } = this.state;

    if (mail !== "" && isValidMail) {
      Analytics.track("waiting_list_email_submitted", "");

      await createLeadMail(mail);

      toast.success(i18n.homeScreen.joinMailSuccessMessage, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: stores.ui.theme,
      });

      this.setState({
        mail: "",
        isValidMail: false,
        isMailFieldVisible: false,
      });
    }
  };

  private handleMailChange = (value: string, isValid: boolean) => {
    this.setState({ mail: value, isValidMail: isValid });
  };

  private handleJoinClick = () => {
    Analytics.track("try_now_for_free_pressed", "");

    this.props.history.push("/editor");
  };
  private handlePricingPageClick = () => {
    Analytics.track("pricing_page_button_pressed", "");

    window.location.href = "/pricing";
  };

  private handleCTAClick = () => {
    Analytics.track("try_now_pressed", "");

    this.props.history.push("/editor");
  };

  private handleBlogClick = () => {
    Analytics.track("blog_button_pressed", "");

    window.location.href = "https://www.lingoedit.com/blog";
  };

  render() {
    const { isMailFieldVisible, isValidMail } = this.state;

    return (
      <Box sx={styles.styleSheet.container}>
        <Box>
          <MainHeader />
        </Box>

        <Box sx={styles.styleSheet.mainContainer}>
          <Typography variant="h6" sx={styles.styleSheet.descriptionText}>
            {i18n.homeScreen.editorInfo}
          </Typography>

          <Box sx={styles.styleSheet.middleButtonContainer}>
            <Button
              sx={styles.styleSheet.tryButton}
              onClick={this.handleJoinClick}
              variant="contained"
              color="primary"
              disabled={isMailFieldVisible}
            >
              {i18n.homeScreen.tryNowForFree}
            </Button>

            <a
              href="https://www.producthunt.com/posts/lingoedit?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-lingoedit"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=395235&theme=light"
                alt="Lingoedit - Simplify&#0032;translation&#0032;and&#0032;editing&#0058;&#0032;your&#0032;AI&#0032;language&#0032;companion | Product Hunt"
                style={{
                  width: "250px",
                  height: "54px",
                  marginLeft: 16,
                  marginRight: 16,
                }}
                width="250"
                height="54"
              />
            </a>

            <a
              href="https://www.producthunt.com/products/lingoedit/reviews?utm_source=badge-product_review&utm_medium=badge&utm_souce=badge-lingoedit"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/product_review.svg?product_id=535181&theme=neutral"
                alt="Lingoedit - Simplify&#0032;translation&#0032;and&#0032;editing&#0058;&#0032;Your&#0032;AI&#0032;language&#0032;companion | Product Hunt"
                style={{ width: "250px", height: "54px" }}
                width="250"
                height="54"
              />
            </a>
          </Box>

          {isMailFieldVisible ? (
            <Box sx={styles.styleSheet.inputContainer}>
              <InputEmailField
                placeholder=""
                helperText={i18n.homeScreen.validMail}
                label="Email"
                fieldName="Email"
                onChange={this.handleMailChange}
              />

              <Button
                sx={styles.styleSheet.submitButton}
                variant="contained"
                color="primary"
                disabled={!isValidMail}
                onClick={this.handleSubmitMail}
              >
                {i18n.common.submit}
              </Button>
            </Box>
          ) : null}
          <Box>
            <ReactPlayer
              url="https://youtu.be/WXLHJte4bP4"
              pip={true}
              style={styles.styleSheet.video}
              width={"60vw"}
              height={"32vw"}
            />
          </Box>

          {/* Title Text Container */}
          <Box sx={styles.styleSheet.titleTextContainer}>
            <GradientText
              title={i18n.homeScreen.pageTitle}
              gradientIndices={[0]}
            />
            <Typography variant="h5">{i18n.homeScreen.pageSubTitle}</Typography>
          </Box>

          {/* Feature List and Descriptions */}
          <ScrollAnimation index={1}>
            <FeatureCards />
          </ScrollAnimation>

          {/* Call To Action Section */}
          <Box sx={styles.styleSheet.callToAction}>
            <GradientText
              title={i18n.homeScreen.callToActionTitleOne}
              gradientIndices={[4, 5]}
              typographyVariant="h4"
            />

            <Button
              sx={styles.styleSheet.tryButton}
              onClick={this.handleCTAClick}
              color="primary"
            >
              {i18n.homeScreen.tryNow}
            </Button>
          </Box>

          {/* Tutorial */}
          <Box sx={styles.styleSheet.tutorialTextSection}>
            <GradientText
              title={i18n.homeScreen.tutorialTitle}
              gradientIndices={[4, 5, 6, 7]}
            />
          </Box>

          <Box sx={{ mb: 10, mt: 10 }}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=KiGmwPP9n-0"
              style={styles.styleSheet.video}
              pip={true}
              width={"60vw"}
              height={"32vw"}
            />
          </Box>
          {/* <Testimonials /> */}
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {i18n.homeScreen.testimonialTitle}
          </Typography>

          <TestimonialsCarousel />

          <Box />
        </Box>
        <Footer />
      </Box>
    );
  }
}

export default withRouter(HomePage);
