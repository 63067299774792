// ** MUI Imports
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import SubscriptionModal from '../../../components/SubscriptionModal'

// ** Icon Imports

// ** Util Import
import { hexToRGBA } from '../hex-to-rgba'

// ** Custom Components Imports
import CustomChip from '../chip'

// ** Types
import { PricingPlanProps } from './types'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import stores from "../../../stores";
import { useHistory } from "react-router-dom";


// ** Styled Component for the wrapper of whole component
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(6),
  paddingTop: theme.spacing(14.75),
  borderRadius: theme.shape.borderRadius
}))


// ** Styled Component for the wrapper of all the features of a plan
const BoxFeature = styled(Box)<BoxProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  '& > :not(:first-of-type)': {
    marginTop: theme.spacing(4)
  }
}))

const PlanDetails = (props: PricingPlanProps) => {
  // ** Props
  const { plan, data } = props
  const { loginWithRedirect } = useAuth0();

  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Check if stores.userStore is undefined
    if (stores.userStore.currentUser.isAnonymous === true) {
      // Redirect to /pricing if stores.userStore is undefined
      history.push('/pricing');
    } else {
      // Only proceed with modal logic if stores.userStore is defined
      const queryParams = new URLSearchParams(window.location.search);
      const subscriptionModalParam = queryParams.get('subscriptionModal');
      if (subscriptionModalParam === 'true') {
      setIsModalOpen(true); // Open the modal
      }
    }
  }, [history]);

  // Modal'ı kapatmak için fonksiyon
  const handleCloseModal = () => {
    setIsModalOpen(false); // Assuming you are managing the modal's open state
    history.push('/pricing'); // Redirects to the /pricing page
    window.location.reload(); // Reloads the page
  };

  const renderFeatures = () => {
    return data?.planBenefits.map((item: string, index: number) => (
      <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box component='span' sx={{ display: 'inline-flex', color: 'text.secondary', mr: 2 }}>
          <TaskAltIcon style={{ color: '#8fc9f9' }} />
        </Box>
        <Typography variant='body2'>{item}</Typography>
      </Box>
    ))
  }
  const handleUpgradeClick = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/pricing?subscriptionModal=true",
      },
      authorizationParams: {
        prompt: "login",
      },
    });

  };

  return (
    <BoxWrapper
      sx={{
        border: theme =>
          !data?.popularPlan
            ? `1px solid ${theme.palette.divider}`
            : `1px solid ${hexToRGBA(theme.palette.primary.main, 0.5)}`
      }}
    >
      {data?.popularPlan ? (
        <CustomChip
          rounded
          skin='light'
          label='Popular'
          color='primary'
          sx={{
            top: 12,
            right: 12,
            height: 24,
            position: 'absolute',
            '& .MuiChip-label': {
              px: 1.75,
              fontWeight: 600,
              fontSize: '0.75rem'
            }
          }}
        />
      ) : null}

      <Box sx={{ textAlign: 'center' }}>
        <Typography variant='h5' >
          {data?.title}
        </Typography>
        <Typography variant='body2'>{data?.subtitle}</Typography>
        <Box sx={{ my: 4, position: 'relative' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant='body2' sx={{ mt: 1.6, fontWeight: 600, alignSelf: 'flex-start' }}>
              $
            </Typography>
            <Typography variant='h3' sx={{ fontWeight: 600, color: 'primary.main', lineHeight: 1.17 }}>
              {plan === 'monthly' ? data?.monthlyPrice : data?.yearlyPlan.perMonth}
            </Typography>
            <Typography variant='body2' sx={{ mb: 1.6, fontWeight: 600, alignSelf: 'flex-end' }}>
              /month
            </Typography>
          </Box>
          {plan !== 'monthly' && data?.monthlyPrice !== 0 ? (
            <Typography
              variant='caption'
              sx={{ top: 50, left: '50%', position: 'absolute', transform: 'translateX(-50%)' }}
            >{`USD ${data?.yearlyPlan.totalAnnual}/year`}</Typography>
          ) : null}
        </Box>
      </Box>
      <BoxFeature>{renderFeatures()}</BoxFeature>
      <Button
        fullWidth
        color={data?.currentPlan ? 'primary' : 'primary'}
        variant={data?.popularPlan ? 'contained' : 'contained'}
        disabled={data?.currentPlan}
        onClick={!data?.currentPlan ? handleUpgradeClick : undefined}>
        {data?.currentPlan ? 'Your Current Plan' : 'Upgrade'}
      </Button>

      <SubscriptionModal
      onClose={handleCloseModal}
      open={isModalOpen}
    />

    </BoxWrapper>
  )
}
export default PlanDetails
