/* @flow */

import { computed } from "mobx";

import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      promptModeButton: {
        flex: 1,
        textTransform: "none",
        color: stores.ui.themeColors.textPrimary,
        bgcolor: stores.ui.themeColors.selectedBackground,
        borderRadius: 1,
        height: 50,
        pr: 2,
      },
      promptModeList: {},
      checkIcon: {
        w: 10,
        h: 10,
        mr: 1,
        ml: 1,
      },
    };

    return styles;
  }
}
