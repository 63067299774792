import { computed } from "mobx";

import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        mr: 1,
      },
      modalButton: {
        textTransform: "none",
        color: stores.ui.themeColors.textPrimary,
        bgcolor: stores.ui.themeColors.selectedBackground,
        borderRadius: 1,
        height: 50,
        pl: 2,
        pr: 2,
      },
      itemContainer: {
        display: "flex",
        justifyContent: "space-between",
      },
      itemLabel: {},
      itemShortcutContainer: {
        display: "flex",
        p: 1,
        justifyContent: "center",
        alignItems: "center",
        bgcolor: stores.ui.themeColors.secondaryBackground,
        ml: 3,
        borderRadius: 1,
        minWidth: 100,
      },
      itemShortcut: {},
      searchBar: {
        display: "flex",
        ml: 1,
        mr: 1,
        flex: 1,
        fontSize: 14,
      },
      divider: {
        mt: 1,
      },
      shortcutListContainer: {
        width: 400,
        height: "30vh",
      },
      shortcutListBox: {
        backgroundColor: stores.ui.themeColors.textSecondary,
        borderRadius: "5px",
        fontSize: 8,
      },
      shortcutList: {
        mr: 1,
        ml: 1,
      },
    };

    return styles;
  }
}
