import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { PageLoader } from "./components/pageLoader";
import HomePage from "./pages/HomePage";
import stores from "./stores";
import EditorPage from "./pages/EditorPage";
import FAQ from "./pages/FAQPage";
import Pricing from "./pages/PricingPages";

export var theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: "Roboto-Medium",
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          alignItems: "flex-start",
          "&.Mui-selected": {
            backgroundColor: stores.ui.themeColors.selectedBackground,
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backgroundColor: "transparent",
        },
      },
    },
  },
  palette: {
    mode: stores.ui.theme,
  },
});

export const App: React.FC = observer(() => {
  const { user, isLoading } = useAuth0();

  theme = createTheme({
    typography: {
      fontSize: 14,
      fontFamily: "Roboto-Medium",
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            alignItems: "flex-start",
            "&.Mui-selected": {
              backgroundColor: stores.ui.themeColors.selectedBackground,
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            // backgroundColor: "transparent",
          },
        },
      },
    },
    palette: {
      mode: stores.ui.theme,
    },
  });

  if (!isLoading) {
    stores.userStore.setUser(user);
  }

  if (!stores.userStore.isCurrentUserReady) {
    return <PageLoader />;
  }

  if (!stores.userStore.currentUser.isAnonymous) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <Route path="/" exact component={EditorPage} />
          <Route path="/faq" exact component={FAQ} />
          <Route path="/pricing" exact component={Pricing} />
        </Switch>

        <ToastContainer
          toastStyle={{ width: 400, fontFamily: "Roboto-Medium" }}
        />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/editor" exact component={EditorPage} />
        <Route path="/faq" exact component={FAQ} />
        <Route path="/pricing" exact component={Pricing} />
      </Switch>

      <ToastContainer
        toastStyle={{ width: 400, fontFamily: "Roboto-Medium" }}
      />
    </ThemeProvider>
  );
});
