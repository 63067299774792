/* @flow */

import { SubscriptionCoupon } from "../../models/SubscriptionCoupon";

export default async function getSubscriptionCoupons(): Promise<
  SubscriptionCoupon[]
> {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };

    fetch(`${process.env.REACT_APP_API_URL}/list-all-coupons`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          if (
            data.coupons &&
            data.coupons.data &&
            data.coupons.data.length > 0
          ) {
            const subscriptionCoupons: SubscriptionCoupon[] = data.coupons.data;

            resolve(subscriptionCoupons);
          } else {
            reject();
          }
        } else {
          reject();
        }
      });
  });
}
