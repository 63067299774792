import * as React from "react";
import { observer } from "mobx-react";
import { Box, IconButton, Popover, Tooltip, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { PromptMode, RegenerateMode } from "../../../../models/RegenerateMode";
import stores from "../../../../stores";
import Functions from "../../../../helpers/Functions";
import i18n from "../../../../data/i18n";
import Analytics from "../../../../helpers/Analytics";

import styles from "./styles";

type Props = {
  disabled: boolean;
  regenerateMode: RegenerateMode;
  isCreatedByUser: boolean;
  onRegenerateModeClick: (regenerateMode: RegenerateMode) => any;
  onEditRegenerateModeClick: (regenerateModeId: number) => any;
};

type State = {
  tooltipText: string;
  viewPopoverIsVisible: boolean;
  anchorEl: any;
};

@observer
export default class RegenerateMenuItem extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      tooltipText: "",
      viewPopoverIsVisible: false,
      anchorEl: null,
    };
  }

  private handleViewButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
    Analytics.track("prompt_view_button_clicked", "");

    this.setState({
      anchorEl: event.currentTarget,
      viewPopoverIsVisible: true,
    });
  };

  render() {
    const {
      disabled,
      regenerateMode,
      isCreatedByUser,
      onRegenerateModeClick,
      onEditRegenerateModeClick,
    } = this.props;
    const { viewPopoverIsVisible, anchorEl } = this.state;

    let isLocked = false;

    if (regenerateMode.prompt_modes_list) {
      isLocked =
        regenerateMode.prompt_modes_list.includes(
          // @ts-ignore
          Functions.getKeyByPromptModeValue(PromptMode.Editor)
        ) &&
        (!stores.userStore.currentUser.subscriptions ||
          stores.userStore.currentUser.subscriptions.length <= 0);
    }

    const nameParts = regenerateMode.name
      .split(/(\[selection\])/)
      .map((part, index) => {
        if (part === "[selection]") {
          return (
            <span
              key={index}
              onMouseEnter={() =>
                //@ts-ignore
                this.setState({ tooltipText: stores.actionStore.selectedText })
              }
              onMouseLeave={() => this.setState({ tooltipText: "" })}
            >
              [selection]
            </span>
          );
        } else {
          return part;
        }
      });

    return (
      <Box
        sx={{
          ...styles.styleSheet.promptItemContainer,
          ...{
            bgcolor: isCreatedByUser
              ? "rgba(25,118,210, 0.1)"
              : stores.ui.themeColors.selectedBackground + "30",
          },
        }}
      >
        <Box
          key={`regenerateMode-${regenerateMode.id}`}
          sx={{
            ...styles.styleSheet.promptItem,
            ...{
              opacity: disabled ? 0.5 : 1,
            },
          }}
        >
          <Typography sx={{ ml: 2 }} variant="body2">
            {isLocked ? "🔒" : ""}
          </Typography>

          <Tooltip followCursor title={this.state.tooltipText}>
            <Typography
              sx={styles.styleSheet.promptLabel}
              onClick={() =>
                disabled ? {} : onRegenerateModeClick(regenerateMode)
              }
              variant="body2"
            >
              {nameParts}
            </Typography>
          </Tooltip>

          {isCreatedByUser ? (
            <IconButton
              sx={styles.styleSheet.iconButton}
              aria-label="edit"
              size="medium"
              onClick={() => onEditRegenerateModeClick(regenerateMode.id)}
            >
              <Tooltip followCursor title={i18n.common.editRegenereMode}>
                <EditOutlined sx={styles.styleSheet.icon} />
              </Tooltip>
            </IconButton>
          ) : null}

          <IconButton
            sx={styles.styleSheet.iconButton}
            aria-label="view"
            size="medium"
            onClick={this.handleViewButtonClicked}
          >
            <Tooltip followCursor title={i18n.common.viewPrompt}>
              <VisibilityIcon sx={styles.styleSheet.icon} />
            </Tooltip>
          </IconButton>

          <Popover
            PaperProps={{
              style: styles.styleSheet.popover,
            }}
            open={viewPopoverIsVisible}
            anchorEl={anchorEl}
            onClose={() => this.setState({ viewPopoverIsVisible: false })}
          >
            <Typography variant="body2" sx={{ p: 2 }}>
              {regenerateMode.prompt}
            </Typography>
          </Popover>
        </Box>
      </Box>
    );
  }
}
