/* @flow */

import { computed } from "mobx";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      mainContainer: {
        display: "flex",
        flexDirection: "column",
        width: 500,
        height: 500,
        p: 3,
      },
    };

    return styles;
  }
}
