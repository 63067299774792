/* @flow */

import { computed } from "mobx";

import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      mainContainer: {
        display: "flex",
        flexDirection: "column",
        width: 500,
        p: 3,
      },
      title: {
        color: stores.ui.themeColors.textPrimary,
        fontWeight: "bold",
        mb: 3,
      },
      description: {
        color: stores.ui.themeColors.textPrimary,
        mb: 1,
      },
      loginButton: {
        textTransform: "none",
        bgcolor: stores.ui.themeColors.primary,
        color: stores.ui.themeColors.textPrimary,
      },
      bottomButtonsContainer: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
      closeButton: {
        textTransform: "none",
        bgcolor: stores.ui.themeColors.secondaryBackground,
        color: stores.ui.themeColors.textPrimary,
        mr: 2,
      },
    };

    return styles;
  }
}
