/* @flow */

import { computed } from "mobx";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        minHeight: 60,
        cursor: "pointer",
      },
      mainContainer: {
        display: "flex",
        p: 2,
      },
      nameContainer: {
        display: "flex",
        width: "100%",
        alignItems: "center",
      },
      nameText: {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 1,
        lineBreak: "anywhere",
        mr: 1,
      },
    };

    return styles;
  }
}
