/* @flow */

import { computed } from "mobx";

import stores from "../../../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        minHeight: 60,
        cursor: "pointer",
      },
      mainContainer: {
        display: "flex",
        p: 2,
      },
      icon: {
        width: 25,
        height: 25,
        marginRight: 10,
      },
      nameContainer: {
        display: "flex",
        width: "100%",
        alignItems: "center",
      },
      nameText: {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 1,
        lineBreak: "anywhere",
        mr: 1,
      },
      sharedText: {
        color: stores.ui.themeColors.textSecondary,
      },
    };

    return styles;
  }
}
