/* @flow */

import { computed } from "mobx";

import stores from "../../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      titleContainer: {
        display: "flex",
        pl: 2,
        pr: 1,
        pt: 2,
        alignItems: "center",
      },
      titleText: {
        fontWeight: "bold",
      },
      divider: {
        flex: 1,
        height: 1.1,
        bgcolor: stores.ui.themeColors.divider,
        ml: 1,
      },
      addButton: {},
      documentsList: {
        flex: 1,
        overflow: "auto",
      },
    };

    return styles;
  }
}
