import React from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { observer } from "mobx-react";

import stores from "../../../stores";
import i18n from "../../../data/i18n";

import styles from "./styles";

type Props = {
  onCancelSubscriptionPress: () => any;
};

type State = {
  isLoading: boolean;
};

@observer
export default class CancelSubscriptionView extends React.Component<
  Props,
  State
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    const { onCancelSubscriptionPress } = this.props;
    const { isLoading } = this.state;

    let subscriptionPackageName = "";

    if (
      stores.userStore.currentUser.subscriptions &&
      stores.userStore.currentUser.subscriptions.length > 0
    ) {
      subscriptionPackageName =
        stores.userStore.currentUser.subscriptions[0].plan.interval === "month"
          ? "monthly"
          : "yearly";
    }

    return (
      <Box sx={styles.styleSheet.mainContainer}>
        <Typography variant="body1" sx={styles.styleSheet.title}>
          {i18n.upgradeModal.cancelSubscription}
        </Typography>

        <Typography variant="body2" sx={styles.styleSheet.priceInfoMessage}>
          {i18n.formatString(
            i18n.upgradeModal.cancelInfo,
            subscriptionPackageName
          )}
        </Typography>

        {isLoading ? (
          <CircularProgress size={30} sx={styles.styleSheet.progress} />
        ) : (
          <Button
            sx={styles.styleSheet.payNowButton}
            color="secondary"
            onClick={() => {
              this.setState({ isLoading: true });

              onCancelSubscriptionPress();
            }}
          >
            {i18n.upgradeModal.cancelSubscription}
          </Button>
        )}

        <Box sx={styles.styleSheet.bottomButtonsContainer}>
          <Button
            sx={styles.styleSheet.backButton}
            color="secondary"
            onClick={() => (stores.userStore.isPurchaseModalOpened = false)}
          >
            {i18n.common.close}
          </Button>
        </Box>
      </Box>
    );
  }
}
