import React from "react";
import { Box, Grid } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";

export const TestimonialsCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: "Sahin Boydas",
      image: require(`../../assets/SahinBoydasPP.jpg`),
      content:
        "Level-up your reading game! This AI translation app is a game-changer. Articles used to take forever, now they're devoured in minutes. Unlock global knowledge – it's your superpower!",
      title: "Head of RemoteTeam at Gusto",
    },
    {
      name: "Dana Loberg",
      image: require(`../../assets/DanaLobergPP.jpeg`),
      content:
        "Goodbye language barrier! This app unlocks articles and saves me time, making complex topics clear in seconds.",
      title: "Founder and CEO of Leo AR",
    },
  ];

  return (
    <Box
      className="slider-container"
      sx={{
        mt: 10,
        mb: 10,

        width: "100%", // Adjust this if you want the container to have a specific width

        maxWidth: 400,
        "@media (max-width: 800px)": {
          maxWidth: 350,
          width: "90%",
          px: 1,
        },

        "@media (max-width: 400px)": {
          maxWidth: 240,
          width: "90%",
          px: 1,
        },

        // Add additional styling as needed
      }}
    >
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <TestimonialCards
            key={index}
            name={testimonial.name}
            image={testimonial.image}
            content={testimonial.content}
            title={testimonial.title}
          />
        ))}
      </Slider>
    </Box>
  );
};

interface TestimonialCardProps {
  name: string;
  image?: string;
  content: string;
  title: string;
  className?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 300,
    width: 350,
    backgroundColor: "rgb(32,32,32)",
    boxShadow: "0px 0px 5px rgb(32,32,32)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    marginTop: 50,
    paddingBottom: 20,
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      minHeight: 280,
      width: 300,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 260,
      width: 200,
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: 240,
      width: 100,
    },
  },
  avatarContainer: {
    height: 100,
    width: 100,
    position: "absolute",
    top: -50,
    zIndex: 1,
    borderRadius: "100%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: 90,
      width: 90,
      top: -45,
    },
    [theme.breakpoints.down("sm")]: {
      height: 80,
      width: 80,
      top: -40,
    },
    [theme.breakpoints.down("xs")]: {
      height: 70,
      width: 70,
      top: -35,
    },
  },
  avatar: {
    width: "85%",
    height: "85%",
    backgroundColor: "#06232a",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    "& > p": {
      margin: 0,
      fontFamily: "Helvetica, Arial, sans-serif",
      fontSize: "3.5rem",
      fontWeight: 700,
      color: "#fff",
      [theme.breakpoints.down("md")]: {
        fontSize: "3rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
    },
  },
  body: {
    marginTop: 80,
    flex: 1,

    textAlign: "center",
    "& > p": {
      marginTop: 0,
      lineHeight: "20px",
      [theme.breakpoints.down("sm")]: {
        lineHeight: "18px",
      },
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 55,
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 45,
      fontSize: "0.8rem",
    },
  },
  author: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    margin: "15px 0px 10px 0px",
    "& h3": {
      margin: 0,
      color: "#fff",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontSize: "1.3rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.1rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& p": {
      m: 0,
      fontFamily: "Roboto-Medium",

      fontSize: "0.8rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.79rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.78rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.77rem",
      },
    },
  },
}));

const TestimonialCards: React.FC<TestimonialCardProps> = (props) => {
  const classes = useStyle();

  return (
    <Grid
      item
      sx={{ justifyContent: "center" }}
      className={[classes.root, props.className].join(" ")}
    >
      <Grid
        item
        className={[
          classes.avatarContainer,
          "testimonial-avatar-container",
        ].join(" ")}
      >
        {props.image ? (
          <img
            className={[classes.avatar, "testimonial-avatar"].join(" ")}
            src={props.image}
            alt="Avatar"
          />
        ) : (
          <div className={[classes.avatar, "testimonial-avatar"].join(" ")}>
            <p> {props.name[0]} </p>
          </div>
        )}
      </Grid>
      <Grid
        item
        xs={11}
        className={[classes.body, "testimonial-body"].join(" ")}
      >
        <p>{props.content}</p>
      </Grid>
      <Grid item className={[classes.author, "testimonial-author"].join(" ")}>
        <Grid item>
          <h3> {props.name} </h3>
        </Grid>
        <Grid item>
          <p> {props.title} </p>
        </Grid>
      </Grid>
    </Grid>
  );
};
