/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";

export default async function deleteSharedDocument(
  id: number
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      deleteSharedDocument(
        id: ${id}
      ) 
    }
  `;

  const deleteSharedDocumentMutationResult = await client.mutate({ mutation });

  const result = get(
    deleteSharedDocumentMutationResult,
    "data.deleteSharedDocument",
    null
  );

  return result !== null && result[0] > 0;
}
