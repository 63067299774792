/* @flow */

import { computed } from "mobx";
import stores from "../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      translationHistoryMenuItem: {
        whiteSpace: "normal",
        bgColor: "rgb(18,18,18)",
      },
      translationHistoryContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: 500,
        minHeight: 60,
      },
      translationHistoryText: {
        color: stores.ui.themeColors.textSecondary,
        fontStyle: "italic",
        fontSize: 14,
      },
      translationHistoryPassTimeInfoText: {
        color: stores.ui.themeColors.textSecondary,
        fontSize: 10,
        textAlign: "center",
        mt: 1,
      },
      menu: {
        display: "inline-block",
        overflow: "visible",
        mt: 1.5,
        bgcolor: stores.ui.themeColors.selectedBackground,
        "&:before": {
          content: '""',
          display: "inline-block",
          position: "absolute",
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: stores.ui.themeColors.selectedBackground,
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
      menuItem: {
        ml: -1,
      },
      nestedMenuItem: {},
    };

    return styles;
  }
}
