import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "mobx-react";

import "./helpers/Functions";
import ApolloClientHelper from "./helpers/ApolloClientHelper";
import { App } from "./app";
import { Auth0ProviderWithHistory } from "./helpers/auth0-provider-with-history";
import "./styles/styles.css";
import RootStore from "./stores/RootStore";
// import ShortcutHelper from "./helpers/ShourtcutHelper";

ApolloClientHelper.init();
// ShortcutHelper.init();

const client = ApolloClientHelper.getApolloClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider rootStore={RootStore}>
    <React.StrictMode>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </ApolloProvider>
    </React.StrictMode>
  </Provider>
);
