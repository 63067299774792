import { useEffect, useState } from "react";
import { useWhisper } from "@chengsokdara/use-whisper";
import stores from "../../../stores";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import MicNoneIcon from "@mui/icons-material/MicNone";
import CreateIcon from "@mui/icons-material/Create";
import MicOffIcon from "@mui/icons-material/MicOff";
import { Create } from "@mui/icons-material";

const WhisperCall = () => {
  const {
    recording,
    speaking,
    transcribing,
    transcript,
    startRecording,
    stopRecording,
  } = useWhisper({
    apiKey: stores.userStore.currentUser.openai_api_key,
    streaming: true,
    removeSilence: true,
    autoTranscribe: true,
    onDataAvailable(data) {
      // console.log("onDataAvailable", data);
    },
    // @ts-ignore
    // onTranscribe(blob) {
    //   console.log("onDataAvailable", blob);
    // },
    timeSlice: 3_000,
    whisperConfig: {
      temperature: 0.0,
      language: stores.documentStore.selectedDocumentItem?.translation_language,
      prompt: "previous conversation",
    },
  });

  const [isRecording, setIsRecording] = useState(false);
  const [hasSpoken, setHasSpoken] = useState(false);

  const [transcriptText, setTranscriptText] = useState("");
  const [storedBlockId, setStoredBlockId] = useState<number>(1);

  const toggleRecording = () => {
    setTranscriptText((prevText) => prevText + transcript.text + <br />);
    if (checkEditorText()) setTranscriptText("");
    setEditorTextStart();
    if (recording) {
      stopRecording();

      setHasSpoken(false);
    } else {
      transcript.text = "";
      setEditorTextStart();
      handleBlockId();

      startRecording();
    }

    setTranscriptText((prevText) => prevText + transcript.text + "\n");
    setIsRecording(!isRecording);
  };

  useEffect(() => {
    if (!transcribing && isRecording && hasSpoken) {
      handleTranscriptResult(transcript.text);
    }
  }, [transcribing, isRecording, transcript.text, hasSpoken]);

  useEffect(() => {
    if (speaking) setHasSpoken(true);
  }, [speaking]);

  useEffect(() => {
    if (recording)
      setTranscriptText((prevText) => prevText + transcript.text + "\n");
    else setTranscriptText((prevText) => prevText + transcript.text + "\n");
  }, [recording]);

  useEffect(() => {
    if (storedBlockId) {
      const doc_id = stores.documentStore.selectedDocumentItem!.id;
      const editorElement = document.querySelector(
        `#editorjs-document-${doc_id} > div > div.codex-editor__redactor > div:nth-child(${storedBlockId}) > div > div:nth-child(1) > div:nth-child(1) > div`
      );
      if (editorElement) {
        setTranscriptText(editorElement.textContent || ""); // Set to empty string if no text
      }
    }
  }, [storedBlockId]);

  const setEditorTextStart = () => {
    const doc_id = stores.documentStore.selectedDocumentItem!.id;
    const editorElement = document.querySelector(
      `#editorjs-document-${doc_id} > div > div.codex-editor__redactor > div:nth-child(${storedBlockId}) > div > div:nth-child(1) > div:nth-child(1) > div`
    );
    if (editorElement) {
      if (editorElement.textContent) {
        setTranscriptText(editorElement.textContent || ""); // Set to empty string if no text
      }
    }
  };

  const checkEditorText = () => {
    const doc_id = stores.documentStore.selectedDocumentItem!.id;
    const editorElement = document.querySelector(
      `#editorjs-document-${doc_id} > div > div.codex-editor__redactor > div:nth-child(${storedBlockId}) > div > div:nth-child(1) > div:nth-child(1) > div`
    );
    if (editorElement) {
      return true;
    } else return false;
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      const doc_id = stores.documentStore.selectedDocumentItem!.id;
      const editorElement = document.querySelector(
        `#editorjs-document-${doc_id} > div > div.codex-editor__redactor > div:nth-child(${storedBlockId}) > div > div:nth-child(1) > div:nth-child(1) > div`
      );
      // componentDidMount equivalent
      if (editorElement) {
        if (editorElement.textContent) {
          setTranscriptText(editorElement.textContent);
          // console.log("Transcript text on mount:", editorElement.textContent);
        }
      }
    }, 1000); // 1 second delay

    return () => clearTimeout(delay);
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    if (!transcribing && !recording && !speaking && transcript.text !== "") {
      transcript.text = "";
    }
  }, [transcribing, transcript, speaking, recording]);

  const handleBlockId = () => {
    let newStoredBlockId = 1;

    const selectedDocumentItem = stores.documentStore.selectedDocumentItem;
    const selectedDocumentContent =
      stores.documentStore.selectedDocumentContent;

    if (!selectedDocumentItem || !selectedDocumentContent) return;

    const doc_id = selectedDocumentItem.id;
    const blocks = selectedDocumentContent.blocks;

    if (!blocks || blocks.length === 0) return;

    for (let i = 0; i < blocks.length; i++) {
      if (!blocks[i].data.text) {
        newStoredBlockId = i + 1;
        break;
      }
    }

    // If there are no empty blocks, set newStoredBlockId to the last block ID plus one
    if (newStoredBlockId === 1 && blocks.length > 0) {
      newStoredBlockId = blocks.length + 1;
    }

    if (blocks.length === 0) newStoredBlockId = 1;

    for (let i = 1; i <= blocks.length; i++) {
      const editorElement = document.querySelector(
        `#editorjs-document-${doc_id} > div > div.codex-editor__redactor > div:nth-child(${i}) > div > div:nth-child(1) > div:nth-child(1) > div`
      );
      if (!editorElement?.textContent) {
        // console.log("Editor element not found:", !editorElement?.innerHTML);
        newStoredBlockId = i;
        break;
      }
    }

    const editorElementNew = document.querySelector(
      `#editorjs-document-${doc_id} > div > div.codex-editor__redactor > div:nth-child(${newStoredBlockId}) > div > div:nth-child(1) > div:nth-child(1) > div`
    );
    if (!editorElementNew) newStoredBlockId--;

    setStoredBlockId(newStoredBlockId);
  };

  // Function to handle writing transcript to the editor
  const handleTranscriptResult = async (chatResult: any) => {
    // Append "\n" to the end of chatResult
    // chatResult += "\n";

    // console.log(storedBlockId);

    const doc_id = stores.documentStore.selectedDocumentItem!.id;
    const editorElement = document.querySelector(
      `#editorjs-document-${doc_id} > div > div.codex-editor__redactor > div:nth-child(${storedBlockId}) > div > div:nth-child(1) > div:nth-child(1) > div`
    );
    const docHistory = await stores.documentStore.getCurrentDocumentHistoryItem(
      stores.documentStore.selectedDocumentItem!
    );
    if (editorElement) {
      editorElement.innerHTML = transcriptText + chatResult;
      // console.log(editorElement);
      await stores.documentStore.setDocumentHistoryItem(
        stores.documentStore.selectedDocumentItem!,
        docHistory,
        stores.documentStore.selectedDocumentContent!
        // {
        //   blocks: [
        //     {
        //       type: "paragraph",
        //       data: {
        //         text: transcriptText + chatResult,
        //       },
        //     },
        //   ],
        // }
      );
    } else {
      // console.error("Editor element not found");
    }
  };

  const buttonIcon = isRecording ? <MicOffIcon /> : <KeyboardVoiceIcon />;
  const buttonColor = isRecording ? "red" : "white";

  return (
    <div>
      {/* <p>Normal</p>
      <p>Recording: {recording.toString()}</p>
      
      <p>Speaking: {speaking.toString()}</p>
      <p>Transcribing: {transcribing.toString()}</p>
      <p>Normal: {transcript.text}</p>*/}
      <Tooltip title="Toggle Writing Voice">
        <IconButton
          onClick={toggleRecording}
          style={{ color: buttonColor, gap: -5 }}
        >
          {buttonIcon}
          <CreateIcon style={{ left: 5 }} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default WhisperCall;
