/* @flow */

import keys from "../../constants/keys";
import { StripeProfile } from "../../models/User";

export default async function createStripeProfile(
  email: string
): Promise<StripeProfile> {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    };

    fetch(`${process.env.REACT_APP_API_URL}/create-customer`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const stripeProfile: StripeProfile = data;

          resolve(stripeProfile);
        } else {
          reject();
        }
      });
  });
}
