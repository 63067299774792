import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import * as React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UncheckCircleIcon from "@mui/icons-material/RadioButtonUnchecked";
import { observer } from "mobx-react";

import i18n from "../../../data/i18n";
import stores from "../../../stores";
import { Product } from "../../../models/Product";
import { getProducts } from "../../../helpers/api";
import { SubscriptionCoupon } from "../../../models/SubscriptionCoupon";

import styles from "./styles";

type Props = {
  onNextPress: () => any;
  onBackPress: () => any;
  onSelectedProductChange: (selectedProduct: Product) => any;
  onSubscriptionCouponSelect: (subscriptionCoupon: SubscriptionCoupon) => any;
  selectedSubscriptionCoupon?: SubscriptionCoupon;
};

type State = {
  selectedProduct?: Product;
  products: Product[];
  couponCode: string;
};

@observer
export default class PackagesView extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      products: [],
      couponCode: "",
    };
  }

  componentWillMount() {
    this.getProducts();
  }

  private getProducts = async () => {
    const { onSelectedProductChange } = this.props;

    const products = await getProducts();

    this.setState({
      products: products.filter((product) => product.active !== false),
      selectedProduct: products[0],
    });

    onSelectedProductChange(products[0]);
  };

  private handleProductChange = (product: Product) => {
    const { onSelectedProductChange } = this.props;

    this.setState({ selectedProduct: product });

    onSelectedProductChange(product);
  };
  
  private handlePayClick = () => {
    const { selectedProduct } = this.state;
    if (!selectedProduct) return;
    const monthlyLink = process.env.REACT_APP_STRIPE_MONTHLY_LINK;
    const yearlyLink = process.env.REACT_APP_STRIPE_YEARLY_LINK;
    const isYearly = selectedProduct.name.includes("Yearly");
    const url = isYearly ? yearlyLink : monthlyLink;    
    // Redirect user
    window.open(url, '_blank');
  };

  private renderProductItem = (
    product: Product,
    selectedSubscriptionCoupon?: SubscriptionCoupon
  ) => {
    const { selectedProduct } = this.state;

    const isSelected =
      selectedProduct && selectedProduct.id === product.id ? true : false;

    const percentOff = selectedSubscriptionCoupon
      ? selectedSubscriptionCoupon.percent_off
      : 100;

    const price = (
      product.name.includes("Yearly")
        ? Math.floor(
            (((product.prices[0].unit_amount / 1200) * percentOff) / 100) * 100
          ) / 100
        : ((product.prices[0].unit_amount / 100) * percentOff) / 100
    ).toFixed(2);

    return (
      <Box
        key={product.id}
        sx={{
          ...styles.styleSheet.purchasePackageItem,
          ...{
            bgcolor: isSelected ? stores.ui.themeColors.primary : "transparent",
          },
        }}
        onClick={() => this.handleProductChange(product)}
      >
        {isSelected ? <CheckCircleIcon /> : <UncheckCircleIcon />}

        <Box sx={styles.styleSheet.priceTitleContainer}>
          <Typography variant="body2" sx={styles.styleSheet.priceTitle}>
            {product.name}
          </Typography>

          {product.name.includes("Yearly") ? (
            <Typography variant="caption" sx={styles.styleSheet.priceSave}>
              {i18n.formatString(i18n.upgradeModal.savePercent, "20%")}
            </Typography>
          ) : null}
        </Box>

        <Typography variant="h6" sx={styles.styleSheet.price}>
          {`$${price}`}
        </Typography>

        <Typography variant="caption" sx={styles.styleSheet.pricePeriod}>
          {`/ ${i18n.common.month.toLowerCase()}`}
        </Typography>
      </Box>
    );
  };

  render() {
    const { onBackPress, selectedSubscriptionCoupon } = this.props;
    const { products } = this.state;

    return (
      <Box sx={styles.styleSheet.mainContainer}>
        <Typography variant="body1" sx={styles.styleSheet.title}>
          {i18n.upgradeModal.goUnlimited}
        </Typography>

        <Box sx={styles.styleSheet.packageContainer}>
          {products.length > 0 ? (
            products.map((item) =>
              this.renderProductItem(item, selectedSubscriptionCoupon)
            )
          ) : (
            <CircularProgress sx={styles.styleSheet.progress} />
          )}
        </Box>

        <Typography variant="body2" sx={styles.styleSheet.description}>
          {i18n.upgradeModal.freeInfo}
        </Typography>

        <Box style={styles.styleSheet.bottomButtonsContainer}>
        <Button
            sx={styles.styleSheet.backButton}
            color="secondary"
            onClick={onBackPress}
          >
            {i18n.common.back}
          </Button>

          <Button
            sx={styles.styleSheet.nextButton}
            color="secondary"
            onClick={this.handlePayClick}
          >
            {i18n.common.pay}
          </Button>
        </Box>
      </Box>
    );
  }
}
