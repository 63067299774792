import React from "react";
import {
  List,
  ListItem,
  InputBase,
  Menu,
  Box,
  Divider,
  Button,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import SwitchAccessShortcutIcon from "@mui/icons-material/SwitchAccessShortcut";
import CloseIcon from "@mui/icons-material/Close";

import i18n from "../../data/i18n";
import { Shortcut } from "../../models/Shortcut";
import keys from "../../constants/keys";
import Analytics from "../../helpers/Analytics";

import styles from "./styles";

interface Props {}

interface State {
  isModalOpen: boolean;
  searchQuery: string;
  anchorEl: null | HTMLElement;
}

export default class ShortcutList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
      searchQuery: "",
      anchorEl: null,
    };
  }

  private handleListItemClick = (item: Shortcut) => {};

  private handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: event.target.value });
  };

  private handleSearchClearButtonClick = () => {
    this.setState({ searchQuery: "" });
  };

  private handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    Analytics.track("shortcuts_button_clicked", "");

    this.setState({ anchorEl: event.currentTarget, isModalOpen: true });
  };

  private handleListClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  render() {
    const { searchQuery, anchorEl, isModalOpen } = this.state;

    return (
      <Box sx={styles.styleSheet.container}>
        <Button
          startIcon={<SwitchAccessShortcutIcon />}
          sx={styles.styleSheet.modalButton}
          onClick={this.handleMenuOpen}
        >
          {i18n.common.shortcuts}
        </Button>

        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
        >
          <Paper sx={styles.styleSheet.searchBar}>
            <InputBase
              autoFocus
              sx={{ ml: 1, flex: 1, fontSize: 14 }}
              placeholder={i18n.searchComponent.search}
              inputProps={{ "aria-label": i18n.searchComponent.search }}
              onChange={this.handleSearchChange}
              value={searchQuery}
            />

            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="directions"
              onClick={this.handleSearchClearButtonClick}
            >
              <CloseIcon />
            </IconButton>
          </Paper>

          <Divider sx={styles.styleSheet.divider} />

          <List
            sx={styles.styleSheet.shortcutListContainer}
            component="nav"
            aria-label="shortcut list"
            onClick={this.handleListClick}
          >
            {keys.shortcutList
              .filter((item) =>
                item.name.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((item) => (
                <ListItem
                  key={item.id}
                  onClick={() => this.handleListItemClick(item)}
                  sx={styles.styleSheet.itemContainer}
                >
                  <Typography sx={styles.styleSheet.itemLabel} variant="body2">
                    {item.name}
                  </Typography>

                  <Box sx={styles.styleSheet.itemShortcutContainer}>
                    <Typography
                      sx={styles.styleSheet.itemShortcut}
                      variant="body1"
                    >
                      {item.shortcut}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
          </List>
        </Menu>
      </Box>
    );
  }
}
