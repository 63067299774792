import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { Language } from "../models/Language";
import keys from "../constants/keys";

type Props = {
  selectedLanguage?: Language;
  label: string;
  onLanguageSelect: (language?: Language) => any;
};

type State = {};

export default class LanguageSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

 componentDidMount() {
  const mostUsedLanguages = this.getMostUsedLanguages();

  const updatedLanguages = [...mostUsedLanguages, ...keys.languages];
  const filteredLanguages = updatedLanguages.filter((lang, index, self) =>
    index === self.findIndex((t) => (
        t.code === lang.code && t.country === lang.country
    ))
);
  keys.languages = filteredLanguages;
}
  private getMostUsedLanguages = (): Language[] => {
    let languageUsage = localStorage.getItem("languageUsage");
    let usage: Record<string, number> = languageUsage ? JSON.parse(languageUsage) : {};

    const mostUsedLanguages: string[] = Object.entries(usage)
      .sort((a, b) => b[1] - a[1]) 
      .slice(0, 3) 
      .map((entry) => entry[0]); 

      const languages: (Language | undefined)[] = mostUsedLanguages.map((langCode) =>
      keys.languages.find((language) => language.code === langCode)
    );
    return languages.filter((language) => language !== undefined) as Language[];
  };
  private onChange = (option: any) => {
    const { onLanguageSelect } = this.props;

    if (option) {
      const language = keys.languages.find((item) => item.code === option.code);

      if (language) onLanguageSelect(language);
    } else {
      onLanguageSelect(undefined);
    }
  };

  private renderOption = (props: any, option: Language) => {
    return (
      <Box
        component="li"
        sx={{ "& > img": { mr: 2, flexShrink: 0 }, fontSize: 12 }}
        {...props}
      >
        <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${option.country.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${option.country.toLowerCase()}.png 2x`}
          alt=""
        />
        {`${option.title}`}
      </Box>
    );
  };

  render() {
    const { label, selectedLanguage } = this.props;

    return (
      <Autocomplete
        sx={{ flex: 1 }}
        options={keys.languages}
        autoHighlight
        getOptionLabel={(option) => option.title}
        onChange={(event, option) => this.onChange(option)}
        value={selectedLanguage || null} // Eğer selectedLanguage undefined ise null değeri atanır
        renderOption={this.renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            size={"small"}
            helperText={label}
            required={true}
            inputProps={{
              ...params.inputProps,
              style: {
                fontSize: 14,
              },
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
            InputLabelProps={{
              style: {
                fontSize: 12,
              },
            }}
            FormHelperTextProps={{
              style: {
                margin: 2,
                padding: 0,
                fontSize: 12,
                textAlign: "center",
              },
            }}
          />
        )}
      />
    );
  }
}
