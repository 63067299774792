import { Box, Dialog, DialogTitle } from "@mui/material";
import * as React from "react";
import { CannyProvider, CannyWidget } from "react-canny";

import keys from "../../constants/keys";
import i18n from "../../data/i18n";
import stores from "../../stores";

type Props = {
  open: boolean;
  onClose: () => void;
};

type State = {};

export default class FeedbackModal extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    const { open, onClose } = this.props;

    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>{i18n.common.sendFeedback}</DialogTitle>

        <Box sx={{ width: 500, height: 500, p: 1 }}>
          <CannyProvider appId={keys.canny.appId}>
            <CannyWidget
              basePath="/"
              user={{
                id: stores.userStore.currentUser.id,
                email: stores.userStore.currentUser.email,
              }}
              boardToken={keys.canny.boardToken}
              ssoToken={keys.canny.ssoToken}
            />
          </CannyProvider>
        </Box>
      </Dialog>
    );
  }
}
