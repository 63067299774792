import React from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import i18n from "../../data/i18n";
import { SortType } from "../../helpers/Enum";
import Analytics from "../../helpers/Analytics";

import styles from "./styles";

interface Props {
  data: SortType[];
  selectedItem: SortType;
  onSort: (item: SortType) => void;
}

interface State {
  isOpen: boolean;
  anchorEl: any;
}

export default class SortButtonListComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      anchorEl: null,
      isOpen: false,
    };
  }

  private handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    Analytics.track("sort_button_clicked", "");

    this.setState({ anchorEl: event.currentTarget, isOpen: true });
  };

  private handleMenuClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { data, selectedItem, onSort } = this.props;
    const { anchorEl, isOpen } = this.state;

    return (
      <Box>
        <Button
          variant="text"
          startIcon={<ImportExportIcon />}
          sx={styles.styleSheet.sortButton}
          onClick={this.handleSortClick}
        >
          {i18n.sortableComponent.sort}
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={this.handleMenuClose}
        >
          {data.map((item, index) => (
            <MenuItem
              key={index}
              selected={selectedItem === item}
              onClick={() => {
                this.setState({ isOpen: false });
                onSort(item);
              }}
            >
              {item}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  }
}
