import stores from "../../../stores";
import React from 'react';
import { createRoot } from 'react-dom/client';
import IconButton from '@mui/material/IconButton';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { toast } from 'react-toastify';
import { replace } from 'lodash';

export default class ActionTool {
  static get isInline() {
    return true;
  }

  constructor({ api }) {
    this.api = api;
    this.menuAnchorEl = null;
    this.menuVisible = false;
    this.alternatives = [];
    this.menuContainer = document.createElement('div');
    document.body.appendChild(this.menuContainer);
    this.menuRoot = createRoot(this.menuContainer);

    this.renderMenu = this.renderMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.replaceSelectedTextWithAlternative = this.replaceSelectedTextWithAlternative.bind(this);
    this.handleAlternativeOptionClick = this.handleAlternativeOptionClick.bind(this);
    this.handleOptionLengthenClick = this.handleOptionLengthenClick.bind(this);
    this.handleOptionShortenClick = this.handleOptionShortenClick.bind(this);
    this.handleOptionImproveClick = this.handleOptionImproveClick.bind(this);
    this.handleOptionFixClick = this.handleOptionFixClick.bind(this);
    this.handleOptionEnhanceClick = this.handleOptionEnhanceClick.bind(this);
  }

  render() {
    const container = document.createElement('div');
    const buttonRoot = createRoot(container);
    buttonRoot.render(
      <IconButton
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={() => this.toggleMenu()}
        style={{ color: '#fff', backgroundColor: '#36474f', margin: '0 4px', border: '1px solid #36474f', padding: '4px' }}
        size="small"
      >
        <AutoAwesome style={{ fontSize: '1rem' }} />
      </IconButton>
    );
    return container;
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
    this.renderMenu();
  }

  handleMouseEnter(event) {
    this.menuAnchorEl = event.currentTarget;
  }

  renderMenu() {
    const isDisabled = stores.actionStore.selectedBlockType === "TranslationText";
    const isSingleWord = stores.actionStore.selectedText && !stores.actionStore.selectedText.includes(' ');
    const items = this.alternatives.length > 0 ? this.alternatives.map((alternative, index) => (
      <MenuItem key={index} onClick={() => this.replaceSelectedTextWithAlternative(alternative)} disabled={isDisabled}>
        {alternative}
      </MenuItem>
    )) : [
      <MenuItem key="alternatives" onClick={this.handleAlternativeOptionClick} disabled={isDisabled}>Alternatives</MenuItem>,
      <MenuItem key="lengthen" onClick={this.handleOptionLengthenClick} disabled={isDisabled}>Lengthen</MenuItem>,
      <MenuItem key="shorten" onClick={this.handleOptionShortenClick} disabled={isDisabled || isSingleWord}>Shorten</MenuItem>,
      <MenuItem key="improve" onClick={this.handleOptionImproveClick} disabled={isDisabled}>Improve</MenuItem>,
      <MenuItem key="fix" onClick={this.handleOptionFixClick} disabled={isDisabled}>Fix Grammar</MenuItem>,
      <MenuItem key="enhance" onClick={this.handleOptionEnhanceClick} disabled={isDisabled}>Enhance Language</MenuItem>
    ];

    const optionsMenu = (
      <ClickAwayListener onClickAway={this.closeMenu}>
        <Popover
          open={this.menuVisible}
          anchorEl={this.menuAnchorEl}
          onClose={this.closeMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{ style: { backgroundColor: '#212121', color: 'white' } }}
        >
          {items}
        </Popover>
      </ClickAwayListener>
    );

    this.menuRoot.render(optionsMenu);
  }

  closeMenu() {
    if (this.menuVisible) {
      this.menuVisible = false;
      this.renderMenu(); // Re-render the menu to update its open state
    }
  }


  async replaceSelectedTextWithAlternative(alternative) {
    const { selectedText, selectedAreaWholeText, selectedBlockType, selectedBlockId } = stores.actionStore;
    if (!selectedText || !selectedAreaWholeText) {
      toast.error("No selected text or document content available for replacement.");
      return;
    }

    const startIndex = selectedAreaWholeText.indexOf(selectedText);
    if (startIndex === -1) {
      toast.error("Selected text is not found in the full text.");
      return;
    }

    const newText = replace(selectedAreaWholeText, selectedText, alternative);
    try {
      if (selectedBlockType === "OriginalText") {
        const updateResult = await stores.actionStore.regenerateAction(newText);
        if (!updateResult) {
          toast.error("Update failed: The translation item could not be updated.");
          window.location.reload();
        } else {
          toast.success("Translation updated successfully.");
        }
      } else {
        await stores.actionStore.regenerateAction(newText);
        toast.success("Content regenerated successfully.");
      }
      this.closeMenu();
    } catch (error) {
      toast.error(`Error updating text: ${error.message}`);
    }
  }

  handleAlternativeOptionClick = async () => {
    const selectedText = stores.actionStore.selectedText;
    if (selectedText) {
      try {
        const alternatives = await stores.documentStore.getAlternativeTextSuggestions(selectedText);
        this.alternatives = alternatives;
        this.menuVisible = true;
        this.renderMenu();
      } catch (error) {
        toast.error("Error fetching alternatives: " + error.message);
        this.closeMenu();
      }
    }
  }

  async handleOptionLengthenClick() {
    const selectedText = stores.actionStore.selectedText;
    if (selectedText) {
      try {
        const alternatives = await stores.documentStore.getLongerText(selectedText);
        this.alternatives = alternatives;
        this.menuVisible = true;
        this.renderMenu();
      } catch (error) {
        toast.error("Error fetching longer alternatives: " + error.message);
        this.closeMenu();
      }
    }
  }

  async handleOptionShortenClick() {
    const selectedText = stores.actionStore.selectedText;
    if (selectedText) {
      try {
        const alternatives = await stores.documentStore.getShorterText(selectedText);
        this.alternatives = alternatives;
        this.menuVisible = true;
        this.renderMenu();
      } catch (error) {
        toast.error("Error fetching shorter alternatives: " + error.message);
        this.closeMenu();
      }
    }
  }

  async handleOptionImproveClick() {
    const selectedText = stores.actionStore.selectedText;
    if (selectedText) {
      try {
        const alternatives = await stores.documentStore.getImprovedText(selectedText);
        this.alternatives = alternatives;
        this.menuVisible = true;
        this.renderMenu();
      } catch (error) {
        toast.error("Error fetching improved text alternatives: " + error.message);
        this.closeMenu();
      }
    }
  }

  async handleOptionFixClick() {
    const selectedText = stores.actionStore.selectedText;
    if (selectedText) {
      try {
        const alternatives = await stores.documentStore.getFixedText(selectedText);
        this.alternatives = alternatives;
        this.menuVisible = true;
        this.renderMenu();
      } catch (error) {
        toast.error("Error fetching grammar fixed alternatives: " + error.message);
        this.closeMenu();
      }
    }
  }
  async handleOptionEnhanceClick() {
    const selectedText = stores.actionStore.selectedText;
    if (selectedText) {
      try {
        const alternatives = await stores.documentStore.getEnhancedText(selectedText);
        this.alternatives = alternatives;
        this.menuVisible = true;
        this.renderMenu();
      } catch (error) {
        toast.error("Error fetching language enhanced alternatives: " + error.message);
        this.closeMenu();
      }
    }
  }

  surround(range) {
    if (this.state) {
      this.unwrap(range);
      return;
    }

    this.wrap(range);
  }

  wrap(range) {
    const tempElement = document.createElement(this.tag);
    tempElement.appendChild(range.cloneContents());

    // const selectedText = tempElement.innerHTML;
    // const wholeText = range.commonAncestorContainer.wholeText;
    let anchorEl = undefined;

    if (
      document.getElementsByClassName("ce-inline-toolbar--showed").length > 0
    ) {
      anchorEl = document.getElementsByClassName(
        "ce-inline-toolbar--showed"
      )[0];
    }

    stores.actionStore.setSelectionBlockId(
      anchorEl,
      this.api.blocks.getCurrentBlockIndex()
    );

    this.api.inlineToolbar.close();
  }

  unwrap(range) {}

  checkState() {
    const mark = this.api.selection.findParentTag(this.tag);

    this.state = !!mark;

    if (this.state) {
      // this.showActions(mark);
    } else {
      // this.hideActions();
    }

    let anchorEl = undefined;

    if (
      document.getElementsByClassName("ce-inline-toolbar--showed").length > 0
    ) {
      anchorEl = document.getElementsByClassName(
        "ce-inline-toolbar--showed"
      )[0];
    }

    stores.actionStore.setSelectionBlockId(
      anchorEl,
      this.api.blocks.getCurrentBlockIndex()
    );
  }

  renderActions() {
    this.colorPicker = document.createElement("input");
    this.colorPicker.type = "color";
    this.colorPicker.value = "#f5f1cc";
    this.colorPicker.hidden = true;

    return this.colorPicker;
  }
}
