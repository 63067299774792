import { Fragment, useState, useEffect } from "react";
import Faqs from "../../components/faq/Faqs"; // Bu ve diğer importlar örnek olarak verilmiştir, gerçek yollarınız farklı olabilir
import { Container } from "@mui/material";
import MainHeader from "../HomePage/MainHeader";
import FaqHeader from "./FaqHeader";
import algoliasearch from "algoliasearch/lite";
import Footer from "../../components/Footer";

const searchClient = algoliasearch(
  "9Q2V2O43P4",
  "38b5cd60e41e2b49bdc72ff3d7c3878f"
);

interface FaqItem {
  objectID: string;
  category: string;
  categoryIcon: string;
  subcategory: string;
  question: string;
  answer: string;
}

interface FaqQuestionAnswer {
  id: string;
  question: string;
  answer: string;
}

interface FaqCategory {
  id: string;
  title: string;
  icon: string;
  subtitle: string;
  qandA: FaqQuestionAnswer[];
}

interface TransformedFaqData {
  faqData: {
    [key: string]: FaqCategory;
  };
}

function transformFaqData(inputData: FaqItem[]): TransformedFaqData {
  const result: TransformedFaqData = { faqData: {} };

  inputData.forEach((item) => {
    const { category, categoryIcon, subcategory, objectID, question, answer } =
      item;
    const categoryId = category
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/&/g, "and");

    if (!result.faqData[categoryId]) {
      result.faqData[categoryId] = {
        id: categoryId,
        title: category,
        icon: categoryIcon,
        subtitle: subcategory,
        qandA: [],
      };
    }

    result.faqData[categoryId].qandA.push({
      id: objectID,
      question,
      answer,
    });
  });

  return result;
}

const FAQ = () => {
  const data = {
    faqData: {
      generalQuestions: {
        id: "general-questions",
        title: "General Questions",
        icon: "mdi:help-circle-outline",
        subtitle: "Learn more about Lingoedit",
        qandA: [
          {
            id: "what-is-lingoedit",
            question: "What is Lingoedit?",
            answer:
              "Lingoedit is an AI-powered translation and editing tool that helps individuals and businesses communicate effortlessly in multiple languages. It provides accurate, context-aware translations, grammar, and spelling corrections, and editing features to refine and polish written content.",
          },
          {
            id: "supported-languages",
            question: "Which languages does Lingoedit support?",
            answer:
              "Lingoedit supports over 30 languages, including major languages such as English, Spanish, French, German, Mandarin, Arabic, and many more, catering to a diverse global audience.",
          },
          {
            id: "translation-accuracy",
            question: "How does Lingoedit ensure translation accuracy?",
            answer:
              "Lingoedit uses state-of-the-art AI technology, incorporating natural language processing and machine learning algorithms to ensure high-quality translations. It also allows users to choose from multiple versions of a translation to best fit the context and intended meaning.",
          },
        ],
      },
      usageAndFeatures: {
        id: "usage-and-features",
        title: "Usage and Features",
        icon: "mdi:book-open-page-variant-outline",
        subtitle: "Maximizing Lingoedit's capabilities",
        qandA: [
          {
            id: "block-by-block-translation",
            question: "How do I use the block-by-block translation feature?",
            answer:
              "To use the block-by-block translation feature, simply paste or type the text into Lingoedit, and it will automatically segment the content into manageable blocks. You can then translate each block individually, giving you greater control over the translation process.",
          },
          {
            id: "save-translations-cloud",
            question: "Can I save my translations in cloud storage?",
            answer:
              "Yes, all your translations can be saved in cloud storage, allowing you to access and edit them from any device, anytime, anywhere.",
          },
          {
            id: "word-limit",
            question: "Is there a word limit on translations and edits?",
            answer:
              "The word limit for translations and edits depends on your subscription plan. Our free plan includes a generous limit suitable for casual use, while our paid plans offer expanded or unlimited word counts for more extensive needs.",
          },
        ],
      },
      pricingAndPlans: {
        id: "pricing-and-plans",
        title: "Pricing and Plans",
        icon: "mdi:tag-multiple",
        subtitle: "Explore our subscription options",
        qandA: [
          {
            id: "free-trial",
            question: "What's included in the free trial?",
            answer:
              "The free trial includes full access to all premium features of Lingoedit for a limited time. This allows you to test out everything from block-by-block translation to cloud storage and custom editing tools.",
          },
          {
            id: "upgrade-plan",
            question: "How can I upgrade my plan?",
            answer:
              "Upgrading your plan is simple. Just visit the 'Pricing' section of your dashboard, select the plan you want to upgrade to, and follow the prompts to complete your purchase.",
          },
          {
            id: "discounts-education-nonprofits",
            question:
              "Are there any discounts for educational institutions or non-profits?",
            answer:
              "Yes, we offer discounted pricing for educational institutions and non-profit organizations. Please contact our sales team for more information and to apply for the discount.",
          },
        ],
      },
      support: {
        id: "support",
        title: "Support",
        icon: "carbon:help-desk",
        subtitle: "Get assistance and support",
        qandA: [
          {
            id: "contact-support",
            question: "How do I contact customer support?",
            answer:
              "You can contact our customer support team by email at support@lingoedit.com, through our in-app chat feature, or by submitting a ticket on our 'Support' page.",
          },
          {
            id: "find-tutorials",
            question: "Where can I find tutorials on using Lingoedit?",
            answer:
              "Tutorials on using Lingoedit can be found on our 'Help Center' page, which includes video guides, step-by-step articles, and tips for getting the most out of our tool.",
          },
          {
            id: "encounter-error",
            question:
              "What should I do if I encounter an error while using Lingoedit?",
            answer:
              "If you encounter an error, please report it to our support team with a description of the issue, the steps leading up to the error, and any relevant screenshots. We're committed to resolving any problems promptly.",
          },
        ],
      },
    },
  };
  const [filteredData, setFilteredData] = useState<TransformedFaqData>({
    faqData: {},
  });
  const [activeTab, setActiveTab] = useState<string>("general-questions");
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Bu useEffect fonksiyonu, searchTerm değiştiğinde çalışacak ve Algolia'dan veri çekecek
  useEffect(() => {
    if (!searchTerm) {
      // Arama terimi boş ise, default veri seti olarak 'data' değerini kullan
      setFilteredData(data);
      return;
    }
    const index = searchClient.initIndex("lingoedit-faq");

    index
      .search<FaqItem>(searchTerm)
      .then(({ hits }) => {
        const transformedData = transformFaqData(hits);
        setFilteredData(transformedData);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [searchTerm]);

  const handleChange = (event: any, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <MainHeader />
      <Container maxWidth="lg">
        <Fragment>
          <FaqHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <Faqs
            data={filteredData}
            activeTab={activeTab}
            handleChange={handleChange}
          />
        </Fragment>
        <div style={{ marginTop: '170px' }}>
          <Footer />
        </div>
      </Container>
    </>
  );
};

export default FAQ;
