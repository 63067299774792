import { observable, action } from "mobx";
import { toast } from "react-toastify";
import ShortUniqueId from "short-unique-id";

import Emitter from "../helpers/EventEmitter";
import {
  checkAPIKey,
  createTranslation,
  getCurrentParagraphTranslation,
  translate,
} from "../helpers/api";
import { RegenerateMode } from "../models/RegenerateMode";
import { BlockType, StringType } from "../helpers/Enum";
import i18n from "../data/i18n";
import keys from "../constants/keys";
import { RegenerateResult } from "../models/RegenerateResult";

import RootStore from "./RootStore";
import stores from ".";

export default class ActionStore {
  @observable selectedText: string | undefined;
  @observable selectedAreaWholeText: string | undefined;
  @observable selectionType: StringType | undefined;
  @observable selectedBlockId: string | undefined;
  @observable selectedBlockType: BlockType | undefined;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(rootStore: RootStore) {}

  @action setSelectionProps(
    selectedText: string | undefined,
    selectedAreaWholeText: string | undefined,
    selectedBlockType: BlockType | undefined
  ) {
    if (stores.documentStore.selectedDocumentContent) {
      this.selectedText = selectedText;

      if (selectedAreaWholeText) {
        this.selectedAreaWholeText = selectedAreaWholeText;
      } else {
        // If selectedAreaWholeText is not provided, use the entire content of the selected block
        const selectedBlockIndex =
          stores.documentStore.selectedDocumentContent.blocks.findIndex(
            (block) => block.id === this.selectedBlockId
          );
        if (selectedBlockIndex !== -1) {
          this.selectedAreaWholeText =
            stores.documentStore.selectedDocumentContent.blocks[
              selectedBlockIndex
            ].data.text;
        }
      }

      this.selectedBlockType = selectedBlockType;

      if (selectedText) {
        this.selectionType = String.typeOf(selectedText);
      } else {
        this.selectionType = undefined;
      }

      stores.regenerateStore.processRegenerateModes();
    }
  }

  @action setSelectionBlockId(anchorEl: Element, blockIndex: number) {
    if (stores.documentStore.selectedDocumentContent) {
      this.selectedBlockId =
        stores.documentStore.selectedDocumentContent.blocks[blockIndex].id;
    }
  }

  @action regenerateAction = async (result: string): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      stores.documentStore.isDocumentsUpdating = true;

      if (
        // this.selectedBlockId &&
        // this.selectedBlockType === BlockType.BlockItem &&
        stores.documentStore.selectedDocumentContent
      ) {
        const selectedBlockIndex =
          stores.documentStore.selectedDocumentContent.blocks.findIndex(
            (item) => item.id === this.selectedBlockId
          );
        // console.log(`selectedBlockIndex: ${selectedBlockIndex}`);

        if (selectedBlockIndex !== -1) {
          stores.documentStore.selectedDocumentContent.blocks[
            selectedBlockIndex
          ].data.text = result;
        }
      } else if (
        this.selectedBlockId &&
        this.selectedBlockType === BlockType.TranslationBlockItem
      ) {
        const translationBlockItem = await getCurrentParagraphTranslation(
          this.selectedBlockId
        );

        if (translationBlockItem) {
          await createTranslation(
            this.selectedBlockId,
            translationBlockItem.original_key_text,
            translationBlockItem.language,
            translationBlockItem.original_text,
            String.formatText(result),
            Date.now()
          );
        }
      }

      Emitter.emit(
        "UPDATE_EDITOR_DATA",
        stores.documentStore.selectedDocumentContent
      );

      stores.documentStore.isDocumentsUpdating = false;

      resolve(true);
    });
  };

  @action regenerate = async (
    regenerateMode: RegenerateMode
  ): Promise<RegenerateResult[]> => {
    return new Promise(async (resolve, reject) => {
      if (
        !this.selectedText ||
        this.selectedText === "" ||
        !this.selectedAreaWholeText ||
        this.selectedAreaWholeText === ""
      ) {
        reject();
        return;
      }

      // let prompt = `Make sure you understand the [paragraph]. Make sure you fully understand [selection]. ${regenerateMode.prompt}. Do not change the meaning or context. You are only allowed to change [selection]. You are not allowed to change the rest of the [paragraph] besides [selection]. You are not allowed to change the sentence order of the [paragraph]. Replace [selection] with your output within the [paragraph]. Generate the result as a new paragraph. Generate only one result. You must only follow the instructions and generate only the paragraph as a result.\n\n[selection] = "${this.selectedText}"\n[paragraph] = "${this.selectedAreaWholeText}"`;
      // let prompt = `${regenerateMode.prompt}\n\nGenerate the result as a new paragraph. Generate only one result. You must only follow the instructions and generate only the paragraph as a result.\n\n[selection] = "${this.selectedText}"\n[paragraph] = "${this.selectedAreaWholeText}"`;
      let prompt = `${regenerateMode.prompt}\n\nYou must only follow the instructions and generate only the result.\n\n[selection] = "${this.selectedText}"\n[paragraph] = "${this.selectedAreaWholeText}"`;

      let results: RegenerateResult[] = [];

      for (let index = 0; index < keys.regenerateResultCount; index++) {
        let result = await translate(prompt);

        result = result.replaceAll('"', "");
        result = result.replaceAll("[selection]", this.selectedText);
        result = result.replaceAll("[paragraph]", this.selectedAreaWholeText);

        if (
          result.length > 0 &&
          results.findIndex((item) => item.result === result) === -1
        )
          results.push({ id: new ShortUniqueId().randomUUID(20), result });
      }

      if (results.length > 0) {
        resolve(results);
      } else {
        if (
          stores.userStore.currentUser.openai_api_key &&
          stores.userStore.currentUser.openai_api_key !== "undefined"
        ) {
          const checkApiKeyResult = await checkAPIKey(
            stores.userStore.currentUser.openai_api_key
          );

          if (!checkApiKeyResult)
            toast.error(i18n.common.apiKeyNotValid, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: stores.ui.theme,
            });
        } else {
          toast.error(i18n.common.apiKeyNotValid, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: stores.ui.theme,
          });
        }

        resolve([]);
      }
    });
  };
}
