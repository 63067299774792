import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  OutlinedInput,
  Switch,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import { ChromePicker } from "react-color";
import { RotateLeft, Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import HelpIcon from "@mui/icons-material/Help";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import stores from "../../stores";
import i18n from "../../data/i18n";
import { checkAPIKey } from "../../helpers/api";
import keys from "../../constants/keys";
import Analytics from "../../helpers/Analytics";

import styles from "./styles";
import { defaultSettings } from "../../stores/UserStore";

type Props = {
  isOpen: boolean;
  onClose: () => any;
};

type State = {
  colorPickerAnchorEl: any;
  isColorPickerOpened: boolean;
  isShowPassword: boolean;

  color?: string;
  selectedColorFieldName?: string;
  apiKey?: string;
  hoverStates: {
    original_text_color: boolean;
    translated_text_color: boolean;
    non_translated_text_color: boolean;
    selected_paragraph_color: boolean;
  };
};

@observer
export default class SettingsDrawer extends React.Component<Props, State> {
  private inputRef: any;

  constructor(props: any) {
    super(props);

    this.state = {
      colorPickerAnchorEl: undefined,
      isColorPickerOpened: false,
      isShowPassword: false,
      hoverStates: {
        original_text_color: false,
        translated_text_color: false,
        non_translated_text_color: false,
        selected_paragraph_color: false,
      },
    };
  }

  private updateUserSettings = async () => {
    await stores.userStore.updateUserData();
  };

  private onColorPickerClick = (
    event: React.MouseEvent,
    colorFieldName: string,
    color: string
  ) => {
    this.setState({
      colorPickerAnchorEl: event.currentTarget,
      selectedColorFieldName: colorFieldName,
      color: color,
      isColorPickerOpened: true,
    });
  };

  private handleTextColorChange = (
    color: any,
    event: any,
    colorFieldName?: string
  ) => {
    const { selectedColorFieldName } = this.state;

    this.setState({ color: color.hex });

    const fieldName = colorFieldName ?? selectedColorFieldName;
    if (fieldName) {
      // @ts-ignore
      stores.userStore.currentUser.settings[fieldName] = color.hex;
      this.updateUserSettings();
    }
  };

  private handleApiKeyChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const apiKey = event.target.value;

    this.setState({ apiKey });
  };

  private handleApiKeyClearClick = () => {
    Analytics.track("clear_open_api_key_clicked", "");

    stores.userStore.currentUser.openai_api_key = "";

    stores.userStore.updateUserData();

    this.setState({ apiKey: "" });

    this.inputRef.value = "";
  };

  private handleApiKeySaveClick = async () => {
    const { apiKey } = this.state;

    Analytics.track("save_open_api_key_clicked", "");

    if (apiKey) {
      const isValid = await checkAPIKey(apiKey);

      if (isValid) {
        Analytics.track("valid_open_api_key_entered", "");

        toast.warning(i18n.common.apiKeyNotValid, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: stores.ui.theme,
        });

        return;
      } else {
        Analytics.track("not_valid_open_api_key_entered", "");
      }
    } else {
      return;
    }

    stores.userStore.currentUser.openai_api_key = apiKey;

    stores.userStore.updateUserData();

    toast.success(i18n.common.apiKeyValid, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: stores.ui.theme,
    });
  };

  render() {
    const { isOpen, onClose } = this.props;
    const {
      colorPickerAnchorEl,
      isColorPickerOpened,
      color,
      isShowPassword,
      apiKey,
    } = this.state;
    const { settings } = stores.userStore.currentUser;

    return (
      <Drawer anchor={"left"} open={isOpen}>
        <Box sx={{ width: "30vw" }} role="presentation">
          <Box sx={styles.styleSheet.mainContainer}>
            <Box sx={styles.styleSheet.titleContainer}>
              <Typography sx={styles.styleSheet.titleText} variant="h5">
                {i18n.settings.settings}
              </Typography>

              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="directions"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box sx={styles.styleSheet.settingContainer}>
              <Box sx={styles.styleSheet.captionContainer}>
                <Typography variant="body1" sx={styles.styleSheet.captionText}>
                  {i18n.settings.userSettings}
                </Typography>

                <Divider sx={{ flex: 1 }} />
              </Box>

              <Box style={styles.styleSheet.settingRow}>
                <Box sx={styles.styleSheet.apiKeyLabelContainer}>
                  <IconButton
                    onClick={() =>
                      window.open(keys.openAIApiKeyUrl, "_blank", "noreferrer")
                    }
                  >
                    <HelpIcon />
                  </IconButton>

                  <Typography
                    variant="body2"
                    sx={styles.styleSheet.apiKeyLabellText}
                  >
                    {i18n.settings.openAIApiKey}
                  </Typography>

                  {!stores.userStore.currentUser.openai_api_key ||
                  stores.userStore.currentUser.openai_api_key ===
                    "undefined" ? (
                    <ReportProblemIcon color="warning" sx={{ ml: 1 }} />
                  ) : null}
                </Box>

                <FormControl variant="outlined">
                  <OutlinedInput
                    inputRef={(ref) => (this.inputRef = ref)}
                    id="outlined-adornment-password"
                    type={isShowPassword ? "text" : "password"}
                    defaultValue={
                      stores.userStore.currentUser.openai_api_key ===
                      "undefined"
                        ? ""
                        : stores.userStore.currentUser.openai_api_key
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            this.setState({ isShowPassword: !isShowPassword })
                          }
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          {isShowPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={this.handleApiKeyChange}
                    label=""
                  />
                </FormControl>
              </Box>

              <Box sx={{ alignSelf: "flex-end" }}>
                <Button
                  color="info"
                  onClick={this.handleApiKeyClearClick}
                  variant="outlined"
                  sx={styles.styleSheet.clearButton}
                >
                  {i18n.common.clear}
                </Button>

                <Button
                  disabled={
                    !apiKey ||
                    apiKey === "" ||
                    apiKey === "undefined" ||
                    apiKey === stores.userStore.currentUser.openai_api_key
                  }
                  color="success"
                  onClick={this.handleApiKeySaveClick}
                  variant="contained"
                  sx={styles.styleSheet.saveButton}
                >
                  {i18n.common.save}
                </Button>
              </Box>
            </Box>

            <Box sx={styles.styleSheet.settingContainer}>
              <Box style={styles.styleSheet.captionContainer}>
                <Typography variant="body1" sx={styles.styleSheet.captionText}>
                  {i18n.settings.editorSettings}
                </Typography>

                <Divider sx={{ flex: 1 }} />
              </Box>

              <Box
                onMouseEnter={() => {
                  this.setState({
                    hoverStates: {
                      ...this.state.hoverStates,
                      original_text_color: true,
                    },
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    hoverStates: {
                      ...this.state.hoverStates,
                      original_text_color: false,
                    },
                  });
                }}
                style={styles.styleSheet.settingRow}
              >
                <Typography variant="body2" sx={styles.styleSheet.labelText}>
                  {i18n.settings.originalTextColor}
                </Typography>

                {this.state.hoverStates.original_text_color ? (
                  <IconButton
                    size="small"
                    onClick={() => {
                      Analytics.track("original_text_color_set_to_default", "");
                      this.handleTextColorChange(
                        { hex: defaultSettings.original_text_color },
                        null,
                        "original_text_color"
                      );
                    }}
                  >
                    <RotateLeft />
                  </IconButton>
                ) : null}

                <Box
                  style={styles.styleSheet.colorPaletteContainer}
                  onClick={(event) => {
                    Analytics.track("original_text_color_changed", "");

                    this.onColorPickerClick(
                      event,
                      "original_text_color",
                      settings.original_text_color
                    );
                  }}
                >
                  <Box
                    style={{
                      ...styles.styleSheet.colorPaletteBox,
                      ...{ backgroundColor: settings.original_text_color },
                    }}
                  />

                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {settings.original_text_color}
                  </Typography>
                </Box>
              </Box>

              <Box
                onMouseEnter={() => {
                  this.setState({
                    hoverStates: {
                      ...this.state.hoverStates,
                      translated_text_color: true,
                    },
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    hoverStates: {
                      ...this.state.hoverStates,
                      translated_text_color: false,
                    },
                  });
                }}
                style={styles.styleSheet.settingRow}
              >
                <Typography variant="body2" sx={styles.styleSheet.labelText}>
                  {i18n.settings.translatedTextColor}
                </Typography>
                {this.state.hoverStates.translated_text_color ? (
                  <IconButton
                    size="small"
                    onClick={() => {
                      Analytics.track(
                        "translated_text_color_set_to_default",
                        ""
                      );

                      this.handleTextColorChange(
                        { hex: defaultSettings.translated_text_color },
                        null,
                        "translated_text_color"
                      );
                    }}
                  >
                    <RotateLeft />
                  </IconButton>
                ) : null}

                <Box
                  style={styles.styleSheet.colorPaletteContainer}
                  onClick={(event) => {
                    Analytics.track("translated_text_color_changed", "");

                    this.onColorPickerClick(
                      event,
                      "translated_text_color",
                      settings.translated_text_color
                    );
                  }}
                >
                  <Box
                    style={{
                      ...styles.styleSheet.colorPaletteBox,
                      ...{ backgroundColor: settings.translated_text_color },
                    }}
                  />

                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {settings.translated_text_color}
                  </Typography>
                </Box>
              </Box>

              <Box
                onMouseEnter={() => {
                  this.setState({
                    hoverStates: {
                      ...this.state.hoverStates,
                      non_translated_text_color: true,
                    },
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    hoverStates: {
                      ...this.state.hoverStates,
                      non_translated_text_color: false,
                    },
                  });
                }}
                style={styles.styleSheet.settingRow}
              >
                <Typography variant="body2" sx={styles.styleSheet.labelText}>
                  {i18n.settings.nonTranslatedTextColor}
                </Typography>

                {this.state.hoverStates.non_translated_text_color ? (
                  <IconButton
                    size="small"
                    onClick={() => {
                      Analytics.track(
                        "non_translated_text_color_set_to_default",
                        ""
                      );
                      this.handleTextColorChange(
                        { hex: defaultSettings.non_translated_text_color },
                        null,
                        "non_translated_text_color"
                      );
                    }}
                  >
                    <RotateLeft />
                  </IconButton>
                ) : null}
                <Box
                  style={styles.styleSheet.colorPaletteContainer}
                  onClick={(event) => {
                    Analytics.track("non_translated_text_color_changed", "");

                    this.onColorPickerClick(
                      event,
                      "non_translated_text_color",
                      settings.non_translated_text_color
                    );
                  }}
                >
                  <Box
                    style={{
                      ...styles.styleSheet.colorPaletteBox,
                      ...{
                        backgroundColor: settings.non_translated_text_color,
                      },
                    }}
                  />

                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {settings.non_translated_text_color}
                  </Typography>
                </Box>
              </Box>

              <Box
                onMouseEnter={() => {
                  this.setState({
                    hoverStates: {
                      ...this.state.hoverStates,
                      selected_paragraph_color: true,
                    },
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    hoverStates: {
                      ...this.state.hoverStates,
                      selected_paragraph_color: false,
                    },
                  });
                }}
                style={styles.styleSheet.settingRow}
              >
                <Typography variant="body2" sx={styles.styleSheet.labelText}>
                  {i18n.settings.selectedParagraphColor}
                </Typography>
                {this.state.hoverStates.selected_paragraph_color ? (
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      Analytics.track(
                        "selected_paragraph_color_set_to_default",
                        ""
                      );
                      this.handleTextColorChange(
                        { hex: defaultSettings.selected_paragraph_color },
                        null,
                        "selected_paragraph_color"
                      );
                    }}
                  >
                    <RotateLeft />
                  </IconButton>
                ) : null}

                <Box
                  style={styles.styleSheet.colorPaletteContainer}
                  onClick={(event) => {
                    Analytics.track("selected_paragraph_color_changed", "");

                    this.onColorPickerClick(
                      event,
                      "selected_paragraph_color",
                      settings.selected_paragraph_color
                    );
                  }}
                >
                  <Box
                    style={{
                      ...styles.styleSheet.colorPaletteBox,
                      ...{ backgroundColor: settings.selected_paragraph_color },
                    }}
                  />

                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {settings.selected_paragraph_color}
                  </Typography>
                </Box>
              </Box>

              <Box style={styles.styleSheet.settingRow}>
                <Typography variant="body2" sx={styles.styleSheet.labelText}>
                  {i18n.settings.originalTextSize}
                </Typography>

                <OutlinedInput
                  defaultValue={settings.original_text_size}
                  style={{ width: 100, height: 45 }}
                  endAdornment={
                    <InputAdornment position="end">px</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  onChange={(event) => {
                    Analytics.track("original_text_size_changed", "");
                    settings.original_text_size = Number(event.target.value);
                    this.updateUserSettings();
                  }}
                  type={"number"}
                  inputProps={{
                    min: 10,
                    max: 40,
                  }}
                />
              </Box>

              <Box style={styles.styleSheet.settingRow}>
                <Typography variant="body2" sx={styles.styleSheet.labelText}>
                  {i18n.settings.translatedTextSize}
                </Typography>

                <OutlinedInput
                  defaultValue={settings.translated_text_size}
                  style={{ width: 100, height: 45 }}
                  endAdornment={
                    <InputAdornment position="end">px</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  onChange={(event) => {
                    Analytics.track("translated_text_size_changed", "");
                    settings.translated_text_size = Number(event.target.value);
                    this.updateUserSettings();
                  }}
                  type={"number"}
                  inputProps={{
                    min: 10,
                    max: 40,
                  }}
                />
              </Box>

              <Box style={styles.styleSheet.settingRow}>
                <Typography variant="body2" sx={styles.styleSheet.labelText}>
                  {i18n.settings.nonTranslatedTextSize}
                </Typography>

                <OutlinedInput
                  defaultValue={settings.non_translated_text_size}
                  style={{ width: 100, height: 45 }}
                  endAdornment={
                    <InputAdornment position="end">px</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  onChange={(event) => {
                    Analytics.track("non_translated_text_size_changed", "");
                    settings.non_translated_text_size = Number(
                      event.target.value
                    );
                    this.updateUserSettings();
                  }}
                  type={"number"}
                  inputProps={{
                    min: 10,
                    max: 40,
                  }}
                />
              </Box>

              <Box style={styles.styleSheet.settingRow}>
                <Typography variant="body2" sx={styles.styleSheet.labelText}>
                  {i18n.settings.darkMode}
                </Typography>

                <Switch
                  checked={settings.dark_mode}
                  onChange={(event) => {
                    Analytics.track("editor_view_mode_changed", "");

                    settings.dark_mode = event.target.checked;
                    stores.ui.theme = settings.dark_mode ? "dark" : "light";

                    this.updateUserSettings();
                   
                  }}
                />
              </Box>
            </Box>

            <Menu
              anchorEl={colorPickerAnchorEl}
              id="account-menu"
              open={isColorPickerOpened}
              onClose={() => this.setState({ isColorPickerOpened: false })}
            >
              <ChromePicker
                color={color}
                onChange={this.handleTextColorChange}
              />
            </Menu>
          </Box>
        </Box>
      </Drawer>
    );
  }
}
