/* @flow */

import { computed } from "mobx";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "100vw",
        minHeight: "100vh",
        justifyContent: "flex-start",
        bgcolor: "rgb(32, 32, 32)",
      },
      mainContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        mt: 15,
      },
      topContainer: {
        position: "absolute",
        display: "flex",
        width: "100%",
        minHeight: 80,
        pl: 1,
        pr: 1,
        alignItems: "flex-start",
        justifyContent: "center",
        left: "50%", 
        transform: "translateX(-50%)", 
        margin:"5px"
      },
      bottomContainer: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        minHeight: 80,
        pl: 3,
        pr: 3,
        alignItems: "center",
        justifyContent: "center",
      },
      topButtonContainer: {
        display: "flex",
        flex: 1,
        mt: 3,
        mr: 1.5,
        padding:"5px"
      },
      navButton: {
        color: "inherit",
        fontSize: "1rem",
        fontWeight: "bold",
        textTransform: "none",
        marginRight: "10px",
        border: "1px solid transparent", 
        borderRadius: "8px", 
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)", 
          topContainer: {
            position: "absolute",
            display: "flex",
            width: "100%",
            minHeight: 80,
            pl: 1,
            pr: 1,
            alignItems: "flex-start",
            justifyContent: "space-between",
            border: "1px solid #ccc", 
            borderRadius: "10px", 
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", 
          },
        },
      },
      icon: {
        width: "13vw",
        height: "13vw",
        maxWidht: 100,
      },
      // titleText: {
      //   color: "#fff",
      //   textAlign: "center",
      // },
      titleTextContainer: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        wordWrap: "break-word", // Allow long words to be able to be broken and wrap onto the next line
        padding: "5px",

        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        pb: 10, // Adjusted for better spacing
        mt: 20,
      },
      titleText: {
        color: "#fff",
        textAlign: "center",
        overflowWrap: "anywhere", // Break words at any character for better wrapping
        fontSize: "5em", // Adjusted for better mobile display
        marginBottom: "0.5em", // Adjusted for better spacing
      },
      subTitleText: {
        color: "#fff",
        textAlign: "center",
        whiteSpace: "pre-line", // Preserve newlines, but wrap text
        fontSize: "2em", // Adjusted for better mobile display
      },
      descriptionText: {
        color: "#fff",
        width: "50vw",
        textAlign: "center",
        margin: "60px"
      },
      spaceBox: {
        flex: 1,
      },
      middleButtonContainer: {
        display: "flex",
        flexWrap: "wrap",
        mt: 3,
        mb: 3,
        justifyContent: "center",
      },
      tryButton: {
        color: "#000",
        backgroundColor: "#fff",
        textTransform: "none",
        fontSize: "18px",
        borderRadius: 3,
        pl: 3,
        pr: 3,
        pt: 1,
        pb: 1,
        height: 54,
      },
      featureCard: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        pb: "20px",
        pr: "40px",
        pl: "40px",
        justifyContent: "center",
        background: "rgb(32, 32, 32)",
        minWidth: 250,
        width: 500,
        height: 300,
        borderRadius: 15,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1)",
          boxShadow: "0 0 20px rgba(255, 255, 255, 0.5)",
        },
        boxShadow: "none",
        border: "1px solid rgb(32, 32, 32)",
        "@media (max-width: 768px)": {
          flexDirection: "column",
          pr: "20px",
          pl: "20px",
          width: "100%",
          height: "auto",
          "&:hover": {
            transform: "none",
            boxShadow: "none",
          },
        },
      },
      video: {
        width: "60vw",
        borderRadius: 15,
        marginTop: 5,
      },
      featureContainer: {
        "@keyframes slideInRight": {
          "0%": { transform: "translateY(200px)" },
          "100%": { transform: "translateY(0)" },
        },
        "@keyframes fadeIn": {
          "0%": { opacity: 0 },
          "100%": { opacity: 1 },
        },
      },
      inputContainer: {
        display: "flex",
        mb: 3,
        mt: 1,
        justifyContent: "center",
        alignItems: "flex-start",
      },
      submitButton: {
        color: "#000",
        backgroundColor: "#fff",
        textTransform: "none",
        fontSize: "18px",
        borderRadius: 2,
        pl: 3,
        pr: 3,
        pt: 1,
        pb: 1,
        ml: 2,
      },

      tutorialTextSection: {
        // Protect the Tutorial text style when scale of browser changes
        width: "100%",
        height: "auto",
        padding: "20px",
        textAlign: "center",
        background:
          "linear-gradient(to bottom, rgb(32,32,32) 0%, rgb(32,32,32) 50%, rgb(32,32,32) 100%)",
      },

      callToAction: {
        width: "100%",
        height: "auto",
        mb: 10,
        padding: "20px",
        textAlign: "center",
        background:
          "linear-gradient(to bottom, rgb(32,32,32) 0%, rgb(32,32,32) 50%, rgb(32,32,32) 100%)",
      },
    };

    return styles;
  }
}
