/* @flow */

import { computed } from "mobx";

import stores from "../../../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        bgcolor: stores.ui.themeColors.secondaryBackground,
        borderRadius: 1,
        height: "100%",
      },
      topTextContainer: {
        display: "flex",
        flexDirection: "column",
        pl: 2,
        pr: 2,
        pt: 2,
        pb: 2,
        bgcolor: stores.ui.themeColors.selectedBackground,
        justifyContent: "center",
        minHeight: 60,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
      },
      titleText: {
        fontWeight: "bold",
      },
      titleActionsText: {
        fontWeight: "bold",
      },
      divider: {
        height: 1.1,
        mt: 1,
        mb: 1,
        bgcolor: stores.ui.themeColors.textSecondary,
      },
      addButton: {
        mr: -1,
      },
      topText: {},
      topTextNoSelection: {
        color: stores.ui.themeColors.textSecondary,
      },
      regenerateMenu: {
        flex: 1,
        overflow: "auto",
      },
      buttonContainer: {
        display: "flex",
        mt: 2,
        ml: 2,
        mr: 2,
      },
      createCustomPromptButton: {
        flex: 1,
        textTransform: "none",
        color: stores.ui.themeColors.textPrimary,
        bgcolor: stores.ui.themeColors.selectedBackground,
        borderRadius: 1,
        height: 50,
        pl: 2,
        pr: 2,
        mr: 2,
      },
      promptHeader: {
        flex: 1,
        textTransform: "none",
        borderRadius: 1,
        pl: 2,
        pr: 2,
        mr: 2,
      },
      sortAndFilterBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        mt: 2,
      },
    };

    return styles;
  }
}
