import { Dialog } from "@mui/material";
import * as React from "react";
import { observer } from "mobx-react";
import { toast } from "react-toastify";

import stores from "../../stores";
import { cancelSubscription } from "../../helpers/api";
import i18n from "../../data/i18n";

import styles from "./styles";
import CancelSubscriptionView from "./CancelSubscriptionView";

type Props = {
  open: boolean;
  onClose: () => void;
};

type State = {};

@observer
export default class CancelSubscriptionModal extends React.Component<
  Props,
  State
> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentStep: 0,
    };
  }

  private onCancelSubscriptionPress = async () => {
    const { onClose } = this.props;

    if (
      stores.userStore.currentUser.subscriptions &&
      stores.userStore.currentUser.subscriptions.length > 0
    ) {
      await cancelSubscription(
        stores.userStore.currentUser.subscriptions[0].id
      );

      onClose();

      toast.success(i18n.upgradeModal.subscriptionCanceled, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: stores.ui.theme,
      });

      stores.userStore.currentUser.subscriptions = [];
    }
  };

  render() {
    const { open, onClose } = this.props;

    return (
      <Dialog
        sx={styles.styleSheet.dialogContainer}
        onClose={onClose}
        open={open}
      >
        <CancelSubscriptionView
          onCancelSubscriptionPress={this.onCancelSubscriptionPress}
        />
      </Dialog>
    );
  }
}
