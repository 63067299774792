import * as React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Box, Button, Tab, Tabs, Tooltip } from "@mui/material";
import { TranslateOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import BlockIcon from "@mui/icons-material/Block";
import AddIcon from "@mui/icons-material/Add";
import WhisperCall from "./WhisperCall";
import stores from "../../../stores";
import { ViewMode } from "../../../helpers/Enum";
import i18n from "../../../data/i18n";
import LanguageSelect from "../../LanguageSelect";
import keys from "../../../constants/keys";
import ShortcutList from "../../ShortcutList";
import Analytics from "../../../helpers/Analytics";

import styles from "./styles";

type Props = {
  docLangCountryCode?: string;
  docTransLangCountryCode?: string;
  isRightViewOpened: boolean;
  onToggleRightView: () => any;
  isLeftViewOpened: boolean;
  onToggleLeftView: () => any;
};

type State = {
  currentViewMode: ViewMode;
  translationLanguage: any;
  translationVisible: boolean;
  buttonPressed: boolean; // New state to track button press
};

@observer
export default class ActionBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentViewMode: stores.userStore.currentUser.settings.view_mode,
      translationLanguage: null,
      translationVisible: true,
      buttonPressed: false,
    };
  }

  updateCurrentViewMode = (newViewMode: ViewMode) => {
    this.setState({ currentViewMode: newViewMode });
  };

  getTranslationClassName = () => {
    return `.ce-translation-block__content`;
  };

  private removeTranslation = () => {
    const translationBlockContents = document.querySelectorAll(
      this.getTranslationClassName()
    );

    translationBlockContents.forEach((translationBlockContent) => {
      if (translationBlockContent instanceof HTMLElement) {
        translationBlockContent.style.display = this.state.translationVisible
          ? "none"
          : "block";
      }
    });

    // Add a class to the editor when translation is removed
    const editorElement = document.getElementById("editor"); // Replace with the actual ID of your editor
    if (editorElement) {
      editorElement.classList.toggle(
        "translation-removed",
        !this.state.translationVisible
      );
    }

    // Update state to track translation visibility
    this.setState((prevState) => ({
      translationVisible: !prevState.translationVisible,
      buttonPressed: true, // Set buttonPressed to true when button is pressed
    }));
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.translationVisible !== this.state.translationVisible ||
      prevState.currentViewMode !== this.state.currentViewMode ||
      prevProps.docLangCountryCode !== this.props.docLangCountryCode ||
      prevProps.docTransLangCountryCode !== this.props.docTransLangCountryCode
    ) {
      // Reset translationVisible to true when document is changed

      if (this.state.currentViewMode === ViewMode.SideView) {
        const editorElement = document.getElementById("editor");
        if (editorElement) {
          const newClass = classNames({
            "translation-block-visible": !this.state.translationVisible,
            "editor-Side-view-mode": true,
            "translation-removed": !this.state.translationVisible,
          });
          editorElement.className = newClass;
        }
      } else if (this.state.currentViewMode === ViewMode.BottomView) {
        const editorElement = document.getElementById("editor");
        if (editorElement) {
          const newClass = classNames({
            "translation-block-visible": !this.state.translationVisible,
            "editor-Bottom-view-mode": true,
            "translation-removed": !this.state.translationVisible,
          });
          editorElement.className = newClass;
        }
      }
    }
  }

  private handleViewTabChange = (event: any, newViewMode: ViewMode) => {
    switch (newViewMode) {
      case ViewMode.SideView:
        Analytics.track("side_by_side_view_clicked", "");
        break;
      case ViewMode.BottomView:
        Analytics.track("bottom_view_clicked", "");
        break;
      case ViewMode.OnlyOriginal:
        Analytics.track("only_original_view_clicked", "");
        break;
      case ViewMode.OnlyTranslation:
        Analytics.track("only_translation_view_clicked", "");
        break;
    }

    stores.userStore.currentUser.settings.view_mode = newViewMode;
    stores.userStore.updateUserData();

    this.updateCurrentViewMode(newViewMode);
  };

  private handleTranslateAllClick = (event: any) => {
    Analytics.track("translate_all_button_clicked", "");

    if (
      !stores.userStore.currentUser.openai_api_key ||
      stores.userStore.currentUser.openai_api_key === "undefined"
    ) {
      stores.userStore.isOpenAIApiKeyAlertModalOpened = true;
      return;
    }

    if (
      stores.documentStore.selectedDocumentItem &&
      stores.documentStore.selectedDocumentItem.translation_language
    ) {
      stores.documentStore.translateAll(
        stores.documentStore.selectedDocumentItem.translation_language
      );
    } else {
      toast.error(i18n.editorScreen.languageSelectWarning, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: stores.ui.theme,
      });
    }
  };

  render() {
    const {
      isLeftViewOpened,
      isRightViewOpened,
      onToggleLeftView,
      onToggleRightView,
    } = this.props;

    const { currentViewMode } = this.state;
    if (!stores.documentStore.selectedDocumentItem) return null;

    const translationLanguage = stores.documentStore.selectedDocumentItem
      .translation_language
      ? keys.languages.find(
          (item) =>
            stores.documentStore.selectedDocumentItem &&
            item.code ===
              stores.documentStore.selectedDocumentItem.translation_language
        )
      : undefined;

    if (!stores.documentStore.selectedDocumentItem) return null;

    const { document_language } = stores.documentStore.selectedDocumentItem;

    // Check if document_language is "Editor"
    const isEditorDocument = !(document_language === "Editor");
    const isVoiceDocument = !(document_language === "Voice");

    // Conditionally render LanguageSelect based on document_language
    const renderLanguageSelect = isEditorDocument && (
      <Box sx={styles.styleSheet.languageSelectorItemContainer}>
        <LanguageSelect
          selectedLanguage={translationLanguage}
          label={i18n.editorScreen.translationLanguage}
          onLanguageSelect={(language) => {
            Analytics.track("translation_language_selected", "");

            if (stores.documentStore.selectedDocumentItem) {
              stores.documentStore.updateDocumentLanguages(
                stores.documentStore.selectedDocumentItem,
                stores.documentStore.selectedDocumentItem.document_language,
                language ? language.code : undefined
              );
              this.setState({ translationLanguage: language });
            }
          }}
        />
      </Box>
    );

    const renderTabs = isVoiceDocument && isEditorDocument && (
      <Box sx={styles.styleSheet.tabContainer}>
        <Tabs
          value={stores.userStore.currentUser.settings.view_mode}
          onChange={this.handleViewTabChange}
          orientation="horizontal"
          centered={true}
          TabIndicatorProps={{ hidden: true }}
          sx={styles.styleSheet.tabs}
        >
          <Tab
            sx={styles.styleSheet.tab}
            value={ViewMode.BottomView}
            icon={
              <Tooltip followCursor title="Bottom View">
                <img
                  style={styles.styleSheet.icon}
                  alt=""
                  src={require(`../../../assets/${stores.ui.theme}/bottomByBottomView.png`)}
                />
              </Tooltip>
            }
          />

          <Tab
            sx={styles.styleSheet.tab}
            value={ViewMode.SideView}
            icon={
              <Tooltip followCursor title="Side-By-Side View">
                <img
                  style={styles.styleSheet.icon}
                  alt=""
                  src={require(`../../../assets/${stores.ui.theme}/sideBySideView.png`)}
                />
              </Tooltip>
            }
          />

          <Tab
            sx={styles.styleSheet.tab}
            value={ViewMode.OnlyOriginal}
            icon={
              <Tooltip followCursor title={i18n.editorScreen.onlyOriginalView}>
                <img
                  style={styles.styleSheet.icon}
                  alt=""
                  src={require(`../../../assets/${stores.ui.theme}/rawIcon.png`)}
                />
              </Tooltip>
            }
          />

          <Tab
            sx={styles.styleSheet.tab}
            value={ViewMode.OnlyTranslation}
            icon={
              <Tooltip
                followCursor
                title={i18n.editorScreen.onlyTranslationView}
              >
                <img
                  style={styles.styleSheet.icon}
                  alt=""
                  src={require(`../../../assets/${stores.ui.theme}/translateIcon.png`)}
                />
              </Tooltip>
            }
          />
        </Tabs>
      </Box>
    );

    // Conditionally render RemoveTranslationButton based on document_language
    const renderRemoveTranslationButton = isVoiceDocument &&
      isEditorDocument && (
        <Box>
          <Tooltip
            title={
              this.state.translationVisible
                ? i18n.editorScreen.removeTranslation
                : i18n.editorScreen.addTranslation
            }
          >
            <Button
              startIcon={
                <Box
                  sx={{
                    ...styles.styleSheet.icon,
                    pl: 0.8,
                  }}
                >
                  {this.state.translationVisible ? <BlockIcon /> : <AddIcon />}
                </Box>
              }
              sx={styles.styleSheet.removeTranslationButton}
              onClick={
                currentViewMode === ViewMode.BottomView ||
                currentViewMode === ViewMode.SideView
                  ? this.removeTranslation
                  : undefined
              }
            />
          </Tooltip>
        </Box>
      );

    // Conditionally render LoadingButton based on document_language
    const renderLoadingButton = isVoiceDocument && isEditorDocument && (
      <LoadingButton
        loading={!stores.documentStore.isTranslationCompleted}
        disabled={!stores.documentStore.isTranslationCompleted}
        startIcon={<TranslateOutlined />}
        sx={styles.styleSheet.translateAllButton}
        onClick={this.handleTranslateAllClick}
      >
        {i18n.editorScreen.translateAll}
      </LoadingButton>
    );

    // Conditionally render LoadingButton based on document_language
    const renderVoiceRecordButton = !isVoiceDocument && (
      <WhisperCall></WhisperCall>
    );

    // // Conditionally render LoadingButton based on document_language
    // const renderActionVoiceRecordButton = !isVoiceDocument && (
    //   <WhisperActionCall></WhisperActionCall>
    // );

    // Conditionally render LoadingButton based on document_language
    const renderShortcutList = isVoiceDocument && <ShortcutList />;

    // Conditionally render spaces between rendered items if isEditorDocument is true
    const renderSpaces = !(isVoiceDocument && isEditorDocument) && (
      <React.Fragment>
        <Box sx={{ flexGrow: 2 }} />
      </React.Fragment>
    );

    return (
      <Box sx={styles.styleSheet.container}>
        <Button
          sx={styles.styleSheet.showHideLeftView}
          onClick={onToggleLeftView}
        >
          <Tooltip followCursor title="Left View">
            <img
              style={styles.styleSheet.icon}
              alt=""
              src={require(`../../../assets/${stores.ui.theme}/${
                isLeftViewOpened ? "closeLeftView" : "openLeftView"
              }.png`)}
            />
          </Tooltip>
        </Button>

        {renderLanguageSelect}

        {renderRemoveTranslationButton}

        {renderTabs}
        {renderSpaces}
        {renderSpaces}

        {renderVoiceRecordButton}

        {/* {renderActionVoiceRecordButton} */}
        {renderSpaces}

        {renderShortcutList}
        {renderSpaces}
        {renderSpaces}

        {renderLoadingButton}

        <Button
          sx={styles.styleSheet.showHideRightView}
          onClick={onToggleRightView}
        >
          <Tooltip followCursor title="Right View">
            <img
              style={styles.styleSheet.icon}
              alt=""
              src={require(`../../../assets/${stores.ui.theme}/${
                isRightViewOpened ? "closeRightView" : "openRightView"
              }.png`)}
            />
          </Tooltip>
        </Button>
      </Box>
    );
  }
}
