// ** React Imports
import { ChangeEvent } from "react";

// ** MUI Imports
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import MuiCard, { CardProps } from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";

// ** Icon Imports
import IconifyIcon from "../../components/icon";

interface Props {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}

// Styled TextField component
const TextField = styled(MuiTextField)<TextFieldProps>(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.up("sm")]: {
    width: "55%",
  },
}));

const FaqHeader = (props: Props) => {
  // ** Props
  const { searchTerm, setSearchTerm } = props;

  const handleFaqFilter = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <CardContent
        sx={{
          pt: 17.5,
          textAlign: "center",
          pb: (theme) => `${theme.spacing(8)} !important`,
        }}
      >
        <Typography variant="h5" sx={{ mb: 8 }}>
          Hello, how can we help?
        </Typography>

        <TextField
          value={searchTerm}
          placeholder="Search a question...."
          onChange={(e) => handleFaqFilter(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconifyIcon icon="bx:search" />
              </InputAdornment>
            ),
          }}
        />
        <Typography sx={{ mt: 4, color: "text.secondary" }}>
          or choose a category to quickly find the help you need
        </Typography>
      </CardContent>
    </div>
  );
};

export default FaqHeader;
