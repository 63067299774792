import * as React from "react";
import { Box, Typography } from "@mui/material";

import { SharedDocumentItem } from "../../../../models/SharedDocumentItem";
import { DocumentItem } from "../../../../models/DocumentItem";
import stores from "../../../../stores";
import { getDocument } from "../../../../helpers/api";
import i18n from "../../../../data/i18n";

import styles from "./styles";

type Props = {
  selected: boolean;
  sharedDocumentItem: SharedDocumentItem;
  handleListItemClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    documentItem: DocumentItem
  ) => any;
};

type State = {
  documentItem?: DocumentItem;
};

export default class SharedDocumentRowItem extends React.Component<
  Props,
  State
> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  componentWillMount(): void {
    this.getDocumentItem();
  }

  private getDocumentItem = async () => {
    const { sharedDocumentItem } = this.props;

    const documentItem = await getDocument(sharedDocumentItem.document_id);

    this.setState({ documentItem });
  };

  private handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    documentItem: DocumentItem
  ) => {
    this.props.handleListItemClick(event, documentItem);
  };

  render() {
    const { selected } = this.props;
    const { documentItem } = this.state;

    if (!documentItem) return null;

    return (
      <Box
        key={documentItem.id}
        sx={[
          styles.styleSheet.container,
          {
            bgcolor: selected
              ? stores.ui.themeColors.selectedBackground
              : "transparent",
          },
        ]}
        onClick={(event) => this.handleListItemClick(event, documentItem)}
      >
        <Box sx={styles.styleSheet.mainContainer}>
          <Box style={styles.styleSheet.nameContainer}>
            <img
              style={styles.styleSheet.icon}
              alt=""
              src={require(`../../../../assets/${
                stores.ui.theme
              }/${"sharedDocument"}.png`)}
            />

            <Box>
              <Typography sx={styles.styleSheet.nameText} variant="body2">
                {documentItem.name}
              </Typography>

              <Typography sx={styles.styleSheet.sharedText} variant="caption">
                {i18n.editorScreen.sharedWithYou}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}
