import { SvgIconProps } from "@mui/material/SvgIcon";
import { Box, Typography, Card, CardContent, Grid } from "@mui/material";
import styles from "./styles";
import i18n from "../../data/i18n";

import LanguageIcon from "@mui/icons-material/Language";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import EditNoteIcon from "@mui/icons-material/EditNote";
import BackupIcon from "@mui/icons-material/Backup";
import FlagIcon from "@mui/icons-material/Flag";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ArticleIcon from "@mui/icons-material/Article";
import BuildIcon from "@mui/icons-material/Build";

interface FeatureCardProps {
  iconComponent: React.ComponentType<SvgIconProps>;
  title: string;
  description: string;
}

export const FeatureCard: React.FC<FeatureCardProps> = ({
  iconComponent: Icon,
  title,
  description,
}) => {
  return (
    <Card sx={styles.styleSheet.featureCard}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 1,
            flexDirection: "column",
          }}
        >
          <Icon sx={{ mb: 3, fontSize: 96 }} />
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ textAlign: "center", mb: 1 }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            textAlign: "center",
          }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const FeatureCards = () => {
  const data = [
    {
      iconComponent: LanguageIcon,
      title: i18n.homeScreen.features.featureOne.title,
      description: i18n.homeScreen.features.featureOne.description,
    },
    {
      iconComponent: FlagIcon,
      title: i18n.homeScreen.features.featureTwo.title,
      description: i18n.homeScreen.features.featureTwo.description,
    },
    {
      iconComponent: SpellcheckIcon,
      title: i18n.homeScreen.features.featureThree.title,
      description: i18n.homeScreen.features.featureThree.description,
    },
    {
      iconComponent: EditNoteIcon,
      title: i18n.homeScreen.features.featureFour.title,
      description: i18n.homeScreen.features.featureFour.description,
    },
    {
      iconComponent: ChromeReaderModeIcon,
      title: i18n.homeScreen.features.featureFive.title,
      description: i18n.homeScreen.features.featureFive.description,
    },
    {
      iconComponent: FormatListNumberedIcon,
      title: i18n.homeScreen.features.featureSix.title,
      description: i18n.homeScreen.features.featureSix.description,
    },
    {
      iconComponent: BuildIcon,
      title: i18n.homeScreen.features.featureSeven.title,
      description: i18n.homeScreen.features.featureSeven.description,
    },
    {
      iconComponent: BackupIcon,
      title: i18n.homeScreen.features.featureEight.title,
      description: i18n.homeScreen.features.featureEight.description,
    },
    {
      iconComponent: ArticleIcon,
      title: i18n.homeScreen.features.featureNine.title,
      description: i18n.homeScreen.features.featureNine.description,
    },
    {
      iconComponent: VerticalSplitIcon,
      title: i18n.homeScreen.features.featureTen.title,
      description: i18n.homeScreen.features.featureTen.description,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        xs={11}
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
          mb: 10,
        }}
      >
        {data.map((feature, index) => (
          <FeatureCard
            key={index}
            iconComponent={feature.iconComponent}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default FeatureCards;
