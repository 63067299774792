import React, { Component, RefObject } from "react";

import keys from "../constants/keys";

type HeaderProps = {
  text?: string;
  placeholder?: string;
  level: number;
  style: React.CSSProperties;
  contentEditable: boolean;
  onContextMenu: (event: any) => void;
  onKeyUp: (event: any) => void;
  onFocus: (event: any) => void;
  onBlur: (event: any) => void;
  onMouseUp: (event: any) => void;
  onDoubleClick: (event: any) => void;
};

type HeaderState = {};

class Header extends Component<HeaderProps, HeaderState> {
  public inputRef: RefObject<HTMLElement>;

  constructor(props: HeaderProps) {
    super(props);
    this.inputRef = React.createRef<HTMLElement>();
  }
  componentDidMount(): void {
    if (this.inputRef.current) {
      this.inputRef.current.addEventListener(
        "DOMSubtreeModified",
        this.props.onKeyUp
      );
    }
  }

  componentWillUnmount() {
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener(
        "DOMSubtreeModified",
        this.props.onKeyUp
      );
    }
  }

  render() {
    const {
      text,
      placeholder,
      level,
      style,
      contentEditable,
      onContextMenu,
      onFocus,
      onBlur,
      onMouseUp,
      onDoubleClick,
    } = this.props;

    const HeaderElement = `h${level}`;

    return (
      <HeaderElement
        //@ts-ignore
        className={`ce-header ${keys.translationBlockClassName}`}
        ref={this.inputRef}
        style={style}
        suppressContentEditableWarning={true}
        contentEditable={contentEditable}
        dangerouslySetInnerHTML={{ __html: text || placeholder || "" }}
        inputMode="text"
        onContextMenu={onContextMenu}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseUp={onMouseUp}
        onDoubleClick={onDoubleClick}
      ></HeaderElement>
    );
  }
}

export default Header;
