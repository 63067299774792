/* @flow */

import { PromptMode } from "../models/RegenerateMode";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translationBlockClassName: "translation-block",
  languages: [
    { title: "Arabic", code: "AR", country: "SA" },
    { title: "Bengali", code: "BN", country: "BD" },
    { title: "Chinese (Mandarin)", code: "ZH", country: "CN" },
    { title: "Czech", code: "CS", country: "CZ" },
    { title: "Dutch", code: "NL", country: "NL" },
    { title: "English", code: "EN", country: "GB" },
    { title: "French", code: "FR", country: "FR" },
    { title: "German", code: "DE", country: "DE" },
    { title: "Hindi", code: "HI", country: "IN" },
    { title: "Hungarian", code: "HU", country: "HU" },
    { title: "Indonesian", code: "ID", country: "ID" },
    { title: "Italian", code: "IT", country: "IT" },
    { title: "Japanese", code: "JA", country: "JP" },
    { title: "Korean", code: "KO", country: "KR" },
    { title: "Malay", code: "MS", country: "MY" },
    { title: "Polish", code: "PL", country: "PL" },
    { title: "Portuguese", code: "PT", country: "PT" },
    { title: "Punjabi", code: "PA", country: "IN" },
    { title: "Romanian", code: "RO", country: "RO" },
    { title: "Russian", code: "RU", country: "RU" },
    { title: "Serbian", code: "SR", country: "RS" },
    { title: "Slovak", code: "SK", country: "SK" },
    { title: "Spanish", code: "ES", country: "ES" },
    { title: "Swedish", code: "SV", country: "SE" },
    { title: "Tamil", code: "TA", country: "IN" },
    { title: "Thai", code: "TH", country: "TH" },
    { title: "Turkish", code: "TR", country: "TR" },
    { title: "Ukrainian", code: "UK", country: "UA" },
    { title: "Urdu", code: "UR", country: "PK" },
    { title: "Vietnamese", code: "VI", country: "VN" },
  ],
  rightAlignedLanguageCodes: ["AR", "UR"],
  shortcutList: [
    { id: 1, name: "Undo ", shortcut: "⌘ + ⇧ + Z" },
    { id: 2, name: "Redo", shortcut: "⌘ + Z" },
    { id: 3, name: "Make selected bold", shortcut: "⌘ + B" },
    { id: 4, name: "Make selected italic", shortcut: "⌘ + I" },
    { id: 5, name: "Insert comment", shortcut: "⌘ + ⇧ + S" },
    { id: 6, name: "Highlight selected", shortcut: "⌘ + H" },
    // {
    //   id: 7,
    //   name: "Increase heading type (h1 to h2) or change text into heading",
    //   shortcut: "⌘ + ⇧ + I",
    // },
    // {
    //   id: 8,
    //   name: "Regenerate/Paraphrase selected word, sentence or paragraph",
    //   shortcut: "⌘ + ⇧ + R",
    // },
    // {
    //   id: 9,
    //   name: "Replace word or sentence with synonym without losing meaning",
    //   shortcut: "⌘ + S",
    // },
    // {
    //   id: 10,
    //   name: "Make selected sentence or paragraph shorter",
    //   shortcut: "⌘ + K",
    // },

    // {
    //   id: 11,
    //   name: "Generate document headline for selected paragraph",
    //   shortcut: "⌘ + ⇧ + Y",
    // },
    // {
    //   id: 12,
    //   name: "Generate more content for selected word, sentence or paragraph",
    //   shortcut: "⌘ + ⇧ + P",
    // },
    // {
    //   id: 13,
    //   name: "Translate selected word, sentence or paragraph",
    //   shortcut: "⌘ + ⇧ + T",
    // },
  ],
  canny: {
    appId: "63f4a4eee1f32b7b2eb01d6d",
    boardToken: "97a17ca7-22ba-9a9e-9a38-51264066cba1",
    ssoToken: "",
  },
  actionDrawerWidth: "40vw",
  proFeatureLimits: {
    freeRegenerateModeCount: 1,
    freeDocumentCount: 2,
    freePromptModes: [PromptMode.Standard],
  },
  firebaseConfig: {
    apiKey: "AIzaSyDZ3RtrC24BeVfbEvzKFAkQPj391Udubkg",
    authDomain: "lingoedit.firebaseapp.com",
    projectId: "lingoedit",
    storageBucket: "lingoedit.appspot.com",
    messagingSenderId: "171498709272",
    appId: "1:171498709272:web:eb8a7567ea90aae794fe6d",
    measurementId: "G-ZVB2H6DBV9",
  },
  amplitudeApiKey: "2bfbd888680a30bcf2152e837ec430ea",
  characterLimits: {
    translationBlockCharacterLimit: 1000,
    promptCharacterLimit: 1000,
  },
  regenerateResultCount: 5,
  publicRegenerateModesUserId: 935,
  openAIApiKeyUrl: "https://platform.openai.com/account/api-keys",
};
