import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { observer } from "mobx-react";
import { toast } from "react-toastify";

import stores from "../../../../stores";
import i18n from "../../../../data/i18n";
import {
  attachPaymentMethod,
  createSubscription,
  setDefaultPaymentMethod,
} from "../../../../helpers/api";
import { Product } from "../../../../models/Product";
import { SubscriptionCoupon } from "../../../../models/SubscriptionCoupon";

import styles from "./styles";

export const CheckoutForm: React.FC<{
  selectedProduct: Product;
  selectedSubscriptionCoupon?: SubscriptionCoupon;
}> = observer(({ selectedProduct, selectedSubscriptionCoupon }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCardAvailable, setIsCardAvailable] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details

    setIsCardAvailable(event.error ? false : true);
    setMessage(event.error ? event.error.message : "");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement("card");
    if (
      cardElement &&
      stores.userStore.currentUser.email &&
      stores.userStore.currentUser.stripeProfile
    ) {
      const newPaymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: stores.userStore.currentUser.email,
        },
      });

      if (newPaymentMethod.paymentMethod) {
        await attachPaymentMethod(
          stores.userStore.currentUser.stripeProfile.id,
          newPaymentMethod.paymentMethod.id
        );

        await setDefaultPaymentMethod(
          stores.userStore.currentUser.stripeProfile.id,
          newPaymentMethod.paymentMethod.id
        );

        const subscription = await createSubscription(
          stores.userStore.currentUser.stripeProfile.id,
          selectedProduct.prices[0].id,
          selectedSubscriptionCoupon
        );

        if (subscription.status === "active") {
          stores.userStore.isPurchaseModalOpened = false;

          toast.success(i18n.upgradeModal.subscriptionSuccess, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: stores.ui.theme,
          });

          stores.userStore.currentUser.subscriptions = [subscription];
        } else {
          setMessage("An unexpected error occurred: 1");
        }
      } else {
        setMessage("An unexpected error occurred: 2");
      }
    } else {
      setMessage("An unexpected error occurred: 3");
    }

    setIsLoading(false);
  };

  const title =
    selectedProduct.name.indexOf("Yearly") !== -1
      ? i18n.upgradeModal.purchaseAnnualPlan
      : i18n.upgradeModal.purchaseMonthlyPlan;

  const percentOff = selectedSubscriptionCoupon
    ? selectedSubscriptionCoupon.percent_off
    : 100;

  const priceMessage =
    selectedProduct.name.indexOf("Yearly") !== -1
      ? i18n.formatString(
          i18n.upgradeModal.annualPlanPurchaseMessage,
          (
            Math.floor(
              (((selectedProduct.prices[0].unit_amount / 1200) * percentOff) /
                100) *
                100
            ) / 100
          ).toString(),
          (
            ((selectedProduct.prices[0].unit_amount / 100) * percentOff) /
            100
          ).toFixed(2)
        )
      : i18n.formatString(
          i18n.upgradeModal.annualPlanPurchaseMessage,
          (
            ((selectedProduct.prices[0].unit_amount / 100) * percentOff) /
            100
          ).toFixed(2),
          (
            ((selectedProduct.prices[0].unit_amount / 100) * percentOff) /
            100
          ).toFixed(2)
        );

  return (
    <Box sx={styles.styleSheet.mainContainer}>
      <Typography variant="body1" sx={styles.styleSheet.title}>
        {title}
      </Typography>

      <Typography variant="body2" sx={styles.styleSheet.priceInfoMessage}>
        {priceMessage}
      </Typography>

      <form
        id="payment-form"
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit}
      >
        <CardElement
          id={`card-element-${stores.ui.theme}`}
          options={{ style: styles.styleSheet.cardStyle }}
          onChange={handleChange}
        />

        {isLoading ? (
          <CircularProgress size={30} sx={styles.styleSheet.progress} />
        ) : (
          <Button
            id="submit"
            sx={styles.styleSheet.payNowButton}
            color="secondary"
            disabled={isLoading || !stripe || !elements || !isCardAvailable}
            onClick={handleSubmit}
          >
            {i18n.upgradeModal.payNow}
          </Button>
        )}

        <Typography variant="caption" sx={styles.styleSheet.cardInfoMessage}>
          {i18n.upgradeModal.cardInfoMessage}
        </Typography>

        <Typography variant="body2" sx={styles.styleSheet.infoMessage}>
          {message}
        </Typography>
      </form>

      <Box sx={styles.styleSheet.bottomButtonsContainer}>
        <Button
          sx={styles.styleSheet.backButton}
          color="secondary"
          onClick={() => (stores.userStore.isPurchaseModalOpened = false)}
        >
          {i18n.common.cancel}
        </Button>
      </Box>
    </Box>
  );
});

export default CheckoutForm;
