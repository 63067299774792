/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import {
  RegenerateMode,
  RegenerateModeType,
  PromptMode,
} from "../../models/RegenerateMode";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function getUserRegenerateModes(
  userId: number
): Promise<RegenerateMode[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query RegenerateModesSearchQuery($user_id: ID!) {
        regenerateModesCreatedByUser(user_id: $user_id) {
          id
          user_id
          name
          prompt
          is_translation
          types
          prompt_modes
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          user_id: userId,
        },
        fetchPolicy: "no-cache",
      })
      .then((regenerateModesSearchQueryResult: any) => {
        const regenerateModes: RegenerateMode[] = get(
          regenerateModesSearchQueryResult,
          "data.regenerateModesCreatedByUser",
          null
        );

        for (let index = 0; index < regenerateModes.length; index++) {
          regenerateModes[index].prompt = decodeURIComponent(
            regenerateModes[index].prompt
          );

          if (
            regenerateModes[index].types &&
            regenerateModes[index].types !== ""
          )
            regenerateModes[index].types_list = regenerateModes[index].types
              .split(",")
              .map((type) => type as RegenerateModeType);
          else regenerateModes[index].types_list = [];

          if (
            regenerateModes[index].prompt_modes &&
            regenerateModes[index].prompt_modes !== ""
          )
            regenerateModes[index].prompt_modes_list = regenerateModes[
              index
            ].prompt_modes
              .split(",")
              .map((mode) => mode as PromptMode);
          else regenerateModes[index].prompt_modes_list = [];
        }

        resolve(regenerateModes);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
