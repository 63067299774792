import { observable, action, when } from "mobx";

import {
  PromptMode,
  RegenerateMode,
  RegenerateModeType,
} from "../models/RegenerateMode";
import {
  createRegenerateMode,
  deleteRegenerateMode,
  getRegenerateModes,
  getUserRegenerateModes,
  updateRegenerateMode,
} from "../helpers/api";
import { SortType } from "../helpers/Enum";
import Functions from "../helpers/Functions";

import RootStore from "./RootStore";
import stores from ".";

export default class RegenerateStore {
  @observable regenerateModes: Array<RegenerateMode> = [];
  @observable userRegenerateModes: Array<RegenerateMode> = [];

  @observable selectedPromptModes: Array<PromptMode> =
    Object.values(PromptMode);
  @observable selectedRegenerateModeTypes: Array<RegenerateModeType> = [];
  @observable selectedSortType: SortType = SortType.AZ;
  @observable searchText: string = "";
  @observable filteredRegenerateModes: Array<RegenerateMode> = [];

  constructor(rootStore: RootStore) {
    when(
      () => rootStore.userStore.isCurrentUserReady,
      () => {
        this.getRegenerateModes();
      }
    );
  }

  @action getRegenerateModes = async () => {
    this.regenerateModes = await getRegenerateModes();

    this.userRegenerateModes = await getUserRegenerateModes(
      stores.userStore.currentUser.id
    );

    this.processRegenerateModes();
  };

  @action processRegenerateModes = async () => {
    let filteredRegenerateModes = [
      ...this.userRegenerateModes,
      ...this.regenerateModes,
    ];

    if (this.selectedPromptModes.length > 0) {
      filteredRegenerateModes = filteredRegenerateModes.filter(
        (regenerateMode) =>
          regenerateMode.user_id.toString() ===
            stores.userStore.currentUser.id.toString() ||
          this.selectedPromptModes.some(
            (promptMode) =>
              regenerateMode.prompt_modes_list.length > 0 &&
              regenerateMode.prompt_modes_list.includes(
                // @ts-ignore
                Functions.getKeyByPromptModeValue(promptMode)
              )
          )
      );
    }

    if (this.selectedRegenerateModeTypes.length > 0) {
      filteredRegenerateModes = filteredRegenerateModes.filter(
        (regenerateMode) =>
          this.selectedRegenerateModeTypes.some((type) =>
            regenerateMode.types_list.includes(type)
          )
      );
    }

    if (stores.actionStore.selectionType) {
      const selectionTypeString = stores.actionStore.selectionType.valueOf();

      filteredRegenerateModes = filteredRegenerateModes.filter(
        (regenerateMode) =>
          regenerateMode.types
            .toLowerCase()
            .includes(selectionTypeString.toLowerCase())
      );
    }

    switch (this.selectedSortType) {
      case SortType.AZ:
        filteredRegenerateModes.sort((a, b) => (a.name < b.name ? -1 : 1));
        break;
      case SortType.ZA:
        filteredRegenerateModes.sort((a, b) => (b.name < a.name ? -1 : 1));
        break;
      default:
        break;
    }

    if (this.searchText.length > 0) {
      filteredRegenerateModes = filteredRegenerateModes.filter(
        (regenerateMode) =>
          regenerateMode.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
      );
    }

    this.filteredRegenerateModes = filteredRegenerateModes;
  };

  @action addNewUserRegenerateMode = async (
    name: string,
    prompt: string,
    types: RegenerateModeType[],
    promptModes: PromptMode[]
  ): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      const newRegenerateMode = await createRegenerateMode(
        name,
        stores.userStore.currentUser.id,
        prompt,
        false,
        types,
        promptModes
      );

      if (newRegenerateMode) {
        this.userRegenerateModes.push(newRegenerateMode);

        this.processRegenerateModes();

        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  @action updateUserRegenerateMode = async (
    id: number,
    name: string,
    prompt: string,
    types: RegenerateModeType[],
    promptModes: PromptMode[]
  ): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      const newRegenerateMode = await updateRegenerateMode(
        id,
        name,
        prompt,
        false,
        types,
        promptModes
      );

      const index = this.userRegenerateModes.findIndex(
        (item) => item.id === id
      );

      if (newRegenerateMode && index !== -1) {
        this.userRegenerateModes[index].name = name;
        this.userRegenerateModes[index].prompt = prompt;
        this.userRegenerateModes[index].is_translation = false;
        this.userRegenerateModes[index].types = types
          .map((type) => type.valueOf())
          .join();
        this.userRegenerateModes[index].prompt_modes = promptModes
          .map((type) => type.valueOf())
          .join();
        this.userRegenerateModes[index].types_list = types;
        this.userRegenerateModes[index].prompt_modes_list = promptModes;

        this.processRegenerateModes();

        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  @action deleteUserRegenerateMode = async (id: number): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      await deleteRegenerateMode(id);

      this.userRegenerateModes = this.userRegenerateModes.filter(
        (item) => item.id !== id
      );

      this.processRegenerateModes();

      resolve(true);
    });
  };
}
