/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import { DocumentHistoryItem } from "../../models/DocumentHistoryItem";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function getCurrentDocumentHistory(
  documentId: number
): Promise<DocumentHistoryItem | undefined> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query CurrentDocumentHistoryQuery($document_id: ID!) {
        currentDocumentHistory(document_id: $document_id) {
          id
          doc_id
          editor_data
          updated_at
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          document_id: documentId,
        },
        fetchPolicy: "no-cache",
      })
      .then((currentDocumentHistoryQueryResult: any) => {
        const documentHistory: DocumentHistoryItem = get(
          currentDocumentHistoryQueryResult,
          "data.currentDocumentHistory",
          null
        );

        if (documentHistory) {
          documentHistory.output_data = JSON.parse(
            decodeURIComponent(documentHistory.editor_data)
          );

          resolve(documentHistory);
        } else {
          resolve(undefined);
        }
      })
      .catch((error: any) => {
        console.error(error);

        resolve(undefined);
      });
  });
}
