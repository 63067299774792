/* @flow */

import { computed } from "mobx";

import stores from "../../../../stores";

export default class styles {
  @computed static get styleSheet() {
    const styles = {
      mainContainer: {
        display: "flex",
        flexDirection: "column",
        height: "80vh",
      },
      title: {
        color: stores.ui.themeColors.textPrimary,
        fontWeight: "bold",
        mb: 3,
      },
      payNowButton: {
        mt: 2,
        mb: 2,
        textTransform: "none",
        bgcolor: stores.ui.themeColors.primary,
        color: stores.ui.themeColors.textPrimary,
      },
      progress: {
        alignSelf: "center",
        mt: 2,
        mb: 2,
      },
      priceInfoMessage: {
        mb: 2,
      },
      infoMessage: {
        mt: 1,
        mb: 1,
        textAlign: "center",
        color: stores.ui.themeColors.textWarning,
      },
      cardInfoMessage: {
        color: stores.ui.themeColors.textSecondary,
      },
      bottomButtonsContainer: {
        display: "flex",
        flex: 1,
        mb: 1,
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
      backButton: {
        textTransform: "none",
        bgcolor: stores.ui.themeColors.secondaryBackground,
        color: stores.ui.themeColors.textPrimary,
      },
      cardStyle: {
        base: {
          color: stores.ui.themeColors.textPrimary,
          fontFamily: "Arial, sans-serif",
          fontSize: "14px",
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: stores.ui.themeColors.textSecondary,
          },
        },
        invalid: {
          fontFamily: "Arial, sans-serif",
          color: stores.ui.themeColors.textWarning,
          iconColor: stores.ui.themeColors.textWarning,
        },
      },
    };

    return styles;
  }
}
