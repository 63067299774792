/* @flow */

import { gql } from "apollo-boost";
import { get } from "lodash";

import { DocumentItem } from "../../models/DocumentItem";
import ApolloClientHelper from "../ApolloClientHelper";

export default async function getDocumentItem(
  id: number
): Promise<DocumentItem> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query DocumentQuery($id: ID!) {
        document(id: $id) {
          id
          name
          document_language
          translation_language
          user_id
          date
          created_at
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          id: id,
        },
        fetchPolicy: "no-cache",
      })
      .then((documentQueryResult: any) => {
        const documentItem: DocumentItem = get(
          documentQueryResult,
          "data.document",
          null
        );

        if (documentItem) {
          resolve(documentItem);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
