/* @flow */

import stores from "../../stores";

export default async function getTranslationResult(
  prompt: string
): Promise<string> {
  return new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        apiKey: stores.userStore.currentUser.openai_api_key,
        prompt: prompt,
      }),
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/translate?_${Date.now()}`,
      requestOptions
    ).then((response) => {
      resolve(response.text());
    });
  });
}
